<mat-form-field fxFlex="100%" appearance="outline">
  <mat-label>Address</mat-label>
  <div matPrefix style="margin: 8px">
    <mat-icon>home</mat-icon>
  </div>
  <input type="text" required [formControl]="addressControl" [matAutocomplete]="autoInsurable" matInput placeholder="Please start typing address...">
  <mat-autocomplete autoActiveFirstOption #autoInsurable="matAutocomplete" [displayWith]="displayFnAddress">
    <mat-option *ngFor="let address of addresses" [value]="address">
      <span class="caption">{{ getAddress(address) }}</span>
    </mat-option>
  </mat-autocomplete>
  <button *ngIf="loader" class="text-secondary spinner" mat-icon-button matSuffix type="button">
    <mat-icon>loading</mat-icon>
  </button>
  <button *ngIf="!loader && addressControl.value && addressControl.enabled" mat-icon-button matSuffix (click)="reset()" type="button">
    <mat-icon>close</mat-icon>
  </button>
  <button *ngIf="!loader && !addressControl.value && addressControl.enabled" class="text-secondary" mat-icon-button matSuffix type="button">
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <mat-hint>{{ notFound ? 'No results found. Please try changing your search query' : '' }}</mat-hint>
  <mat-error *ngIf="addressControl.invalid">{{ addressControl | errorMsg:'Address' }}</mat-error>
</mat-form-field>
