<div class="new-faq">
  <div class="new-faq-container">
    <div class="new-faq-search">
      <input type="text" [placeholder]="('faq.search_faq' | translate)" [formControl]="search">
      <div *ngIf="search && search.value && search.value.length > 0"
           class="new-faq-search__close"
           (click)="resetSearch()"
      >
        <img src="./assets/images/icon-menu-close.svg" alt="close">
      </div>
    </div>
    <div class="new-faq-accordion">
      <mat-accordion [multi]="true"
                     #accordion="matAccordion">
        <mat-expansion-panel class="mat-elevation-z0" *ngFor="let section of searchedFaqData">
          <mat-expansion-panel-header class="new-faq-section-header">
            <mat-panel-title>
              {{ section.title }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <app-faq-question *ngFor="let question of section.questions" [search]="search.value" [question]="question.question" [answer]="question.answer"></app-faq-question>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <ng-container *ngIf="searchedFaqData && searchedFaqData.length === 0">
      <div class="new-faq-subject">
        {{ 'faq.no_faq_info' | translate }}
      </div>
    </ng-container>
  </div>
</div>
