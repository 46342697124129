import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { map, tap } from 'rxjs/operators';
import { JwtService } from '../services/jwt.service';


@Injectable()
export class ProfileResolver implements Resolve<Observable<any>> {

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private jwt: JwtService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.authService.getCurrentUser();
    //
    // const userlocal = this.jwt.getToken();
    // if ( user.id === undefined && userlocal !== undefined && userlocal !== null) {
    //    user = userlocal;
    // }
    // return this.userService.getUser(user.id).pipe(map(result => result));
  }
}
