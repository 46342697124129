import { Component } from '@angular/core';

@Component({
  selector: 'app-pci-insurance-fraud-provisions',
  templateUrl: './pci-insurance-fraud-provisions.component.html',
  styleUrls: ['./pci-insurance-fraud-provisions.component.css']
})
export class PCIInsuranceFraudProvisionsComponent {

  constructor() { }
}
