import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-questions-popup',
  templateUrl: './questions-popup.component.html',
  styleUrls: ['./questions-popup.component.scss']
})
export class QuestionsPopupComponent implements OnInit {
  questions;
  answers;

  constructor(public dialogRef: MatDialogRef<QuestionsPopupComponent>,
              private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) public matData: any) {
    this.questions  = matData.questions;
    this.answers  = matData.answers;
  }

  ngOnInit(): void {
  }

  getLabelOption(option) {
    return option === 'true' ? this.translate.instant('form.yes') : this.translate.instant('form.no');
  }

  changeAnswer(answer, knockoutValue, questionId) {
    this.answers[questionId] = answer.value;
  }

  get qbeWrongKnockout() {
    return Object.keys(this.answers).some(questionId => {
      const found = this.questions.find(question => question.questionId === questionId);
      return found && found.value !== this.answers[questionId];
    });
  }

}
