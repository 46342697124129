<svg class="social-links-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
  <g>
    <title>background</title>
    <rect x="-1" y="-1" width="4.85714" height="4.85714" id="canvas_background" fill="none"/>
  </g>
  <g>
    <title>Layer 1</title>
    <path class="social-links-svg_fill" d="m10.24447,0.91678a6.92132,6.92132 0 0 0 -7.0647,6.77033a3.93842,3.93842 0 0 0 1.937,3.67044a0.53625,0.53625 0 0 0 0.79812,-0.33718l0.19277,-0.823a0.50436,0.50436 0 0 0 -0.14323,-0.499a2.69066,2.69066 0 0 1 -0.73672,-2.39653a5.03722,5.03722 0 0 1 4.975,-4.57643c3.12027,-0.05518 5.00867,1.8975 4.3626,5.66614c-0.68545,3.99861 -3.9774,4.86143 -4.75694,2.69328a2.00728,2.00728 0 0 1 0.02829,-1.21247c0.17891,-0.61969 0.4894,-1.59175 0.64116,-2.13062c0.35887,-1.27427 0.23248,-2.45578 -0.92719,-2.74062c-0.89886,-0.22077 -1.805,0.38623 -2.19719,1.76525a3.88589,3.88589 0 0 0 0.22638,2.4543l-1.37243,5.68444a4.37535,4.37535 0 0 0 -0.118,1.21957l0.12181,2.76261a0.236,0.236 0 0 0 0.44438,0.09982l1.32992,-2.51835a4.37535,4.37535 0 0 0 0.36863,-0.954l0.71136,-2.7678c2.35839,3.0342 7.31862,0.59536 7.99979,-3.96644c0.7733,-5.17955 -2.19513,-7.82173 -6.82081,-7.86374z"/>
  </g>
</svg>
