import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  @Input() inputFormControl: FormControl;
  @Input() label = 'Upload Files';
  @Input() singleFile = false

  alerts: string[] = [];

  constructor(private cd: ChangeDetectorRef) { }

  get isRequired(): boolean {
    return this.inputFormControl?.hasValidator(Validators.required);
  }

  public validateFile(file: File): boolean {
    const types = ['application/pdf', 'image/jpeg', 'image/png'];
    if (!types.find(type => type === file.type)) {
      const msg = 'Not Allowed File extension';
      this.alerts.push(file.name + ': ' + msg);
      return false;
    }
    if (file.size > 31457280) {
      const msg = 'Max file size is 30mb';
      this.alerts.push(file.name + ': ' + msg);
      return false;
    }
    return true;
  }

  onFileChange(event: Event): void {
    if (this.singleFile) {
      this.addSingleFile(event);
    } else {
      this.addMultipleFiles(event);
    }
  }

  addSingleFile(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files?.length) {
      const files = target.files;
      const file = files[0];
      const currentFiles = [];
      if (this.validateFile(file)) {
        currentFiles.push(file);
      }
      this.inputFormControl.patchValue(currentFiles);
      this.cd.markForCheck();
    }
  }

  addMultipleFiles(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files?.length) {
      const files = Array.from(target.files);
      const currentFiles = this.inputFormControl.value;
      [...files].forEach(file => {
        if (this.validateFile(file)) {
          currentFiles.push(file);
        }
      });
      this.inputFormControl.patchValue(currentFiles);
      this.cd.markForCheck();
    }
  }

  removeFileByIndex(index) {
    const files = this.inputFormControl.value.filter((item, i) => i !== index);
    this.inputFormControl.patchValue(files);
  }

  removeAlertByIndex(index) {
    const alerts = this.alerts.filter((item, i) => i !== index);
    this.alerts = alerts;
  }

}
