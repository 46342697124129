import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insurance-producer-licensure',
  templateUrl: './insurance-producer-licensure.component.html',
  styleUrls: ['./insurance-producer-licensure.component.css']
})
export class InsuranceProducerLicensureComponent implements OnInit {

  states = [
    {
      state: 'Alabama',
      licenseNumber: '3000299473'
    },
    {
      state: 'Arizona',
      licenseNumber: '3000270003'
    },
    {
      state: 'Arkansas',
      licenseNumber: '3000270002'
    },
    {
      state: 'California',
      licenseNumber: '0M46726'
    },
    {
      state: 'Colorado',
      licenseNumber: '565004'
    },
    {
      state: 'Connecticut',
      licenseNumber: '2592252'
    },
    {
      state: 'Delaware',
      licenseNumber: '3000270012'
    },
    {
      state: 'District of Columbia',
      licenseNumber: '3000269967'
    },
    {
      state: 'Florida',
      licenseNumber: 'L102928'
    },
    {
      state: 'Georgia',
      licenseNumber: '200704'
    },
    {
      state: 'Idaho',
      licenseNumber: '662253'
    },
    {
      state: 'Illinois',
      licenseNumber: '3000270005'
    },
    {
      state: 'Indiana',
      licenseNumber: '3327349'
    },
    {
      state: 'Iowa',
      licenseNumber: '1002313367'
    },
    {
      state: 'Kansas',
      licenseNumber: '823427840-0'
    },
    {
      state: 'Kentucky',
      licenseNumber: 'DOI-989512'
    },
    {
      state: 'Louisiana',
      licenseNumber: '778571'
    },
    {
      state: 'Maine',
      licenseNumber: 'AGN306641'
    },
    {
      state: 'Maryland',
      licenseNumber: '3000270018'
    },
    {
      state: 'Massachusetts',
      licenseNumber: '2177871'
    },
    {
      state: 'Michigan',
      licenseNumber: '115611'
    },
    {
      state: 'Minnesota',
      licenseNumber: '40569262'
    },
    {
      state: 'Mississippi',
      licenseNumber: '15033362'
    },
    {
      state: 'Missouri',
      licenseNumber: '8436734'
    },
    {
      state: 'Montana',
      licenseNumber: '3000270020'
    },
    {
      state: 'Nebraska',
      licenseNumber: '100287325'
    },
    {
      state: 'Nevada',
      licenseNumber: '3325949'
    },
    {
      state: 'New Hampshire',
      licenseNumber: '2398758'
    },
    {
      state: 'New Jersey',
      licenseNumber: '1666991'
    },
    {
      state: 'New Mexico',
      licenseNumber: '3000272867'
    },
    {
      state: 'New York',
      licenseNumber: 'PC-1492495'
    },
    {
      state: 'North Carolina',
      licenseNumber: '1000572232'
    },
    {
      state: 'North Dakota',
      licenseNumber: '3000270027'
    },
    {
      state: 'Ohio',
      licenseNumber: '1186518'
    },
    {
      state: 'Oklahoma',
      licenseNumber: '3000270040'
    },
    {
      state: 'Oregon',
      licenseNumber: '3000280734'
    },
    {
      state: 'Pennsylvania',
      licenseNumber: '847591'
    },
    {
      state: 'Rhode Island',
      licenseNumber: '3000270074'
    },
    {
      state: 'South Carolina',
      licenseNumber: '1913564660'
    },
    {
      state: 'South Dakota',
      licenseNumber: '10020482'
    },
    {
      state: 'Tennessee',
      licenseNumber: '2396899'
    },
    {
      state: 'Texas',
      licenseNumber: '2296116'
    },
    {
      state: 'Utah',
      licenseNumber: '662615'
    },
    {
      state: 'Vermont',
      licenseNumber: '3326175'
    },
    {
      state: 'Virginia',
      licenseNumber: '143586'
    },
    {
      state: 'Washington',
      licenseNumber: '983445'
    },
    {
      state: 'West Virginia',
      licenseNumber: '100267522'
    },
    {
      state: 'Wisconsin',
      licenseNumber: '3000270037'
    },
    {
      state: 'Wyoming',
      licenseNumber: '365935'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
