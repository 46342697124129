import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class TranslatePaginatorIntl extends MatPaginatorIntl {

  constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange.subscribe((e: Event) => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex: number = page * pageSize;
    const endIndex: number = startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} / ${length}`;
  }

  public getAndInitTranslations(): void {
    this.translate.get([
      'paginator.items_per_page',
      'paginator.next_page',
      'paginator.previous_page',
      'paginator.first_page',
      'paginator.last_page'
    ])
      .subscribe((translation: any) => {
        this.itemsPerPageLabel = translation['paginator.items_per_page'];
        this.nextPageLabel = translation['paginator.next_page'];
        this.previousPageLabel = translation['paginator.previous_page'];
        this.firstPageLabel = translation['paginator.first_page'];
        this.lastPageLabel = translation['paginator.last_page'];
        this.changes.next();
      });
  }
}
