import { Component } from '@angular/core';

@Component({
  selector: 'powered-by-gc',
  templateUrl: './powered-by-gc.component.html',
  styleUrls: ['./powered-by-gc.component.scss']
})
export class PoweredByGcComponent {

}
