<mat-dialog-content>
  <p style="margin-bottom: 15px; text-align: center;">
    We have found these users with matching names or emails on our system
  </p>
  <p style="margin-bottom: 15px; text-align: center;">
    Are you one of them?
  </p>
  <div class="dialog-match-user">
    <mat-radio-group [formControl]="matchUserControl">
      <mat-radio-button *ngFor="let user of users"
                        [value]="user"
                        color="primary">
        <div class="label-radio">{{ user.first_name}} {{ user.last_name }} {{ user.email_masked }}</div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="space-around start"
                    fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign.xs="center center">
  <button mat-button
          class="mat-btn"
          [disabled]="!matchUserControl.value"
          (click)="dialogRef.close(matchUserControl.value)">
    Yes, it's me
  </button>
  <button mat-button
    class="mat-btn outline"
    (click)="dialogRef.close(null)">
    No, I am someone else
  </button>
</mat-dialog-actions>
