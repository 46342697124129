import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GetOrCreateResponse, ResidentialService } from '../../../core/services/residential.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ui-building-address',
  templateUrl: './ui-building-address.component.html',
  styleUrls: ['./ui-building-address.component.scss']
})
export class UiBuildingAddressComponent {
  @Input() buildingAddressControl;
  @Input() unitControl;
  @Input() communityId;
  @Output() setBuilding = new EventEmitter();

  constructor(private residentialService: ResidentialService,
              private toastr: ToastrService) { }

  handleAddressChange(googleAddress: Address) {
    const formattedAddress = this.residentialService.formatAddress(this.buildingAddressControl.value, googleAddress);
    this.buildingAddressControl.setValue(formattedAddress);
    this.getOrCreate();
  }

  getOrCreate(): void {
    this.residentialService.getOrCreateBuilding(this.buildingAddressControl.value, this.communityId)
      .subscribe((result: GetOrCreateResponse) =>  {
        const building = result && result.results && result.results[0] ? result.results[0] : null;
        if (building) {
          this.setBuilding.emit(building);
        }
      }, err => {
        if (err && err.error && err.message) {
          this.toastr.error(err.message);
        }
      });
  }
}
