import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output
} from '@angular/core';
import { environment } from '@environment';
import { ResidentialService } from '../../core/services/residential.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { BrandingService } from '../../core/services/branding.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DisclosuresPageComponent } from '../disclosures-page/disclosures-page.component';
import { AppService } from '../../core/services/app.service';
import { GaEcommerceService } from '../../core/services/ga-ecommerce.service';
import { Subject } from 'rxjs';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-total-premium',
  templateUrl: './total-premium.component.html',
  styleUrls: ['./total-premium.component.scss']
})
export class TotalPremiumComponent implements OnChanges, OnDestroy {
  @Input() formType = 'residential';
  @Input() additionalInformation = null;
  @Input() totalLabel = 'user.total_premium';
  @Input() mergedServerData = null;
  @Input() data;
  @Input() paymentPlans;
  @Input() hasBackButton = false;
  @Output() paymentSuccess = new EventEmitter();
  @Output() paymentPlanChange = new EventEmitter();
  @Output() paymentError = new EventEmitter();
  @Output() declinePayment = new EventEmitter();
  @Output() cardComplete = new EventEmitter();
  state;
  newCarrierAgree = false;
  environment = environment;
  newCarrier = false;
  loader = false;
  result;
  totalNumberClass = '';
  subjectFormSubmit = new Subject();

  constructor(private residentialService: ResidentialService,
              private router: Router,
              private brandingService: BrandingService,
              private dialog: MatDialog,
              private toastrService: ToastrService,
              private appService: AppService,
              private gaEcommerceService: GaEcommerceService,
              private translate: TranslateService) {
    this.brandingService.customRgPage$.subscribe(customRgPage => {
      if (customRgPage === 'freeway') {
        this.totalNumberClass = 'registration-accent_freeway';
      }
    });
  }

  processPayment(value) {
    this.loader = true;
    const body: any = {
      user: {
        id: this.data.user.id,
        payment_method: value.id,
      }
    };

    if (this.data.carrier_id === 5) {
      const card = value.card;
      body.user.source = 'tok_visa';
      body.payment = {
        payment_method: 'card',
        token: card.token,
        CreditCardInfo: {
          CardHolderName: card.name,
          CardExpirationDate: `${card.exp.month}${card.exp.year % 100}`,
          CardType: card.brand === 'american-express' ? 'AMEX' : card.brand,
          CreditCardLast4Digits: card.last4,
          Addr: {
            Addr1: card.address1,
            Addr2: card.address2,
            City: card.city,
            StateProvCd: card.region,
            PostalCode: card.postalCode
          }
        }
      };
    }

    const policyTypeId = this.mergedServerData !== null ? this.mergedServerData.policy_type_id : 1;
    const allInvoicesPrice = this.data.invoices.reduce((acc, val) => acc + val.total, 0);
    const price = this.gaEcommerceService.formatPrice(allInvoicesPrice);

    this.residentialService.acceptPolicyQuote(this.data.quote.id, body).pipe(
      finalize(() => this.loader = false)
    ).subscribe(result => {
        const obj = {
          transaction_id: this.data.quote.id,
          value: price,
          currency: 'USD',
          items: [this.gaEcommerceService.getProduct(policyTypeId, {
            price
          })],
        };
        // @ts-ignore
        window.gtag('event', 'purchase', obj);

        this.result = result;
        this.paymentSuccess.emit(result);
      },
      error => {
        const msg = error && error.message ? error.message : error && error.error ? error.error : 'Error!';
        this.paymentError.emit(error);
        this.toastrService.error(msg);
      });
  }

  decline() {
    this.declinePayment.emit();
  }

  goToDisclosure() {
    this.newCarrierAgree = null;
    const state: any = {
      monthlyDownPayment: this.data.invoices[0].total,
      state: this.additionalInformation?.address?.primary_address?.state,
      email: this.additionalInformation.email,
      billingTitle: this.additionalInformation.billingStrategy.title,
      fee: this.additionalInformation.coverageOptions.installment_fee,
      installmentTotal: this.data.installment_total,
      policyFee: this.data.policy_fee
    };
    const dialog = this.dialog.open(DisclosuresPageComponent, {
      data: {
        pageData: state
      }
    });
    dialog.afterClosed().subscribe((res) => {
      this.newCarrierAgree = res;
      if (res) {
        this.subjectFormSubmit.next();
      }
    });
  }

  onPaymentPlanChanged(value) {
    this.paymentPlanChange.emit(value);
  }

  ngOnChanges(changes: any): void {
    if (this.additionalInformation && this.additionalInformation.address) {
      this.state = this.additionalInformation.address.state;
    }
    this.newCarrier = (this.data && this.data.carrier_id === 5);
  }

  ngOnDestroy(): void {
    this.appService.setShowMenu(true);
  }
}
