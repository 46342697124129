import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { BrandingService } from '../../core/services/branding.service';

@Directive({
  selector: '[unlessBrandingAttr]'
})
export class UnlessBrandingAttrDirective {
  private hasView = false;
  constructor(private brandingService: BrandingService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
  ) { }

  @Input() set unlessBrandingAttr(brandingAttribute: string) {
    const attribute = this.brandingService.getBrandingInfoSnapshot()?.profile_attributes.find(item => item.name === brandingAttribute);

    if (!attribute) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (attribute && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
