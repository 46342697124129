import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ResidentialService } from '../services/residential.service';

@Injectable()
export class GuaranteeResolver implements Resolve<Observable<any>> {

  constructor(private resService: ResidentialService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.resService.getFormData('rent-guarantee');
  }
}
