<footer class="freeway-footer">
  <div class="freeway-footer-wrap">
    <div class="freeway-footer-row">
      <div *ngIf="isFooterLogo" class="freeway-footer-logo">
        <img class="freeway-footer-logo-img" [src]=footerLogoUrl alt="LOGO">
        <img *ngIf="isSecondFooterLogo" class="freeway-footer-logo-plus" src="./assets/logos/header_logo_plus.png" alt="plus">
        <img *ngIf="isSecondFooterLogo" class="freeway-footer-logo-img" [src]="footerLogoUrl" alt="LOGO-2">
      </div>
      <div *ngIf="!isFooterLogo" class="freeway-footer-logo">
        <img class="freeway-footer-logo-img" [src]=headerLogoUrl alt="LOGO">
        <img *ngIf="isSecondHeaderLogo" class="freeway-footer-logo-plus" src="./assets/logos/header_logo_plus.png" alt="plus">
        <img *ngIf="isSecondHeaderLogo" class="freeway-footer-logo-img" [src]="headerSecondLogoUrl" alt="LOGO-2">
      </div>

      <div class="freeway-footer__navigation">
        <div class="freeway-footer__navigation__link">
          <a *ngIf="!(isUserLogin$ | async)"routerLink="/auth/login">{{ 'form.login' | translate }}</a>
          <a *ngIf="isUserLogin$ | async"routerLink="user/profile">{{ 'form.profile' | translate }}</a>
        </div>
        <div *ngIf="isContactUsPage$ | async" class="freeway-footer__navigation__link"><a routerLink="/Contact-Us">{{ 'menu.contact_us' | translate }}</a></div>
      </div>
    </div>

    <div class="freeway-footer-row">
      <div class="freeway-footer__copyright">
        <div [innerHTML]="footerCopyrightHtml$ | async"></div>
        <p *ngIf="(isFooterAffiliate$ | async)">{{ 'menu.list_of_entity_license' | translate }} <a href="https://www.freewayinsurance.com/affiliate-disclosure">{{ 'menu.affiliate_disclosure_page' | translate }}</a></p>
      </div>

      <div class="freeway-footer__links">

        <div class="freeway-footer__links__router">
          <a href="https://www.freewayinsurance.com/affiliate-disclosure">{{ 'menu.affiliates' | translate }}</a>
          <span class="freeway-footer__links__router__slash">|</span>
          <a routerLink="Terms-of-Use">{{ 'menu.terms_of_use' | translate }}</a>
          <span class="freeway-footer__links__router__slash">|</span>
          <a routerLink="privacy-policy">{{ 'menu.privacy_policy' | translate }}</a>
          <span class="freeway-footer__links__router__slash">|</span>
          <a href="https://www.freewayinsurance.com/e-sign_disclaimer">{{ 'menu.e_sign' | translate }}</a>
          <span class="freeway-footer__links__router__slash">|</span>
          <!--<a routerLink="PCI-Insurance-Fraud-Provisions">{{ 'menu.pci_insurance_fraud_provisions' | translate }}</a>-->
          <a routerLink="state-fraud-warnings">{{ 'menu.state_fraud_warnings' | translate }}</a>
          <span class="freeway-footer__links__router__slash">|</span>
          <a routerLink="licenses">Licenses</a>
        </div>

        <div class="freeway-footer__social-links">
          <ng-container *ngIf="(socialLinks$ | async) && (socialLinks$ | async).length" >
            <div *ngFor="let link of (socialLinks$ | async)" [ngClass]="link.type">
              <a [href]="link.url" class="freeway-footer__social-links__item">
                <app-freeway-layout-footer-social [type]="link.type"></app-freeway-layout-footer-social>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</footer>
