export function jsonToCss(json) {
  const css = [];
  for (const selector in json) {
    if (json.hasOwnProperty(selector)) {
      let style = selector + ' {';
      for (const prop in json[selector]) {
        if (json[selector].hasOwnProperty(prop)) {
          if (typeof json[selector][prop] === 'string') {
            style += prop + ':' + json[selector][prop] + ';';
          } else {
            style += jsonToCss(json[selector]) + ';';
          }
        }
      }
      style += '}';
      css.push(style);
    }
  }
  return css.join('\n');
}
