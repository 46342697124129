import { Carrier } from '../models';
import { isString } from 'lodash';

/**
 * isCarrier type guard
 * TODO make it narrower but not sure if some data may come empty or nil
 * @param obj
 */
export const isCarrier = (obj: any): obj is Carrier => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'id' in obj &&
    'slug' in obj &&
    'call_sign' in obj &&
    'integration_designation' in obj &&
    'settings' in obj &&
    'commission_strategy_id' in obj &&
    'created_at' in obj && isString(obj.created_at) &&
    'updated_at' in obj && isString(obj.updated_at) &&
    'synonyms' in obj &&
    'title' in obj
  );
};
