import { Component, OnDestroy, OnInit } from '@angular/core';
import { BrandingService } from './core/services/branding.service';
import { Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'app-redirect',
  template: '',
})
export class RedirectComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  constructor(private brandingService: BrandingService, private router: Router) { }

  ngOnInit(): void {
    this.subscription = combineLatest([
      this.brandingService.availableForms$,
      this.brandingService.defaultStartingPage$
    ]).subscribe(([forms, value]) => {
      if (value) {
        this.router.navigateByUrl(value);
      } else {
        if (forms.includes('rent_guarantee')) {
          this.router.navigateByUrl('rentguarantee');
        } else if (forms.includes('residential')){
          this.router.navigateByUrl('residential');
        } else if (forms.includes('commercial')){
          this.router.navigateByUrl('commercial');
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) { this.subscription.unsubscribe(); }
  }
}
