import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConvertCoveragesService {

  constructor() {}

  convertCoverageSelections(coverageSelections) {
    const output = {};
    coverageSelections.forEach(item => {
      output[item.uid] = {
        selection: item.selection === undefined ? true : item.selection
      };
    });
    return output;
  }

  convertCoverageOptions(coverageOptions) {
    const output = [];
    Object.keys(coverageOptions).forEach(uid => {
      output.push({
        uid,
        ...coverageOptions[uid]
      });
    });
    return output;
  }
}
