<div class="auth-form">
  <ng-container *ngIf="tokenChecking">
    <div fxLayout="row" fxLayoutAlign="center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!tokenChecking">
    <div class="registration-expired-message" *ngIf="!tokenValid">
      {{ 'form.token_expired' | translate }}
    </div>
    <ng-container *ngIf="tokenValid">
      <ng-container *ngIf="formStep === 1">
        <form [formGroup]="changePasswordForm" class="auth-form" (ngSubmit)="submitForm(changePasswordForm)">

          <mat-form-field appearance="outline">
            <mat-label>{{ 'form.password' | translate }}</mat-label>
            <input matInput type="password" formControlName="password" required>
            <mat-error *ngIf="f['password'].invalid && submitted">
              {{ f['password'] | errorMsg: ('form.password' | translate) }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'user.confirm_password' | translate }}</mat-label>
            <input matInput type="password" formControlName="password_confirmation" required>
            <mat-error *ngIf="f['password_confirmation'].invalid && submitted">
              {{ f['password_confirmation'] | errorMsg: ('user.confirm_password' | translate) }}
            </mat-error>
          </mat-form-field>

          <hr>

          <button mat-button
                  type="submit"
                  class="mat-btn warning test-change"
                  [disabled]="!changePasswordForm.valid && submitted"
                  [class.spinner]="changePasswordForm.valid && submitted"
          >
            {{ btnLabel | translate }}
          </button>
          <p *ngIf="message" class="invalid-input-dedactible" style="margin-top: 10px; color:red;">{{message['error']}}</p>
        </form>
      </ng-container>
      <form *ngIf="formStep === 2">
        <div>
          <p>{{ 'form.password_updated' | translate }}.<br>
            <a *ngIf="withLogin" class="gc-link" [routerLink]="['/auth/login']">{{ 'form.here_login' | translate }}.</a>
          </p>
        </div>
      </form>
    </ng-container>
  </ng-container>
</div>



