import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator, ValidatorFn,
  Validators
} from '@angular/forms';
import { DateTime } from 'luxon';
import { customEmailValidator } from '../../../../core/utils/custom-email-validator.utils';
import { InsuredUser } from '../../../../core/interfaces/residential-data.interface';

export type PmaInsuredUserComponentValue = Pick<InsuredUser, 'first_name' | 'last_name' | 'middle_name' | 'email' | 'spouse'>;

const forbiddenEmailValidator = (email: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = email.trim().toLowerCase() === control.value.trim().toLowerCase();
    return forbidden ? { forbiddenEmail: true } : null;
  };
};

@Component({
  selector: 'pma-insured-user',
  templateUrl: './pma-insured-user.component.html',
  styleUrls: ['./pma-insured-user.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PmaInsuredUserComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PmaInsuredUserComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PmaInsuredUserComponent implements OnInit, ControlValueAccessor, Validator {

  /** The applicant email is the email of the user filling out the form and adding additional users */
  @Input() applicantEmail: string;

  @Input() index: number;

  @Input() removable = true;

  @Output() readonly remove = new EventEmitter<number>;

  readonly maxBirthdayDate = DateTime.local().minus({ years: 18 }).toJSDate();

  readonly formGroup = new FormGroup({
    first_name: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    last_name: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    middle_name: new FormControl('', [Validators.maxLength(25)]),
    email: new FormControl('', [ Validators.required, customEmailValidator() ]),
    spouse: new FormControl(false),
  });

  constructor() { }

  ngOnInit(): void {
    this.formGroup.controls.email.addValidators([
      forbiddenEmailValidator(this.applicantEmail),
    ]);
    this.formGroup.valueChanges.subscribe(value => {
      if (this.onTouched) {
        this.onTouched(value);
      }
      if (this.onChange) {
        this.onChange(value);
      }
    });
  }

  writeValue(user: Partial<PmaInsuredUserComponentValue>): void {
    this.formGroup.patchValue(user, { emitEvent: false });
    this.formGroup.updateValueAndValidity();
  }

  private onChange: (v: Partial<PmaInsuredUserComponentValue>) => void;
  registerOnChange(fn: (v: Partial<PmaInsuredUserComponentValue>) => void): void {
    this.onChange = fn;
  }

  private onTouched: (v: Partial<PmaInsuredUserComponentValue>) => void;
  registerOnTouched(fn: (v: Partial<PmaInsuredUserComponentValue>) => void): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): {  invalid: true } | null {
    if (this.formGroup.valid) {
      control.setErrors(null);
      return null;
    }
    control.setErrors({ invalid: true });
    return { invalid: true };
  }
}
