import { Component, Input, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { PmaService } from '../../../pma/pma.service';
import { map, startWith } from 'rxjs/operators';
import { Insurable } from '../../../core/models/insurable.model';

@Component({
  selector: 'ui-unit',
  templateUrl: './ui-unit.component.html',
  styleUrls: ['./ui-unit.component.scss']
})
export class UiUnitComponent {
  @Input() unitControl: FormControl;
  @Input() units;
  @Input() loader = false;
  @Input() nonApplicableControl;
  filteredInsurables$: Observable<Insurable[]>;
  insurables;
  communitiesTypeIds;
  subscription = new Subscription();

  constructor() {
    this.communitiesTypeIds = PmaService.getTypeIdsByName('units');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.units && changes.units.currentValue) {
      this.setFilteredUnits();
    }
  }

  setFilteredUnits() {
    this.filteredInsurables$ = this.unitControl.valueChanges
      .pipe(
        startWith(''),
        map(value => value || ''),
        map(value => typeof value === 'string' ? value : value.title),
        map(title => title ? this.filterInsurables(this.units, title) : this.units.slice(0))
      );
  }

  private filterInsurables(insurables, searchTerm: string): Insurable[] {
    const filterValue = searchTerm.toLowerCase();

    return insurables.filter(insurable => insurable && insurable.title && insurable.title.toLowerCase().includes(filterValue));
  }

  get insurableField() {
    return this.unitControl;
  }

  displayFnInsurable(insurable?: Insurable) {
    return insurable && insurable.title ? insurable.title:
      insurable && insurable.id ? 'N/A' :
        insurable ? insurable : undefined;
  }

  reset() {
    this.insurableField.reset(null);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

