import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'dialog-policy-number',
  templateUrl: './dialog-policy-number.component.html',
  styleUrls: ['./dialog-policy-number.component.css']
})
export class DialogPolicyNumberComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogPolicyNumberComponent>) { }

  ngOnInit(): void {
  }

}
