import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BrandingService } from '../../core/services/branding.service';
import { CLAIMS_URL } from '../layout/header/header-menu/header-menu.component';
import { AuthService } from '../../core/services';
import { MediaMatcher } from '@angular/cdk/layout';

export interface MenuItem {
  url: string;
  title: string;
  items?: Array<MenuItem>;
  isExternalUrl?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MenusService {

  public readonly accountMenuInMain$ = new BehaviorSubject(false);

  public readonly menus$: Observable<{
    main: Array<MenuItem>,
    additional: Array<MenuItem>,
  }> = combineLatest([
    this.brandingService.dropdownMenuHeader$,
    this.brandingService.menuHeader$,
    this.authService.isAuthenticated$,
    this.accountMenuInMain$,
  ]).pipe(
    map(([menuItems, additionalMenuItems, loggedin, accountMenuInMain]) => {

      const mainMenu: Array<MenuItem> = this.addClaimsLink(menuItems?.enabled
        ? [ ...menuItems.values ]
        : [
          { url: '/faq', title: this.translateService.instant('menu.faq') },
          { url: '/About-Us', title: this.translateService.instant('menu.about_us') },
          { url: '/Contact-Us', title: this.translateService.instant('menu.contact_us') }
        ]);

      const additionalMenu: Array<MenuItem> = this.addClaimsLink(additionalMenuItems?.enabled
        ? [ ...additionalMenuItems.values ]
        : [
          { url: '/faq', title: this.translateService.instant('menu.faq') },
          { url: 'https://filemyclaim.io', title: this.translateService.instant('menu.claims') }
        ]);

      if (this.isUpload()) {
        const uploadCoverageProof = {
          url: '/upload-coverage-proof',
          title: this.translateService.instant('menu.upload_insurance'),
        };
        //mainMenu.push(uploadCoverageProof);
        additionalMenu.push(uploadCoverageProof);
      }

      if (loggedin && accountMenuInMain) {
        mainMenu.push({
          url: '',
          title: 'Account',
          items: this.accountMenuItems,
        });
      }

      return {
        main: mainMenu,
        additional: additionalMenu,
      };
    }),
  );

  public readonly mainMenuItems$: Observable<Array<MenuItem>> = this.menus$.pipe(
    map(menus => menus.main),
  );

  public readonly additionalMenuItems$: Observable<Array<MenuItem>> = this.menus$.pipe(
    map(menus => menus.additional),
  );

  constructor(private translateService: TranslateService,
              private authService: AuthService,
              private mediaMatcher: MediaMatcher,
              private brandingService: BrandingService) {

    const matcher = this.mediaMatcher.matchMedia('(max-width: 640px)');
    this.accountMenuInMain$.next(matcher.matches);
    matcher.addEventListener('change', (event) => {
      this.accountMenuInMain$.next(event.matches);
    });
  }

  public isUpload(): boolean {
    const profileAttributes = this.brandingService?.getBrandingInfoSnapshot()?.profile_attributes;
    if (profileAttributes) {
      const isUpload = profileAttributes.find(item => item.name === 'upload_external_policy');
      return isUpload && isUpload.value && isUpload.value === 'true';
    }
    return false;
  }

  private addClaimsLink(menu: Array<MenuItem>): Array<MenuItem> {
    return menu.map(val => {
      return val.url.includes('/claims') ? { ...val, url: CLAIMS_URL} : val;
    });
  }

  public get accountMenuItems(): Array<MenuItem> {
    const menu = [
      { url: '/user/security', title: 'Security' },
      { url: '/user/email-notifications', title: this.translateService.instant('menu.email_notifications') },
      { url: '/user/payments', title: this.translateService.instant('menu.invoices_payments') },
      { url: '/user/payment-methods', title: this.translateService.instant('menu.payment_methods') },
      { url: '/user/policies', title: this.translateService.instant('menu.policies') },
      { url: CLAIMS_URL, title: this.translateService.instant('menu.claims'), isExternalUrl: true }, // Assuming claimsUrl is defined elsewhere
    ];
    if (this.isUpload()) {
      menu.push(
        { url: '/upload-coverage-proof', title: this.translateService.instant('menu.upload_coverage_proof') }
      );
    }
    return menu;
  }
}
