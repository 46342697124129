import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environment';



@Injectable({
  providedIn: 'root'
})
export class FastAPIService {
  constructor(private httpClient: HttpClient) {
  }

  lookup(searchString: string) {
    return this.httpClient.get(`${environment.addressesSearch.fastAPIBase}/lookup?q=${searchString}`);
  }
}
