import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  constructor(private toastr: ToastrService, private router: Router) {

  }

  handleFormErrors(error) {
    if (error && error.error) {
      let toast;
      if (error.message) {
        let message = error.message;
        if (error.payload && error.payload.full_messages) {
          message = `${message}: ${error.payload.full_messages.join(',')}`;
        }
        toast = this.toastr.error(message);
      } else {
        if (error.payload && error.payload.length && error.payload.length > 0) {
          toast = this.toastr.error(error.payload.join(' '));
        } else {
          toast = this.toastr.error(error.error);
        }
      }
      if (error.error === 'auth_error') {
        toast.onTap.pipe(
          take(1),
          tap(() => this.router.navigateByUrl('auth/login'))
        ).subscribe();
      }
    }
  }
}
