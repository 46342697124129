import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

export interface FaqInterface {
  id: number;
  title: string;
  branding_id: number;
  questions: FaqQuestion[];
}

export interface FaqQuestion {
  id: number;
  question: string;
  answer: string;
}

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private httpService: HttpClient) { }

  getFaqs(brandingId: number) {
    return this.httpService.get(`/branding-profiles/${brandingId}/faqs`);
  }
}
