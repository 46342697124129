import { isString } from 'lodash';

/**
 * Branding profile attributes may be set to "true" or "false" as string
 * So we need a little helper to make sure we convert them properly
 * @param str String
 * @param strict True will make sure the string is set to 'true'
 */
export const stringIsTrue = (str: string, strict = false): boolean => {
  if (isString(str)) {
    str = str.trim().toLowerCase();
    return strict ? str === 'true' : str !== 'false';
  }
  console.error('Not a string', str);
  return false;
}

/**
 * Compares strings and compares content after trim and without casing
 * @param s1
 * @param s2
 */
export const haveSameContent = (s1: string, s2: string): boolean => trimLowerCase(s1) === trimLowerCase(s2);

export const trimLowerCase = (s: string) => s.trim().toLowerCase();
