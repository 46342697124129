import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface InsurablesFetchResult {
  id: number;
  title: string;
  account_id: number;
  primary_address: Address;
  insurables: {
    id: number;
    title: string;
  }
  community_with_buildings: boolean;
}

interface Address {
  street_number: string;
  street_name: string;
  street_two?: any;
  city: string;
  state: string;
  zip_code: string;
  plus_four: string;
  full: string;
}


@Injectable({
  providedIn: 'root'
})
export class InsurablesService {

  constructor(private httpClient: HttpClient) {
  }

  getBuildingsAndUnits(communityId: string): Observable<InsurablesFetchResult> {
    return this.httpClient.get<InsurablesFetchResult>(
      `/insurables/communities/${communityId}`
    );
  }
}
