<ng-container *ngIf="!result; else paymentSuccess">
  <div class="text-residential-content">
    <div class="residential-large-text">
      <p [innerHTML]="totalLabel | translate"></p>
      <span class="registration-accent payment-price" [ngClass]="totalNumberClass">
          <img src="./assets/images/underline.svg" alt="blue line" class="underline">
          <ng-container *ngIf="data.invoices.length > 0">{{ data?.invoices[0]?.total_payable | stripeCurrency }}</ng-container>
      </span>
    </div>
    <div class="residential-large-text residential-pay-afterwards" *ngIf="additionalInformation?.billingStrategy?.title === 'Monthly'">
      <p>
        {{ data?.invoices[1]?.total_payable | stripeCurrency }} {{ 'total_premium.pay_afterwards' | translate }}
      </p>
    </div>
    <p class="residential-content-description">
      {{ 'form.price_includes_taxes_and_fees' | translate }}
    </p>
  </div>
  <form class="residential-form-main multy-result">
    <div class="left-result">
      <mat-form-field *ngIf="paymentPlans" appearance="outline">
        <mat-label>Plans</mat-label>
        <mat-select required (valueChange)="onPaymentPlanChanged($event)">
          <mat-option *ngFor="let plan of paymentPlans" [value]="plan">{{plan.title | translate | titlecase }}</mat-option>
        </mat-select>
      </mat-form-field>
      <app-payment-new-carrier
        *ngIf="formType === 'residential' && newCarrier"
        [data]="data"
        [hasBackButton]="hasBackButton"
        [loading]="loader"
        [agreeWithDisclosureParent]="newCarrierAgree"
        [subjectFormSubmit]="subjectFormSubmit"
        (cardStart)="loader = true"
        (cardError)="loader = false"
        (cardSuccess)="processPayment($event)"
        (openDisclosure)="goToDisclosure()"
        (declinePayment)="decline()"
      >
      </app-payment-new-carrier>
      <app-st-elements
        *ngIf="formType !== 'residential' || !newCarrier"
        [loading]="loader"
        [hasBackButton]="hasBackButton"
        (cardFilled)="processPayment($event)"
        (cardComplete)="cardComplete.emit($event)"
        (declinePayment)="decline()">
      </app-st-elements>
    </div>
    <div class="right-result">
      <table class="residential-table-details">
        <thead>
        <tr>
          <td colspan="2" class="title">{{ 'form.premium_breakdown' | translate }}</td>
        </tr>
        </thead>
        <tr *ngIf="formType === 'guarantee'">
          <td>{{ 'rent_guarantee.rent_amount' | translate }}</td>
          <td>${{mergedServerData.fields.monthly_rent}}</td>
        </tr>
        <tr>
          <td>{{ newCarrier? ('total_premium.total_policy_premium' | translate) : ('total_premium.annual_premium' | translate)}}</td>
          <td>{{data.quote.premium.total_premium | stripeCurrency}}</td>
        </tr>
        <tr>
          <td>{{ 'form.taxes' | translate }}</td>
          <td>{{data.quote.premium.total_tax | stripeCurrency}}</td>
        </tr>
        <tr>
          <td>{{newCarrier ? ('total_premium.installment_fees' | translate) : ('total_premium.fees' | translate)}}**</td>
          <td>{{data.quote.premium.total_fee | stripeCurrency}}</td>
        </tr>
        <tr>
          <td>{{ ('total_premium.total_annual_cost' | translate)}}</td>
          <td>{{data.quote.premium.total | stripeCurrency}}</td>
        </tr>
        <tr *ngIf="(additionalInformation && additionalInformation.billingStrategy && additionalInformation.billingStrategy.title)">
          <td>{{ ('total_premium.payment_schedule' | translate)}}</td>
          <td>{{ (additionalInformation && additionalInformation.billingStrategy && additionalInformation.billingStrategy.title)
            ? additionalInformation.billingStrategy.title : '' }}</td>
        </tr>
        <tr>
          <td>{{ ('total_premium.pay_today' | translate)}}</td>
          <td>{{data?.invoices[0]?.total_payable | stripeCurrency}}</td>
        </tr>
        <tr>
          <td colspan="2" class="subscription">
            {{ ('total_premium.est_premium_schedule' | translate) }}
            <p>{{newCarrier ?
              ('total_premium.a_detailed_list_of_all_payments' | translate) :
              ('total_premium.payment_amounts_may_be_subject' | translate) }}</p>
          </td>
        </tr>
      </table>

      <mat-accordion class="total-premium-invoices-accordion">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="total-premium-invoices-accordion__title">
                {{ 'total_premium.payment_schedule' | translate }}
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <table class="residential-table-details">
            <tr>
              <td>{{ 'user.due_date' | translate }}</td>
              <td>{{ 'user.amount_due' | translate }}</td>
            </tr>
            <tr *ngFor="let item of data.invoices; let i=index;">
              <td>{{item.due_date | localizedDate:'MMM dd, yyyy' | titlecase}}</td>
              <td>{{item.total_payable | stripeCurrency}}
                <span *ngIf="i === 0">*</span>
              </td>
            </tr>
          </table>
        </mat-expansion-panel>
      </mat-accordion>

    </div>
  </form>
  <div style="margin: 0 auto;" *ngIf="formType === 'residential'">
    <p style="font-size: 11px;" *ngIf="!newCarrier">{{ 'total_premium.card_processing_fee' | translate }}</p>
    <p style="font-size: 11px; margin-bottom: 10px">
      {{ 'total_premium.first_ins_fee' | translate}}
    </p>
    <p style="font-size: 11px; margin-bottom: 10px">
      {{ 'total_premium.monthly_scheduled' | translate }}
    </p>
    <p style="font-size: 11px;">*{{newCarrier? ('total_premium.first_payment' | translate) : ('total_premium.deposit_is_first_month_only' | translate)}}</p>
    <p style="font-size: 11px;" *ngIf="!newCarrier">**
      <ng-container>
        {{ 'total_premium.annual_administrative' | translate }} <b>{{data.quote.premium.total_fees | stripeCurrency}}</b> {{ 'total_premium.charge_by_description' | translate }}
      </ng-container>
      <!--
            <ng-container *ngIf="environment.title==='cambridge'">
              NOTE: There is an annual administrative fee of <b>$39.95</b> (this amount is included in quote above; please note
              the admin fee is not part of premium cost and will show as a separate payment) charged by Cambridge Insurance - Occupant
              Shield to recover the reasonable costs for administrative services provided to your landlord that are not associated
              with the sale, solicitation, negotiation and servicing of this policy, including, but not limited to, upkeep of all
              mandated properties’ compliance, training and record keeping, tracking and monitoring, as well as risk management and
              other services not related to the premium or commission received.
            </ng-container>-->
    </p>
    <p style="font-size: 11px;" *ngIf="newCarrier">**{{ 'total_premium.a_fee_of' | translate }} {{additionalInformation.coverageOptions.installment_fee | stripeCurrency}} {{ 'total_premium.per_installment_will' | translate }}</p>
  </div>
  <div class="row legal-notice" style="margin: 0 auto;" *ngIf="newCarrier">
    <div class="col-xs-12">
      <h6>{{ 'total_premium.legal_notices' | translate }}</h6>
      <app-total-premium-states [state]="state"></app-total-premium-states>

      <p>{{ 'total_premium.by_clicking_submit_payment' | translate }} <a target="_blank" [routerLink]="['/Terms-of-Use']">{{ 'total_premium.terms_conditions' | translate }}</a>.</p>
      <a href="javascript:void(0)" style="text-decoration: underline" (click)="goToDisclosure()">{{ 'total_premium.click_here_disclosure' | translate }}</a>
    </div>
  </div>
</ng-container>

<ng-template #paymentSuccess>
  <div style="min-height: 600px">
    <form class="residential-form-main final-result">
      <ng-container *ngIf="formType !== 'guarantee'">
        <h2 style="padding-top: 60px;">{{result.title}}</h2>
        <p style="padding-bottom: 60px;">{{result.message}}</p>
      </ng-container>
      <ng-container *ngIf="formType === 'guarantee'">
        <h2>{{ 'total_premium.thank_you_submitting' | translate }}</h2>
        <p>
          <br><br>
          {{ 'total_premium.if_you_are_new_user' | translate }}
        </p>
      </ng-container>
      <br>
      <br>
    </form>
  </div>

</ng-template>
