<div style="font-size: 14px;">
<h2 style="text-align: center;">{{ 'pci.pci_insurance_fraud_provisions_by_state_june' | translate }}</h2>
<table>
  <thead>
  <tr>
    <th>{{ 'form.state' | translate }}</th>
    <th>{{ 'pci.2019_pci_insurance_fraud_provisions_june_29' | translate }}</th>
  </tr>
  </thead>
  <tbody>
    <tr>
      <td>AL</td>
      <td>{{ 'pci.al' | translate }}</td>
    </tr>

    <tr>
      <td>AR</td>
      <td>{{ 'pci.ar' | translate }}</td>
    </tr>

    <tr>
      <td>AZ</td>
      <td>{{ 'pci.az' | translate }}</td>
    </tr>

    <tr>
      <td>CA</td>
      <td>{{ 'pci.ca' | translate }}</td>
    </tr>

    <tr>
      <td>CO</td>
      <td>{{ 'pci.co' | translate }}</td>
    </tr>

    <tr>
      <td>CT</td>
      <td>{{ 'pci.ct' | translate }}</td>
    </tr>

    <tr>
      <td>DC</td>
      <td>{{ 'pci.dc' | translate }}</td>
    </tr>

    <tr>
      <td>DE</td>
      <td>{{ 'pci.de' | translate }}</td>
    </tr>

    <tr>
      <td>FL</td>
      <td>{{ 'pci.fl' | translate }}</td>
    </tr>

    <tr>
      <td>GA</td>
      <td>{{ 'pci.ga' | translate }}</td>
    </tr>

    <tr>
      <td>IA</td>
      <td>{{ 'pci.ia' | translate }}</td>
    </tr>

    <tr>
      <td>ID</td>
      <td>{{ 'pci.id' | translate }}</td>
    </tr>

    <tr>
      <td>IL</td>
      <td>{{ 'pci.il' | translate }}</td>
    </tr>

    <tr>
      <td>IN</td>
      <td>{{ 'pci.in' | translate }}</td>
    </tr>

    <tr>
      <td>KS</td>
      <td>{{ 'pci.ks' | translate }}</td>
    </tr>

    <tr>
      <td>KY</td>
      <td>{{ 'pci.ky' | translate }}</td>
    </tr>

    <tr>
      <td>LA</td>
      <td>{{ 'pci.la' | translate }}</td>
    </tr>

    <tr>
      <td>MD</td>
      <td>{{ 'pci.md' | translate }}</td>
    </tr>

    <tr>
      <td>ME</td>
      <td>{{ 'pci.me' | translate }}</td>
    </tr>

    <tr>
      <td>MA</td>
      <td>{{ 'pci.ma' | translate }}</td>
    </tr>

    <tr>
      <td>MI</td>
      <td>{{ 'pci.mi' | translate }}</td>
    </tr>

    <tr>
      <td>MN</td>
      <td>{{ 'pci.mn' | translate }}</td>
    </tr>

    <tr>
      <td>MO</td>
      <td>{{ 'pci.mo' | translate }}</td>
    </tr>

    <tr>
      <td>MS</td>
      <td>{{ 'pci.ms' | translate }}</td>
    </tr>

    <tr>
      <td>MT</td>
      <td>{{ 'pci.mt' | translate }}</td>
    </tr>

    <tr>
      <td>NC</td>
      <td>{{ 'pci.nc' | translate }}</td>
    </tr>

    <tr>
      <td>ND</td>
      <td>{{ 'pci.nd' | translate }}</td>
    </tr>

    <tr>
      <td>NE</td>
      <td>{{ 'pci.ne' | translate }}</td>
    </tr>

    <tr>
      <td>NH</td>
      <td>{{ 'pci.nh' | translate }}</td>
    </tr>

    <tr>
      <td>NJ</td>
      <td>{{ 'pci.nj' | translate }}</td>
    </tr>

    <tr>
      <td>NM</td>
      <td>{{ 'pci.nm' | translate }}</td>
    </tr>

    <tr>
      <td>NV</td>
      <td>{{ 'pci.nv' | translate }}</td>
    </tr>

    <tr>
      <td>NY</td>
      <td>{{ 'pci.ny' | translate }}</td>
    </tr>

    <tr>
      <td>OH</td>
      <td>{{ 'pci.oh' | translate }}</td>
    </tr>

    <tr>
      <td>OK</td>
      <td>{{ 'pci.ok' | translate }}</td>
    </tr>

    <tr>
      <td>OR</td>
      <td>{{ 'pci.or' | translate }}</td>
    </tr>


    <tr>
      <td>PA</td>
      <td>{{ 'pci.pa' | translate }}</td>
    </tr>

    <tr>
      <td>RI</td>
      <td>{{ 'pci.ri' | translate }}</td>
    </tr>

    <tr>
      <td>SC</td>
      <td>{{ 'pci.sc' | translate }}</td>
    </tr>

    <tr>
      <td>SD</td>
      <td>{{ 'pci.sd' | translate }}</td>
    </tr>

    <tr>
      <td>TN</td>
      <td>{{ 'pci.tn' | translate }}</td>
    </tr>

    <tr>
      <td>TX</td>
      <td>{{ 'pci.tx' | translate }}</td>
    </tr>

    <tr>
      <td>UT</td>
      <td>{{ 'pci.ut' | translate }}</td>
    </tr>

    <tr>
      <td>VA</td>
      <td>{{ 'pci.va' | translate }}</td>
    </tr>

    <tr>
      <td>VT</td>
      <td>{{ 'pci.vt' | translate }}</td>
    </tr>

    <tr>
      <td>WA</td>
      <td>{{ 'pci.wa' | translate }}</td>
    </tr>

    <tr>
      <td>WI</td>
      <td>{{ 'pci.wi' | translate }}</td>
    </tr>

    <tr>
      <td>WV</td>
      <td>{{ 'pci.wv' | translate }}</td>
    </tr>

    <tr>
      <td>WY</td>
      <td>{{ 'pci.wy' | translate }}</td>
    </tr>
  </tbody>
</table>
</div>
