import { Carrier } from '../../core/models';
import { difference, flatMap, intersection, isEmpty, reverse, sortBy } from 'lodash';
import { haveSameContent, jaroWinklerDistance, trimLowerCase } from '../../core/utils';
import { exclude } from 'isbot';


export interface Match {
  distance: number;
  intersection: number;
  priority?: number;
}

export interface SynonymMatch extends Match {
  synonym: string;
}

export interface CarrierMatch extends Match {
  carrier: Carrier;
  synonymMatches: Array<SynonymMatch>;
}


export const carrierSearch = (
  carrierList: Array<Carrier>,
  keyword: string,
  options = {
    minDistance: 0.75,
  }
): Array<CarrierMatch> => {

  // remove carriers that are synonym of a parent
  const synonyms = flatMap(carrierList.map(carrier => carrierSynonyms(carrier))).map(name => name.toLowerCase());
  const synonymCarriers = carrierList.filter(carrier => synonyms.includes(carrier.title.toLowerCase()));

  const carriers = difference(carrierList, []);

  keyword = trimLowerCase(keyword);

  // If keyword is empty, present only preferred carriers in A-Z ORDER
  if (!keyword) {
    const preferredCarriers = carriers.filter(carrier => carrier.confirmed);
    const matches = preferredCarriers.map(carrier => matchCarrier(carrier, ''));
    return sortBy(matches, match => match.carrier.title);
  }

  const minDistance = options.minDistance;
  const matches = sortBy(carriers.map(carrier => matchCarrier(carrier, keyword)), 'distance').reverse();
  const preferredCarrierMatches = matches.filter(match => match.carrier.confirmed);
  const otherCarrierMatches = difference(matches, preferredCarrierMatches);

  /*
    Best matches are the ones for preferred carriers where a keyword match occurs in the title or synonyms
   */
  const bestPreferredMatches = preferredCarrierMatches.filter(match => {
    return match.intersection > 0 || match.synonymMatches.some(match => match.intersection > 0);
  });

  /*
    2nd best matches are preferred carriers with similarities based on distance
   */
  const secondBestPreferredMatches = difference(preferredCarrierMatches, bestPreferredMatches).filter(match => {
    return match.distance >= minDistance || match.synonymMatches.some(match => match.distance >= minDistance);
  });

  /*
    3rd best matches are the ones that are not preferred where a keyword match occurs in the title or synonyms
   */
  const thirdPreferredMatches = otherCarrierMatches.filter(match => {
    return !match.carrier.confirmed && (match.intersection > 0 || match.synonymMatches.some(match => match.intersection > 0));
  });

  /*
    4th best matches are the ones that are not preferred with similarities based on distance
   */
  const fourthPreferredMatches = difference(otherCarrierMatches, thirdPreferredMatches).filter(match => {
    return match.carrier.confirmed && (match.distance >= minDistance || match.synonymMatches.some(match => match.distance >= minDistance));
  });

  // Add priority to identify results as best, second, .... for menus
  const ordered = [
    bestPreferredMatches,
    secondBestPreferredMatches,
    thirdPreferredMatches,
    fourthPreferredMatches,
  ];

  return flatMap(ordered.map((matches, i) => {
    return matches.map(match => ({ ...match, priority: i }))
  }));
};

const carrierSynonyms = (carrier: Carrier): Array<string> => {
  const synonymString = carrier.synonyms?.trim() ?? '';
  return synonymString ? synonymString.split(',') : [];
}

const matchCarrier = (carrier: Carrier, keyword?: string): CarrierMatch => {
  const synonyms = carrierSynonyms(carrier);
  const synonymMatches: Array<SynonymMatch> = synonyms.map(synonym => {
    const match = keywordMatchAgainstString(keyword, synonym);
    return {
      synonym,
      ...match,
    }
  });

  const carrierNameMatch = keywordMatchAgainstString(keyword, carrier.title);

  return {
    carrier,
    ...carrierNameMatch,
    synonymMatches,
  };
};

const keywordMatchAgainstString = (keyword: string, comparisonString: string): Match => {
  keyword = trimLowerCase(keyword);
  comparisonString = trimLowerCase(comparisonString);
  const index = keyword ? comparisonString.indexOf(keyword) : -1;
  return {
    distance: jaroWinklerDistance(comparisonString, keyword),
    intersection: keyword && index > -1 ? intersection(comparisonString.split(''), keyword.split('')).length : 0,
  };
};
