<form class="auth-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'form.email' | translate }}</mat-label>
    <input matInput type="email" formControlName="email" required>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ 'form.password' | translate }}</mat-label>
    <input matInput type="password" formControlName="password" required>
  </mat-form-field>
  <div class="login-form-option">
    <label for="remember-me">
      <input id="remember-me" formControlName="remember_me" type="checkbox">
      {{ 'form.remember_me' | translate }}
    </label>
    <a [routerLink]="['/auth/forgot-password']">{{ 'form.forgot_password' | translate }}</a>
  </div>
  <div class="login-register-block">
    <p>
      <ng-container *ngIf="!(isRegisterDisabled$ | async)">
        {{ 'form.new_to' | translate }} <i>{{(brandingService.brandingInfo$ | async).title}}</i>?
        <u><a [routerLink]="['/auth/register']">{{ 'form.register' | translate }}</a></u> {{ 'form.for_an_account' | translate }}.
      </ng-container>
    </p>
    <div class="login-register-block__btn-container">
      <button
        mat-button
        type="submit"
        style="width: 140px; line-height: 20px; padding: 13px 0;"
        class="mat-btn warning test-login">
          {{ 'form.login' | translate }}
      </button>
    </div>
  </div>
</form>

