import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PagesService {
  constructor(private httpService: HttpClient) { }

  getPage(id: number) {
    return this.httpService.get(`/pages/${id}`);
  }
}
