<mat-accordion [hidden]="!question" [multi]="true" #accordion="matAccordion" class="new-faq-question">
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="new-faq-question-header">
      <mat-panel-title>
        <div [innerHTML]="question" [style.color]="faqColor$ | async"></div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="new-faq-content" [innerHTML]="answer"></div>
  </mat-expansion-panel>
</mat-accordion>
