import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { BrandingService } from '../../../../core/services/branding.service';
import { AuthService } from '../../../../core/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

export interface LanguageInterface {
  title: string;
  value: string;
}

interface LanguageState {
  currentLanguage: string;
}

const initialLanguageState: LanguageState = {
  currentLanguage: null
};

@Injectable({
  providedIn: 'root'
})
export class SwitchLanguagesService {
  public static LANGUAGES: LanguageInterface[] = [
    { value: 'en', title: 'English' },
    { value: 'es', title: 'Español' }
  ];

  private store = new BehaviorSubject(initialLanguageState);
  public currentLanguage: Observable<string> = this.store.asObservable().pipe(
    map(state => state.currentLanguage)
  );

  private defaultLanguage = 'en';
  private spanishLanguage = 'es';

  constructor(
    private adapter: DateAdapter<Date>,
    private brandingService: BrandingService,
    private translate: TranslateService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  setCurrentLanguage(language: string) {
    if (this.isAllowedLanguage(language)) {
      this.translate.use(language);
      localStorage.setItem('Accept-Language', language);
      this.adapter.setLocale(language);
      this.brandingService.refreshStore();
      this.setStoreLanguage(language);
    }
  }

  isAllowedLanguage(language: string): boolean {
    return SwitchLanguagesService.LANGUAGES.findIndex(lang => lang.value === language) > -1;
  }

  setStoreLanguage(language: string) {
    this.store.next({
      currentLanguage: language
    });
  }

  getCurrentLanguage(): string {
    const user = this.authService.getCurrentUser();
    const userLanguage = user && user.profile_attributes && user.profile_attributes.langugage ? user.profile_attributes.langugage : null;
    const storedLanguage = localStorage.getItem('Accept-Language');
    return userLanguage ? userLanguage : storedLanguage ? storedLanguage : this.isSpanish() ? this.spanishLanguage : this.defaultLanguage;
  }

  isSpanish() {
    if (isPlatformBrowser(this.platformId)) {
      return window.navigator.language.includes(this.spanishLanguage);
    } else {
      return this.translate.getBrowserLang;
    }
  }

  initCurrentLanguage() {
    const language = this.getCurrentLanguage();
    this.setCurrentLanguage(language);
  }
}
