<div class="privacy-policy">

  <div [innerHTML]="'privacy_policy.get_covered_privacy_notice' | translate"></div>

  <ol>
    <li [innerHTML]="'privacy_policy.what_information_do_we_collect' | translate"></li>

    <li [innerHTML]="'privacy_policy.how_we_use_cookies' | translate"></li>

    <li [innerHTML]="'privacy_policy.sharing_your_information' | translate"></li>

    <li [innerHTML]="'privacy_policy.control_over_your_information' | translate"></li>

    <li [innerHTML]="'privacy_policy.third_party_tracking_and_online_advertising' | translate"></li>

    <li [innerHTML]="'privacy_policy.how_we_store_and_protect_your_information' | translate"></li>

    <li [innerHTML]="'privacy_policy.links_to_other_web_sites_and_services' | translate"></li>

    <li [innerHTML]="'privacy_policy.how_to_contact_us' | translate"></li>

    <li [innerHTML]="'privacy_policy.changes_to_out_privacy_notes' | translate"></li>
  </ol>

</div>
