import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'stripeCurrency' })
export class StripeCurrencyPipe implements PipeTransform {
  transform(value: string | number): string {
    const symbol = '$';

    if (isNaN(Number(value))) {
      return symbol + 0;
    }

    const tmpVal = ((+value) / 100.0).toFixed(2);
    let tmpStringVal = tmpVal.toString();
    const regexPattern = /(-?\d+)(\d{3})/;

    while (regexPattern.test(tmpStringVal)) {
      tmpStringVal = tmpStringVal.replace(regexPattern, '$1,$2');
    }

    return symbol + tmpStringVal;
  }
}
