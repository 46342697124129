import { Inject, Injectable } from '@angular/core';
import {Meta, MetaDefinition, Title} from '@angular/platform-browser';
import {HOST_URL} from './tokens/host-url';
import { Router } from '@angular/router';
import {DOCUMENT} from '@angular/common';
import { brandingProfileStore } from '../../server/branding-profile-store';

@Injectable()
export class MetadataService {
  constructor(private metaTagService: Meta,
              @Inject(DOCUMENT) private dom,
              private titleService: Title,
              @Inject(HOST_URL) private hostUrl: string,
              private router: Router) {}

  public updateMetadata(title = 'Get Covered',
                        image = 'https://gc-public-prod.s3.us-west-2.amazonaws.com/assets/GetCovered_Umbrella_Icon_Purple.png',
                        index: boolean = true): void {
    const tags: MetaDefinition[] = this.generateMetaDefinitions(title);
    this.setAppleTouchIcon(image);
    this.metaTagService.addTags([
      ...tags,
      { property: 'og:url', content: `${this.hostUrl}${this.router.url}`},
      { name: 'robots', content: index ? 'index, follow' : 'noindex' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' }
    ], false);
    this.titleService.setTitle(title);
  }

  private generateMetaDefinitions(title): MetaDefinition[] {
    return [
      // { name: 'title', content: title },
      { property: 'og:title', content: title },
      // { name: 'description', content: description },
      // { property: 'og:description', content: description },
      // { name: 'author', content: author },
      // { property: 'og:author', content: author },
      // { name: 'keywords', content: keywords.join(', ') },
      // { property: 'og:type', content: type },
      // { property: 'og:image', content: image}
    ];
  }

  private setAppleTouchIcon(image) {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'apple-touch-icon');
    link.setAttribute('href', image);
    this.dom.head.appendChild(link);
  }

  public getValueByField(field) {
    const brandingProfile = brandingProfileStore.getProfile();
    let found = null;
    if (brandingProfile) {
      found = brandingProfile.profile_attributes.find(attr => attr.name === field);
    }
    return found && found.value ? found.value : found;
  }
}
