import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-total-premium-states',
  templateUrl: './total-premium-states.component.html',
  styleUrls: ['./total-premium-states.component.css']
})
export class TotalPremiumStatesComponent implements OnInit {

  @Input() state;

  constructor() { }

  ngOnInit(): void {
  }

}
