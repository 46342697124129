<mat-form-field appearance="outline">
  <mat-label>Property Management Company</mat-label>
  <input required
         [formControl]="accountControl"
         [matAutocomplete]="autoAccounts"
         matInput
         (blur)="onBlur(autoAccounts)"
         placeholder="Please start typing property management company’s name"
  >
  <mat-autocomplete
    autoActiveFirstOption
    #autoAccounts="matAutocomplete"
    [displayWith]="displayFnAccount">
    <mat-option *ngFor="let account of filteredAccounts$ | async" [value]="account">
      <span class="caption">{{ account.title }}</span>
    </mat-option>
  </mat-autocomplete>
  <button *ngIf="isLoading" class="text-secondary spinner" mat-icon-button matSuffix type="button">
    <mat-icon>loading</mat-icon>
  </button>
  <button *ngIf="!isLoading && accountControl.value && accountControl.enabled" mat-icon-button matSuffix (click)="reset()" type="button">
    <mat-icon>close</mat-icon>
  </button>
  <button *ngIf="!isLoading && !accountControl.value && accountControl.enabled && (filteredAccounts$ | async)?.length"
          class="text-secondary" mat-icon-button matSuffix type="button">
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <mat-error *ngIf="accountControl.invalid">{{ accountControl.errors | errorMsg:accountControl:null:null }}</mat-error>

  <mat-hint *ngIf="(filteredAccounts$ | async)?.length === 0">
    <ng-container *ngIf="(accountControlStringValue$ | async)?.length as charLen">
      <ng-container *ngIf="charLen > 0 && charLen < minCharacters">
        Please enter at least {{ minCharacters }} characters to find a company.
      </ng-container>
      <ng-container *ngIf="charLen >= minCharacters;">
        No accounts found, please change search terms.
      </ng-container>
    </ng-container>
  </mat-hint>
</mat-form-field>

<ng-template #loading>
  <mat-spinner></mat-spinner>
</ng-template>
