import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../../core/services';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'pma-documents-reviewed',
  templateUrl: './pma-documents-reviewed.component.html',
  styleUrls: ['./pma-documents-reviewed.component.css']
})
export class PmaDocumentsReviewedComponent implements OnInit {
  @Input() firstName = '';
  @Input() communityName = 'Community Name';
  @Input() buildingName;
  @Input() unitName = 'Unit Number';
  loader = false;
  passwordControl = new FormControl();
  verifyPasswordControl = new FormControl();
  submitted = true;
  token = 'get-token-from-API';

  constructor(private authService: AuthService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
  }

  setPassword() {
    this.loader = true;
    this.authService.invitationResetPassword({
      password: this.passwordControl.value,
      password_confirmation: this.verifyPasswordControl.value,
      invitation_token: this.token
    }).pipe(
      finalize(() => this.loader = false)
    ).subscribe(
      (result: any) => {
        this.toastr.success(result.success);
        this.submitted = true;
      },
      error => {
        this.toastr.error(error.errors);
      }
    );
  }

  get isPasswordsMatch() {
    return this.passwordControl.value &&
      this.verifyPasswordControl.value &&
      this.passwordControl.value === this.verifyPasswordControl.value;
  }
}
