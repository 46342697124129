import { Pipe, PipeTransform } from '@angular/core';
import { BrandingService } from '../../core/services/branding.service';

@Pipe({
  name: 'displayBrandingAttr'
})
export class DisplayBrandingAttrPipe implements PipeTransform {

  constructor(private brandingService: BrandingService) {
  }

  transform(value: string, property: string = null): string {
    const branding = this.brandingService.getBrandingInfoSnapshot();

    if (property) {
      return branding[property].find(item => item.name === value)?.value;
    }

    return branding[value];
  }

}
