import { merge, Observable, Subject } from 'rxjs';
import { flatMap, isArray } from 'lodash';
import { map } from 'rxjs/operators';

/**
 * A singleton class to watch elements and be notified when they become visible or not in the view port.
 * Static class
 * Make sure you stop observing when the element is being destroyed
 */
export class ViewPortVisibilityObserver {

  /**
   * Observables for each element
   * @private
   */
  private static observables = new WeakMap<Element, Subject<boolean>>();

  /**
   * The intersection observer
   * @private
   */
  private static intersectionObserver: IntersectionObserver;

  private static readonly mutationObservers
    = new WeakMap<{ parent: Element, cssSelector: string }>();

  private constructor() {
  }

  /**
   * Observes the element
   * @param element
   */
  public static observe(element: Element): Observable<boolean> {
    if (this.observables.has(element)) {
      return this.observables.get(element);
    }
    if (!this.intersectionObserver) {
      this.intersectionObserver = new IntersectionObserver( (entries) => {
        entries.forEach(entry => {
          if (!this.observables.has(entry.target)) {
            return;
          }
          this.observables.get(entry.target).next(entry.isIntersecting);
        });
      });
    }
    const subject = new Subject<boolean>();
    this.observables.set(element, subject);
    this.intersectionObserver.observe(element);
    return subject.asObservable();
  }


  /**
   * Stops observing the element
   * @param element
   */
  public static stopObserving(element: Element | Array<Element>): void {
    const elements = isArray(element) ? element : [ element ];
    elements.forEach(element => {
      this.observables.delete(element);
      if (Object.entries(this.observables).length === 0 && this.intersectionObserver) {
        this.intersectionObserver.disconnect();
        this.intersectionObserver = null;
      }
    });
  };
}
