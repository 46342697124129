import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'dialog-match-user',
  templateUrl: './dialog-match-user.component.html',
  styleUrls: ['./dialog-match-user.component.scss']
})
export class DialogMatchUserComponent implements OnInit {
  matchUserControl = new FormControl();
  users;

  constructor(public dialogRef: MatDialogRef<DialogMatchUserComponent>,
              @Inject(MAT_DIALOG_DATA) public matData: any) {
    this.users  = matData;
  }

  ngOnInit(): void {
  }

}
