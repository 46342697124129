import { InsurableByTokenResponse } from './pma.service';
import { pick } from 'lodash';
import { FormControl } from '@angular/forms';

/**
 * Used to be in a service but not needed as it is a simple function
 * TODO type everything especially form and control
 * @param form
 * @param communityControl
 * @param nonpreferredAddressSelection
 * @param payload
 */
export const setGeneralData = (form, communityControl, nonpreferredAddressSelection: boolean, payload: InsurableByTokenResponse, buildingControl: FormControl, pmaSingleHouses = false): void => {
  form.get('first_name').setValue(payload.user?.profile?.first_name);
  form.get('last_name').setValue(payload.user?.profile?.last_name);
  form.get('middle_name').setValue(payload.user?.profile?.middle_name);
  form.get('email').setValue(payload.user?.email);

  const addressAttributes = addressAttributesFroInsurableByTokenResponse(payload);
  if (!nonpreferredAddressSelection) {
    // timeout needed to make sure state field will react on the change and will fetch list of communities after its value changed
    setTimeout(() => {
      form.get('state').setValue(addressAttributes.state, { emitEvent: false });
    }, 500);
  }
  const fullAddress = addressAttributes.full;
  if (nonpreferredAddressSelection) {
    form.get('address').setValue(fullAddress, { emitEvent: false });
  }

  setTimeout(() => {
    communityControl.setValue({ id: payload.community?.id, title: payload.community?.title }, { emitEvent: true });

    if (!pmaSingleHouses) {
      form.get('unit').enable();
    }
  }, 501);

  if (!pmaSingleHouses) {
    const primaryInsurableAttributes = payload && payload.unit ? payload.unit : {};

    const building = payload?.building;
    const unit = payload?.unit;
    setTimeout(() => {
      if (building?.id) {
        buildingControl.setValue({
          id: building?.id,
          title: building?.title,
          units: building?.units
        }, {emitEvent: false});
      }
      setTimeout(() => {
        if (unit?.id) {
          form.get('unit').setValue({id: unit?.id, title: unit?.title});
          form.get('unit').enable();
        }
      }, 1000);
    }, 550);
  } else {
    form.get('insurable_id').setValue(payload?.unit?.id);
  }
};

export const addressAttributesFroInsurableByTokenResponse = (
  response: InsurableByTokenResponse
): { state: string, full: string, county: string } => {
  const attr = response?.community.primary_address;
  if (attr) {
    return pick(attr, ['state', 'full', 'county']);
  }
  return { state: '', full: '', county: '' };
};
