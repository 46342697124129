<mat-dialog-content>
  <p style="margin-bottom: 15px;">
    {{ 'tenant_boarding.additional_interest_allows_the_named' | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="end start"
                    fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign.xs="center center">
  <button mat-button
    class="mat-btn outline"
    (click)="dialogRef.close(false)">
    Close
  </button>
</mat-dialog-actions>
