<div class="auth-form">
  <form [formGroup]="forgetForm" #formDirective="ngForm" class="auth-form" (ngSubmit)="submitForm(formDirective)">
    <mat-form-field appearance="outline">
      <mat-label>{{'form.email' | translate }}</mat-label>
      <input matInput type="email" placeholder="Email" formControlName="email" required>
    </mat-form-field>

    <hr>

    <button mat-button
            type="submit"
            class="mat-btn warning test-forget"
            [disabled]="!forgetForm.valid && submitted"
    >
      {{'user.send_reset_link' | translate }}
    </button>
  </form>

  <p class="forgot-password-login">
    {{'form.already_have_an_account' | translate }}?
    <a class="gc-link" [routerLink]="['/auth/login']">{{ 'form.login' | translate }}</a>.
  </p>
</div>
