import { YMDDate } from '../models';
import { isString } from 'lodash';

/**
 * Validates a string as a date
 * @param v
 */
export const isValidDateString = (v: string): boolean => !isNaN(Number(new Date(v)));

/**
 * Validates a date against the YYYY-MM-DD format
 * and makes sure the date is valid
 * @param v
 */
export const isYMDDate = (v: any): v is YMDDate => {
  if (!isString(v)) {
    return false;
  }
  const split = v.split('-');
  if (split.length != 3) {
    return false;
  }
  const [year, month, date] = split;
  if (year.length !== 4 || month.length > 2 || month.length < 1 || date.length > 2 || date.length < 1) {
    return false;
  }
  return isValidDateString(v);
};

/**
 * Converts JS date to YYYY-MM-DD format
 * @param date
 */
export const dateToYMDDate = (date: Date): YMDDate => {
  return date.toISOString().slice(0, 10);
};
