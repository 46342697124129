
import { Component, Input } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BrandingService } from 'src/app/core/services/branding.service';
import { IframeModeService } from '../../../../core/services/iframe-mode.service';

interface ISize {
  width: number;
  height: number;
  ratio?: number;
}

@Component({
  selector: 'app-logo-section',
  templateUrl: './logo-section.component.html',
  styleUrls: ['./logo-section.component.scss']
})
export class HeaderLogoSectionComponent {

  headerLogoUrl;
  headerSecondLogoUrl;
  headerLogo$: Observable<string> = this.brandingService.headerLogo$;
  headerSecondLogo$: Observable<string> = this.brandingService.headerSecondLogo$;
  isWideLogo$ = this.brandingService.isWideLogo$;
  isLogoLoading = true;
  isSecondLogoLoading = true;
  isWideLogo = false;
  isSquareLogo = false;
  isSquareSecondLogo = false;
  IframeMode = false;
  get isSecondLogo() {
    return this.headerSecondLogoUrl && this.headerLogoUrl;
  }
  get getLogoUrl() {
    return this.headerLogoUrl ? this.headerLogoUrl : this.headerSecondLogoUrl;
  }

  constructor(private brandingService: BrandingService, private IframeModeService: IframeModeService) {
    this.getLogos();
    IframeModeService.iframeMode$.subscribe(IframeMode => this.IframeMode = IframeMode);
  }

  getLogos() {
    this.isLogoLoading = true;
    this.headerLogo$.pipe(take(1)).subscribe(headerUrl => {
      this.headerLogoUrl = headerUrl;
      this.getLogoSize(headerUrl).subscribe((iSize: ISize) => {
        this.isWideLogo = iSize.ratio >= 4.4;
        this.isSquareLogo = this.setSquareLogo(iSize);
        this.isLogoLoading = false;
      });
    });
    this.isSecondLogoLoading = true;
    this.headerSecondLogo$.pipe(
      take(1)
    ).subscribe(headerUrl => {
      this.headerSecondLogoUrl = headerUrl;
      this.getLogoSize(headerUrl).subscribe((iSize: ISize) => {
        this.isSquareSecondLogo = this.setSquareLogo(iSize);
        this.isSecondLogoLoading = false;
      });
    });
  }

  setSquareLogo(iSize: ISize) {
    return Math.abs(iSize.width - iSize.height) < 160 || iSize.height > iSize.width;
  }


  getLogoSize(imageSrc: string): Observable<ISize> {
    const mapLoadedImage = (event): ISize => {
      return {
        width: event.target.width,
        height: event.target.height,
        // https://stackoverflow.com/a/11832950
        ratio: Math.round(event.target.width / event.target.height * 100) / 100
      };
    };

    const image = new Image();
    image.src = imageSrc;

    return fromEvent(image, 'load').pipe(map(mapLoadedImage));
  }

}
