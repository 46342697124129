<mat-form-field fxFlex="100%" appearance="outline">
  <mat-label>Building</mat-label>
  <input required [formControl]="insurableField" [matAutocomplete]="autoInsurable" matInput>
  <mat-autocomplete autoActiveFirstOption #autoInsurable="matAutocomplete" [displayWith]="displayFnInsurable">
    <mat-option *ngFor="let insurable of filteredInsurables$ | async" [value]="insurable">
      <span class="caption">{{ insurable.title }}</span>
    </mat-option>
  </mat-autocomplete>
  <button *ngIf="loader" class="text-secondary spinner" mat-icon-button matSuffix type="button">
    <mat-icon>loading</mat-icon>
  </button>
  <button *ngIf="!loader && insurableField.value && insurableField.enabled" mat-icon-button matSuffix (click)="reset()" type="button">
    <mat-icon>close</mat-icon>
  </button>
  <button *ngIf="!loader && !insurableField.value && insurableField.enabled" class="text-secondary" mat-icon-button matSuffix type="button">
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <mat-error *ngIf="insurableField.invalid">{{ insurableField | errorMsg:'Building' }}</mat-error>
</mat-form-field>
