import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { JwtService } from '../services';
import {environment} from '@environment';
import { catchError, tap } from 'rxjs/operators';
import { SwitchLanguagesService } from '../../shared/layout/header/switch-languages/switch-languages.service';


@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private switchLanguagesService: SwitchLanguagesService,
    private inj: Injector,
    private jwtService: JwtService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('i18n')
      || !this.needToModify(req.url)
      // || !this.getAuthData('accessToken')
    ) {
      return next.handle(req);
    }

    const headersConfig = {};
    const token = this.jwtService.getToken();
    if (token) {
      headersConfig['access-token'] = token.access_token;
      headersConfig['uid'] = token.uid;
      headersConfig['client'] = token.client;
      headersConfig['token-type'] = token.token_type;
      headersConfig['expiry'] = token.expiry;
    }

    const request = req.clone({
        url: this.getModifiedUrl(req.url),
        setHeaders: headersConfig
    });
    return next.handle(request).pipe(
      tap(res => {if (res instanceof HttpResponse) { this.updateToken(res); } }),
      catchError((e: any) => {
        if (e instanceof HttpErrorResponse) {
          this.updateToken(e);
        } else if (e && e.originError) {
          this.updateToken(e.originError);
        }
        return throwError(e);
      })
    );
  }

  getModifiedUrl(requestUrl): string {
    if (requestUrl && (requestUrl.includes('http://') || requestUrl.includes('https://'))) {
      return  requestUrl;
    }
    return environment.apiEndpoint + requestUrl;
  }

  updateToken(req: HttpResponse<any> | HttpErrorResponse) {
    if (!req.headers.get('access-token') || req.headers.get('access') === '') {
      return;
    }
    const header = {
      access_token: req.headers.get('access-token'),
      token_type  : req.headers.get('token-type'),
      client      : req.headers.get('client'),
      expiry      : req.headers.get('expiry'),
      uid         : req.headers.get('uid')
    };
    this.jwtService.saveToken(header);
  }

  needToModify(url) {
    return !url.includes(environment.apiEndpoint) && !url.includes('np_units.json');
  }

  getAuthData(key) {
    return localStorage.getItem(key);
  }
}
