
<form id="firstdataForm">
  <div fxLayout="row">
    <div fxFlex="50%">
      <div class="payment-field-container">
        <label class="required-label" for="first-data-payment-field-card">{{ 'form.card' | translate }}</label>
        <div id="cc-card" data-cc-card class="external-input-field"></div>
        <div class="error-message" *ngIf="formSent && errorMessages['card']">{{errorMessages['card']}}</div>
      </div>
    </div>
    <div fxFlex="50%">
      <div class="payment-field-container">
        <label class="required-label" for="first-data-payment-field-name">{{ 'form.name' | translate }}</label>
        <div id="cc-name" data-cc-name class="external-input-field"></div>
        <div class="error-message" *ngIf="formSent && errorMessages['name']">{{errorMessages['name']}}</div>
      </div>
    </div>
  </div>


  <div fxLayout="row">
    <div fxFlex="50%">
      <div class="payment-field-container">
        <label class="required-label" for="first-data-payment-field-exp">{{ 'form.exp' | translate }}</label>
        <div id="cc-exp" data-cc-exp class="external-input-field"></div>
        <div class="error-message" *ngIf="formSent && errorMessages['exp']">{{errorMessages['exp']}}</div>
      </div>
    </div>
    <div fxFlex="50%">
      <div class="payment-field-container">
        <label class="required-label" for="first-data-payment-field-cvv">{{ 'form.cvv' | translate }}</label>
        <div id="cc-cvv" data-cc-cvv class="external-input-field"></div>
        <div class="error-message" *ngIf="formSent && errorMessages['cvv']">{{errorMessages['cvv']}}</div>
      </div>
    </div>
  </div>

    <div class="mailing-ch-container">
        <label>
            <mat-checkbox [(ngModel)]="mailingAddress"
                          [ngModelOptions]="{standalone: true}"
            >{{ 'msi_payment.billing_address_same_as_mailing' | translate }} <strong>({{address.street_name}},
                <ng-container *ngIf="address.street_two != ''">{{address.street_two}} </ng-container>{{address.city}}, {{address.state}}, {{address.zip_code}})</strong></mat-checkbox>
        </label>
    </div>

    <ng-container *ngIf="!mailingAddress">
        <div class="payment-field-container payment-input">
            <label class="required-label" for="first-data-payment-field-address1">{{ 'form.address' | translate }}</label>
            <input id="address1" [placeholder]="('form.address' | translate)" [(ngModel)]="address1" [ngModelOptions]="{standalone: true}">
            <div class="error-message" *ngIf="formSent && !address1">{{ 'msi_error.the' | translate }} {{ 'form.address' | translate | lowercase }} {{
                'msi_error.field_is_required' |
                translate }}</div>
        </div>

        <div class="payment-field-container payment-input">
            <label for="address2">{{ 'form.address_two' | translate }}</label>
            <input id="address2" [placeholder]="('form.address_two' | translate)" [(ngModel)]="address2" [ngModelOptions]="{standalone: true}">
<!--            <div class="error-message" *ngIf="formSent && errorMessages['address2']">{{errorMessages['address2']}}</div>-->
        </div>
      <div fxLayout="row">
        <div fxFlex="50%">
          <div class="payment-field-container payment-input">
            <label class="required-label" for="first-data-payment-field-city">{{ 'form.city' | translate }}</label>
            <input id="first-data-payment-field-city" [placeholder]="('form.city' | translate)" [(ngModel)]="city" [ngModelOptions]="{standalone: true}">
            <div class="error-message" *ngIf="formSent && !city">{{ 'msi_error.the' | translate }} {{ 'form.city' | translate | lowercase }}
                {{ 'msi_error.field_is_required' | translate }}</div>
          </div>
        </div>
        <div fxFlex="50%" class="payment-field-container payment-selector">
          <label class="required-label" for="state">{{ 'form.state_or_province' | translate }}</label>
          <select [(ngModel)]="state" id="state" [ngModelOptions]="{standalone: true}">
              <option value="" disabled></option>
              <option *ngFor="let state of stateList; let i=index;" [ngValue]="state">{{state}}</option>
          </select>
          <div class="error-message" *ngIf="formSent && !state">{{ 'form.state_required' | translate }}</div>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="100%">
          <div class="payment-field-container payment-input">
            <label class="required-label" for="zip">{{ 'residential.postal_code' | translate }}</label>
            <input type="text" id="zip" [(ngModel)]="zip" [ngModelOptions]="{standalone: true}" mask="00000" [placeholder]="('residential.postal_code' | translate)">
            <div class="error-message" *ngIf="formSent && !zip">{{ 'form.postal_code_required' | translate }}</div>
          </div>
        </div>
      </div>
    </ng-container>
  <mat-checkbox class="agree-ch"
                [(ngModel)]="agreeWithDisclosure"
                [ngModelOptions]="{standalone: true}"
                *ngIf="agreeWithDisclosure"
                disableRipple
  >{{ 'form.have_read_agree' | translate }} <a href="#" (click)="$event.preventDefault();$event.stopPropagation();openDisclosure.emit()" style="color:var(--primary-color);">{{'total_premium.the_standard_disclosure' | translate}}</a></mat-checkbox>

  <div class="guarantee__btns-container" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="stretch">
    <button type="button" mat-button class="mat-btn warningmsi-agree-btn" (click)="openDisclosure.emit()" *ngIf="!agreeWithDisclosure">
      {{ 'total_premium.purchase' | translate }}
    </button>
    <button id="submit" mat-button
            class="mat-btn warning"
            [disabled]="loading || !agreeWithDisclosure"
            [class.spinner]="loading"
            *ngIf="agreeWithDisclosure"
    >
      {{ 'total_premium.purchase' | translate }}
    </button>
    <button mat-button class="mat-btn outline" *ngIf="hasBackButton" (click)="decline($event)">{{ 'form.go_back' | translate }}</button>
  </div>
</form>
