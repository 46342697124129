import { Component, OnInit } from '@angular/core';
import { ChangePasswordFormType } from '../change-password-form/change-password-form.component';
import { BrandingService } from '../../core/services/branding.service';

@Component({
  selector: 'app-accept-invitation',
  templateUrl: './accept-invitation.component.html'
})
export class AcceptInvitationComponent implements OnInit {
  type = ChangePasswordFormType.AcceptInvitation;
  constructor(private brandingService: BrandingService) { }

  ngOnInit(): void {
    this.brandingService.setBrandingTitle('Accept Invitation');
  }
}
