import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import * as _moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'errorMsg',
  pure: false
})
export class FormErrorMsgPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  errorMessages = {
    required: 'errorMessages.required',
    email: 'errorMessages.email',
    emailTaken: 'errorMessages.emailTaken',
    minlength: 'errorMessages.minlength',
    maxlength: 'errorMessages.maxlength',
    date: 'errorMessages.date',
    matDatepickerMin: 'errorMessages.matDatepickerMin',
    matDatepickerMax: 'errorMessages.matDatepickerMax',
    objectId: 'errorMessages.objectId',
    min: 'errorMessages.min',
    max: 'errorMessages.max',
    mask_errors: {
      phone: 'errorMessages.mask_errors_phone',
      zip: 'errorMessages.mask_errors_zip',
    },
    objectRequired: 'errorMessages.objectRequired',
    autocompleteNotSelected: 'errorMessages.autocompleteNotSelected',
    mustMatch: 'errorMessages.mustMatch',
    in_system: 'errorMessages.in_system',
    invalidState: 'errorMessages.invalidState',
    sameEmail: 'errorMessages.sameEmail'
  };

  error;
  errorValue;
  control;
  controlName;
  mask;
  customErrorMessages;

  transform(control, customFieldName = null, mask = null, customErrorMessages = null): string {
    const errors = control.errors;
    if (!errors) {
      return;
    }

    if (!(control instanceof AbstractControl)) {
      throw new ReferenceError('Specify control when using FormErrorMsgPipe');
    }
    this.error = Object.keys(errors)[0];
    this.errorValue = Object.values(errors)[0];
    this.control = control;
    this.controlName = customFieldName ? customFieldName : this.getControlName();
    this.mask = mask;
    this.customErrorMessages = customErrorMessages;

    return this.getErrorMessage();
  }

  getErrorMessage() {
    try {
      const errorMessageTemplate = (this.mask && (this.error === 'Mask error' || this.error === 'mask'))
        ? this.getMaskTemplate()
        : this.getErrorMessageTemplate();
      return this.replaceVariables(errorMessageTemplate);
    } catch (error) {
      // TODO: Implement custom error displaying here
      throw new Error(error);
    }
  }

  getErrorMessageTemplate() {
    if (this.customErrorMessages && this.customErrorMessages[this.error]) {
      return this.customErrorMessages[this.error];
    }

    if (this.errorMessages.hasOwnProperty(this.error)) {
      if (this.control.errors.matDatepickerParse) {
        return this.translate.instant(this.errorMessages['date']);
      } else if (this.control.errors.matDatepickerMin) {
        this.error = 'matDatepickerMin';
        return this.translate.instant(this.errorMessages['matDatepickerMin']);
      } else if (this.control.errors.matDatepickerMax) {
        this.error = 'matDatepickerMax';
        return this.translate.instant(this.errorMessages['matDatepickerMax']);
      } else if (this.control.errors.objectId) {
        return this.translate.instant(this.errorMessages['objectId']);
      } else {
        return this.translate.instant(this.errorMessages[this.error]);
      }
    } else {
      throw new RangeError('No error message for ' + this.error);
    }
  }

  getMaskTemplate() {
    if (this.errorMessages.mask_errors.hasOwnProperty(this.mask)) {
      return this.translate.instant(this.errorMessages.mask_errors[this.mask]);
    } else {
      throw new RangeError('No error message for mask ' + this.mask);
    }
  }

  replaceVariables(errorMessageTemplate) {
    errorMessageTemplate = errorMessageTemplate
      .replace(':attribute', this.controlName)
      .replace('_', ' ');

    if (this.error === 'minlength') {
      errorMessageTemplate = errorMessageTemplate.replace(':minlength', this.errorValue.requiredLength);
    }
    if (this.error === 'maxlength') {
      errorMessageTemplate = errorMessageTemplate.replace(':maxlength', this.errorValue.requiredLength);
    }
    if (this.error === 'matDatepickerMax') {
      errorMessageTemplate = errorMessageTemplate.replace(':date', _moment(this.errorValue.max).format('MM/DD/YYYY'));
    }
    if (this.error === 'matDatepickerMin') {
      errorMessageTemplate = errorMessageTemplate.replace(':date', _moment(this.errorValue.min).format('MM/DD/YYYY'));
    }
    if (this.error === 'min') {
      errorMessageTemplate = errorMessageTemplate.replace(':min', this.errorValue.min);
    }
    if (this.error === 'max') {
      errorMessageTemplate = errorMessageTemplate.replace(':max', this.errorValue.max);
    }

    return errorMessageTemplate;
  }

  getControlName(): string | null {
    const formGroup = this.control.parent.controls;
    return Object.keys(formGroup).find(name => this.control === formGroup[name])
      .replace('_', ' ')
      .replace(/([A-Z])/g, ' $1')
      .toLowerCase() || null;
  }
}
