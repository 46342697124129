<h2 mat-dialog-title>{{ 'disclosure.final_bind_policy_disclosures' | translate }}</h2>
<mat-dialog-content>
  <p>{{'disclosure.i_hereby_acknowledge_that_by_clicking_the' | translate}} {{nameOfButton | translate}} {{'disclosure.button_below_i_am_applying_for_the' | translate}}
    {{(brandingInfo$ |async)?.title}} {{ 'disclosure.offered_through_millennial_specialty_insurance' | translate }}
    {{nameOfButton | translate}} {{ 'disclosure.button_below_constitutes' | translate }}
    {{paymentMethod | translate}} {{ 'disclosure.to_which_policy_premium_will_be_charged' | translate }} {{data.billingTitle}}.
    {{'disclosure.in_addition_by_clicking_below' | translate}} {{data.monthlyDownPayment | stripeCurrency}}
    {{'disclosure.with_recurring_monthly_charges_of' | translate}} {{data.installmentTotal | stripeCurrency}}
    {{'disclosure.from_the_same' | translate}} {{paymentMethod | translate}}
    {{'disclosure.for_the_premium_cost_i_have_agreed' | translate}}
    {{(brandingInfo$ |async)?.title}}
    {{'disclosure.to_contact_me_to_provide_notice' | translate}}
  </p>
  <h3>{{ 'disclosure.nsf_reinstatement_fees' | translate }}</h3>
  <p>{{ 'disclosure.the_following_additional_fees' | translate }}</p>
  <h3>{{ 'disclosure.options_endorsements' | translate }}</h3>
  <p>
    {{ 'disclosure.your_insurance_policy_may_include_optional_endorsements' | translate }}
    {{(brandingInfo$ |async)?.title}}
    {{ 'disclosure.to_request_changes_to_policy' | translate }}
  </p>
  <h3>{{ 'disclosure.verify_your_email' | translate }}</h3>
  <p>{{ 'disclosure.i_agree_to_receive_all_mailings_and_communications' | translate }} {{data.email}}
      {{ 'disclosure.provided_in_this_on_line_application' | translate }}
  </p>
  <h3>{{ 'disclosure.cancellation_policy' | translate }}</h3>
  <p>
    {{ 'disclosure.i_hereby_acknowledge_that_by_clicking' | translate }} {{nameOfButton | translate}} {{ 'disclosure.button_below_i_agree_to_the_cancelation' | translate }}
  </p>
  <h3>{{ 'disclosure.fraud_warning' | translate }}</h3>
  <app-total-premium-states [state]="data.state"></app-total-premium-states>
  <br>

  <p>{{ 'disclosure.in_most_states_there_is_an_animal' | translate }}</p>
  <p *ngIf="data.state ==='FL'">
    {{ 'disclosure.a_detailed_listing_of_all_policy_fl' | translate }}
  </p>
  <p *ngIf="data.state !=='FL'">
    {{ 'disclosure.a_detailed_listing_of_all_policy' | translate }}
    {{data.policyFee | stripeCurrency}}
    {{ 'disclosure.policy_fee_is_included_with_your' | translate }}
    {{data.fee | stripeCurrency}}
    {{ 'disclosure.per_installment_will_be_added' | translate }}
  </p>
  <p>
    {{ 'disclosure.if_you_establish_an_account_with_us' | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="end start"
                    fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign.xs="center center">
  <button mat-button
          class="mat-btn warning"
          (click)="dialogRef.close(true)">
    {{ 'total_premium.purchase' | translate }}
  </button>
  <button mat-button
          class="mat-btn outline"
          (click)="dialogRef.close(false)">
    {{ 'form.go_back' | translate }}
  </button>
</mat-dialog-actions>
