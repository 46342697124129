import { Component, Inject} from '@angular/core';
import { BrandingService } from '../../core/services/branding.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-disclosures-page',
  templateUrl: './disclosures-page.component.html',
  styleUrls: ['./disclosures-page.component.scss']
})
export class DisclosuresPageComponent {
  nameOfButton = 'total_premium.purchase';
  paymentMethod = 'form.credit_card';
  data;
  brandingInfo$;
  constructor(public dialogRef: MatDialogRef<DisclosuresPageComponent>,
              private brandingService: BrandingService,
              @Inject(MAT_DIALOG_DATA) public matData: any) {
    this.data  = matData.pageData;
    this.brandingInfo$ = brandingService.brandingInfo$;
  }
}
