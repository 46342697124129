import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { UserModel } from '../models/user.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EmailNotificationModel } from '../models/email-notification.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpService: HttpClient) { }

  getUser(id: number): Observable<UserModel> {
    return this.httpService.get<UserModel>(`/user/users/${id}`);
  }

  updateProfile(id: number, profile) {
    return this.httpService.put(`/user/users/${id}`, profile);
  }

  register(user): Observable<any> {
    return this.httpService.post('/user/auth', user);
  }

  resetPassword(email: string, redirectUrl: string): Observable<any> {
    const resetObj = {
      email,
      redirect_url: redirectUrl
    };
    return this.httpService.post('/user/auth/password', resetObj);
  }

  getAllPolicies() {
    return this.httpService.get('/user/policies');
  }

  getPolicies(sortField: string = 'updated_at', sortDirection: string = 'desc',
              pageIndex: number = 0, pageSize: number = 0): Observable<any> {
    let params = new HttpParams();

    params = params.append('pagination[per]', String(pageSize));
    params = params.append('pagination[page]', String(pageIndex));

    return this.httpService.get('/user/policies', {params, observe: 'response'})
      .pipe(
        map((response) => ({
          dataLength: response.headers.get('total-entries'),
          data: response.body
        }))
      );
  }

  getPoliciesById(id: number) {
    return this.httpService.get(`/user/policies/${id}`);
  }

  getClaimById(id: number) {
    return this.httpService.get(`/user/claims/${id}`);
  }

  getClaimsOld(per: number, page: number) {
    const params = new HttpParams()
      .set('pagination[per]', `${per}`)
      .set('pagination[page]', `${page}`);
    return this.httpService.get(`/user/claims?${params.toString()}`);
  }

  getClaims(sortField: string = 'updated_at', sortDirection: string = 'desc',
              pageIndex: number = 0, pageSize: number = 0): Observable<any> {
    let params = new HttpParams();

    params = params.append('pagination[per]', String(pageSize));
    params = params.append('pagination[page]', String(pageIndex));

    return this.httpService.get('/user/claims', {params, observe: 'response'})
      .pipe(
        map((response) => ({
          dataLength: response.headers.get('total-entries'),
          data: response.body
        }))
      );
  }

  getClaimsPolicies() {
    const params = new HttpParams()
      .set('filter[status][]', 'accepted_with_warning')
      .set('sort[columns][]', 'policy_number')
      .set('sort[direction][]', 'desc')
      .set('short', 'true');
    return this.httpService.get(`/user/policies?filter[status][]=accepted&${params.toString()}`);
  }

  getSessions() {
    return this.httpService.get(`/user/login-activities`);
  }

  closeSessions() {
    return this.httpService.get(`/user/login-activities/close_all_sessions`);
  }

  postClaim(claim: any) {
    return this.httpService.post('/user/claims', claim);
  }

  getInvoices(sortField: string = 'created_at', sortDirection: string = 'desc',
              pageIndex: number = 0, pageSize: number = 0): Observable<any> {
    let params = new HttpParams();

    params = params.append('pagination[per]', String(pageSize));
    params = params.append('pagination[page]', String(pageIndex));
    params = params.append('sort[column][]', sortField);
    params = params.append('sort[direction][]', sortDirection);

    return this.httpService.get('/user/invoices', {params, observe: 'response'})
      .pipe(
        map((response) => ({
          dataLength: response.headers.get('total-entries'),
          data: response.body
        }))
      );
  }

  getInvoicesById(id: number) {
    return this.httpService.get(`/user/invoices/${id}`);
  }

  getLeases(per: number, page: number) {
    const params = new HttpParams()
      .set('pagination[per]', `${per}`)
      .set('pagination[page]', `${page}`);
    return this.httpService.get(`/user/leases?${params.toString()}`)
  }

  getPolicyTypes() {
    return this.httpService.get('/policy-types');
  }

  addCoverageProof(body) {
    return this.httpService.post('/user/policies/add_coverage_proof', body);
  }

  resendDocuments(userId) {
    return this.httpService.get(`/user/policies/${userId}/resend_policy_documents`);
  }

  refundPolicy(policyId) {
    return this.httpService.get(`/user/policies/${policyId}/refund_policy`);
  }

  cancelPolicy(policyId) {
     return this.httpService.get(`/user/policies/${policyId}/cancel_policy`);
  }

  getNotificationSettings(): Observable<EmailNotificationModel[]> {
    return this.httpService.get<EmailNotificationModel[]>(`/user/notification_settings`);
  }

  updateNotificationSettings(notificationSetting: string) {
    const body = { notification_action: notificationSetting };
    return this.httpService.post(`/user/notification_settings/switch`, body);
  }

  cancelRenewal(id, renewalToken) {
    const payload = { id, auto_renew: false, renewal_token: renewalToken };
    return this.httpService.post(`/user/policies/cancel_auto_renewal`, payload);
  }
}
