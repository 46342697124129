<div *ngIf="false" class="tenant-boarding__header tenant-boarding__header_small">Account Setup Complete</div>
<div class="tenant-boarding__header tenant-boarding__header_small">Your Documents Are Being Reviewed</div>
<div class="tenant-boarding__insurables tenant-boarding__header_small" *ngIf="false">
  <span class="tenant-boarding__highlight">{{ communityName }}</span> |
  <span *ngIf="buildingName"><span class="tenant-boarding__highlight">{{ buildingName }}</span> | </span>
  <span class="tenant-boarding__highlight">{{ unitName }}</span>
</div>
<div class="tenant-boarding__wrap">
  <div class="tenant-boarding__cloud-container" style="text-align: center;">
    <p class="tenant-boarding__complete-text">You may close this page.</p>
  </div>
</div>
