<div class="header-content">
  <header-menu></header-menu>
  <app-logo-section></app-logo-section>

  <div class="login-register">
<!--    <app-switch-languages class="top-lang-switcher"></app-switch-languages>-->
    <app-login-logout *ngIf="!IframeMode"></app-login-logout>
  </div>
</div>

