import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private toastr: ToastrService, private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !error.url.includes('user/auth/password') && !error.url.includes('user/auth/sign_in')) {
          this.authService.logOut();
        }
        return throwError(Object.assign({}, error.error, { originError: error }));
      })
    );
  }

  getErrorMessage(error) {
    let message;

    switch (true) {
      case error.status === 404:
        message = 'Not found';
        break;

      case error.status === 401:
        message = 'Your session is expired';
        break;

      case error.status === 0:
        message = 'Something is wrong with network';
        break;

      case error.status.toString().startsWith('4'):
        message = 'Client Error';
        break;

      default:
        message = 'Server error';
    }

    return message;
  }
}
