import { Component, Input, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { PmaService } from '../../../pma/pma.service';
import { map, startWith } from 'rxjs/operators';
import { Insurable } from '../../../core/models/insurable.model';

@Component({
  selector: 'ui-building',
  templateUrl: './ui-building.component.html',
  styleUrls: ['./ui-building.component.scss']
})
export class UiBuildingComponent {
  @Input() buildingControl: FormControl;
  @Input() buildings;
  @Input() unitControl;
  @Input() loader = false;
  filteredInsurables$: Observable<Insurable[]>;
  insurables;
  communitiesTypeIds;

  constructor() {
    this.communitiesTypeIds = PmaService.getTypeIdsByName('buildings');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.buildings && changes.buildings.currentValue) {
      this.setFilteredInsurables();
    }
  }

  setFilteredInsurables() {
    this.filteredInsurables$ = this.buildingControl.valueChanges
      .pipe(
        startWith(''),
        map(value => value || ''),
        map(value => typeof value === 'string' ? value : value.title),
        map(title => title ? this.filterInsurables(this.buildings, title) : this.buildings.slice(0))
      );
  }

  private filterInsurables(insurables, searchTerm: string): Insurable[] {
    const filterValue = searchTerm.toLowerCase();

    return insurables.filter(insurable => insurable.title.toLowerCase().includes(filterValue));
  }

  get insurableField() {
    return this.buildingControl;
  }

  displayFnInsurable(insurable?: Insurable): string | undefined {
    return insurable ? insurable.title : undefined;
  }

  reset() {
    this.insurableField.reset(null);
    this.unitControl.reset(null);
  }
}
