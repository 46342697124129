<div class="success-policy">
  <h3>Policy Accepted</h3>
  <p>Policy <b>#{{ policyDetails.policyNumber }}</b> has been accepted.</p>
  <div class="success-policy__info">
    <div>Address: <b>{{ policyDetails.address.full }}</b></div>
    <div>Unit: <b>{{ policyDetails.unit }}</b></div>
    <div>Insured persons:
      <b *ngFor="let name of policyDetails.insuredNames; let last = last">{{ name }}<span *ngIf="!last">, </span></b>
    </div>
    <div>Effective dates: <b>{{ policyDetails.effectiveDates }}</b></div>
    <div>Payment plan: <b>{{ policyDetails.paymentPlan }}</b></div>
  </div>
  <p>Please check your email for more information.</p>
</div>
