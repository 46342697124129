import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'pma-account-setup',
  templateUrl: './pma-account-setup.component.html',
  styleUrls: ['./pma-account-setup.component.css']
})
export class PmaAccountSetupComponent implements OnInit {
  @Input() communityName = 'Community Name';
  @Input() buildingName;
  @Input() unitName = 'Unit Number';

  constructor() { }

  ngOnInit(): void {
  }

}
