import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { PCIInsuranceFraudProvisionsComponent } from './shared/pci-insurance-fraud-provisions/pci-insurance-fraud-provisions.component';
import { GivebackPageComponent } from './shared/giveback-page/giveback-page.component';
import { PrivacyPolicyPageComponent } from './shared/privacy-policy-page/privacy-policy-page.component';
import { TermsOfUsePageComponent } from './shared/terms-of-use-page/terms-of-use-page.component';
import { AboutUsPageComponent } from './shared/about-us-page/about-us-page.component';
import { ContactUsPageComponent } from './shared/contact-us-page/contact-us-page.component';
import { NewFaqPageComponent } from './shared/new-faq-page/new-faq-page.component';
import { RedirectComponent } from './redirect.component';
import { NotSupportedComponent } from './shared/not-supported/not-supported.component';
import { StaticPageComponent } from './static-page/static-page.component';
import { InsuranceProducerLicensureComponent } from './shared/insurance-producer-licensure/insurance-producer-licensure.component';
import { PmaUploadCoverageProofComponent } from './pma/pma-upload-coverage-proof/pma-upload-coverage-proof.component';
import { UploadGuard } from './core/guards/upload.guard';

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64]
};

const routes: Routes = [
  {
    path: '',
    component: RedirectComponent
  },
  {
    path: 'residential',
    loadChildren: () => import('./residential/residential.module').then(m => m.ResidentialModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'pma-tenant-onboarding',
    loadChildren: () => import('./pma/pma.module').then(m => m.PmaModule),
    canActivate: [UploadGuard]
  },
  {
    path: 'pma-tenant-onboarding/:token',
    loadChildren: () => import('./pma/pma.module').then(m => m.PmaModule),
    canActivate: [UploadGuard]
  },
  {
    path: 'upload-coverage-proof',
    component: PmaUploadCoverageProofComponent,
    canActivate: [UploadGuard]
  },
  {
    path: 'upload-coverage-proof/:token',
    component: PmaUploadCoverageProofComponent,
    canActivate: [UploadGuard]
  },
  {
    path: 'faq',
    component: NewFaqPageComponent
  },
  {
    path: 'PCI-Insurance-Fraud-Provisions',
    component: PCIInsuranceFraudProvisionsComponent
  },
  {
    path: 'licenses',
    component: InsuranceProducerLicensureComponent
  },
  {
    path: 'state-fraud-warnings',
    component: PCIInsuranceFraudProvisionsComponent
  },
  {
    path: 'giveback',
    component: GivebackPageComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyPageComponent
  },
  {
    path: 'Terms-of-Use',
    component: TermsOfUsePageComponent
  },
  {
    path: 'About-Us',
    component: AboutUsPageComponent,
    data: { pageTitle: 'about_us' }
  },
  {
    path: 'Contact-Us',
    component: ContactUsPageComponent,
    data: { pageTitle: 'contact_us' }
  },
  {
    path: 'process_payment',
    loadChildren: () => import('./process-payment/process-payment.module').then(m => m.ProcessPaymentModule)
  },
  {
    path: 'deposit-choice',
    loadChildren: () => import('./deposit-choice/deposit-choice.module').then(m => m.DepositChoiceModule)
  },
  {
    path: 'page/:title',
    component: StaticPageComponent
  },
  {
    path: 'not-supported',
    component: NotSupportedComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
