import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BrandingService} from '../../core/services/branding.service';

@Component({
  selector: 'app-disclosures-qbe',
  templateUrl: './disclosures-qbe.component.html',
  styleUrls: ['./disclosures-qbe.component.scss']
})
export class DisclosuresQbeComponent implements OnInit {
  nameOfButton = 'total_premium.purchase';
  paymentMethod = 'form.credit_card';
  data;
  brandingInfo$;

  constructor(public dialogRef: MatDialogRef<DisclosuresQbeComponent>,
              private brandingService: BrandingService,
              @Inject(MAT_DIALOG_DATA) public matData: any) {
    this.data  = matData.pageData;
    this.brandingInfo$ = brandingService.brandingInfo$;
  }

  ngOnInit(): void {
  }

}
