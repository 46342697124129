import { Injectable, OnDestroy } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  DialogMatchUserComponent
} from '../pma/pma-upload-coverage-proof/upload-coverage/dialog-match-user/dialog-match-user.component';
import { merge, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PmaService } from '../pma/pma.service';

@Injectable({
  providedIn: 'root'
})
export class UserMatchService implements OnDestroy {
  subscription = new Subscription();

  constructor(private dialog: MatDialog,
              private pmaService: PmaService) {}

  onChangesUserInfo(form) {
    this.subscription.add(
      merge(
        form.get('first_name').valueChanges,
        form.get('last_name').valueChanges,
        form.get('email').valueChanges
      ).pipe(
        distinctUntilChanged(),
        debounceTime(800),
      ).subscribe(() => {
        this.checkUserInfo(form);
      })
    )
  }

  checkUserInfo(form) {
    const firstName = form.get('first_name').value;
    const lastName = form.get('last_name').value;
    const email = form.get('email').value;
    if (firstName && form.get('first_name').valid &&
      lastName && form.get('last_name').valid &&
      email && form.get('email').valid) {
      this.pmaService.getMatchUsers(firstName, lastName, email).subscribe((res: any) => {
        const users = this.setUsers(res);
        if (users && users.length > 0) {
          this.openDialogMatchUser(users, form);
        }
      })
    }
  }

  setUsers(res) {
    let users = [];
    if (res && res.possible_matches) {
      users = [ ...res.possible_matches ];
    }
    users = res && res.exact_match && res.exact_match.id && users.length === 1 ? [] : users;
    // this.setAsterisks(users);
    return users;
  }

  setAsterisks(users) {
    users.forEach(user => user.email = this.getAsteriskEmail(user.email));
  }

  getAsteriskEmail(email) {
    let outputEmail;
    if (email) {
      const atIndex = email.indexOf("@");
      const replacement = "*".repeat(atIndex - 2);
      const lastCharacters = email.substring(atIndex - 1, email.lenght);
      outputEmail = email[0] + replacement + lastCharacters;
    }
    return outputEmail ? outputEmail : email;
  }

  openDialogMatchUser(users, form) {
    const dialog = this.dialog.open(DialogMatchUserComponent, { autoFocus: false, maxWidth: 450, data: users });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.prefillUser(res, form);
      }
    });
  }

  prefillUser(user, form) {
    form.get('first_name').patchValue(user.first_name, { emitEvent: false });
    form.get('last_name').patchValue(user.last_name, { emitEvent: false });
    form.get('email').patchValue(user.email, { emitEvent: false });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
