import {Component, OnInit} from '@angular/core';
import {BrandingService} from '../core/services/branding.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PagesService} from '../core/services/pages.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.css']
})
export class StaticPageComponent implements OnInit {
  page;
  content;

  constructor(private brandingService: BrandingService,
              private pagesService: PagesService,
              private route: ActivatedRoute,
              private router: Router,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    const pages = this.brandingService.getBrandingInfoSnapshot().pages;
    let pageURL = this.route.snapshot.params.title;

    const page = pages.find(item => item.title === pageURL);

    if (!page) {
      this.router.navigateByUrl('/404');
      return;
    }

    this.pagesService.getPage(page.id).subscribe(pageResponse => {
      this.page = pageResponse;
      this.content = this.sanitizer.bypassSecurityTrustHtml(this.page.content);
    });
  }

}
