import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';

interface AppState {
  showMenu: boolean;
  isGaLoaded: boolean;
}

const APP_INITIAL_STATE = {
  showMenu: null,
  isGaLoaded: false,
};

export interface AppConfig {
  maintenance_mode: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private store = new BehaviorSubject<AppState>(APP_INITIAL_STATE);
  public showMenu$ = this.store.asObservable().pipe(
    map(v => v.showMenu)
  );
  public isGaLoaded$ = this.store.asObservable().pipe(
    map(v => v.isGaLoaded)
  );

  constructor(private httpClient: HttpClient) {
  }

  setShowMenu(showMenu: boolean) {
    this.store.next({
      ...this.store.getValue(),
      showMenu
    });
  }

  setIsGaLoaded(isGaLoaded: boolean) {
    this.store.next({
      ...this.store.getValue(),
      isGaLoaded
    });
  }

  loadAppConfig(): Observable<AppConfig> {
    if (environment?.configUrl === '') {
      return throwError('No app configuration set');
    }

    return this.httpClient.get<AppConfig>(environment.configUrl);
  }

}
