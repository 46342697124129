<div>
  <mat-form-field fxFlex="" appearance="outline">
    <mat-label>Building Address</mat-label>
    <input matInput
           ngx-google-places-autocomplete
           [formControl]="buildingAddressControl"
           [options]="{ componentRestrictions: { country: 'USA' }}"
           #placesRef="ngx-places"
           (onAddressChange)="handleAddressChange($event)"
           placeholder="Start typing your address..."/>

    <div matPrefix style="margin: 8px">
      <mat-icon>home</mat-icon>
    </div>
  </mat-form-field>
</div>
