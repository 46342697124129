import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services';
import { MenusService } from '../../../menus';
import { debounce } from 'lodash';

export const CLAIMS_URL = 'https://filemyclaim.io';

const RESIZE_EVENT = 'resize';

@Component({
  selector: 'header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMenuComponent implements OnDestroy, OnInit {

  public readonly showMenu$ = new Subject();

  curentUrl: string | null = null;
  private menuSub: Subscription;

  public readonly mainMenuItems$ = this.menuService.mainMenuItems$;

  public readonly additionalMenuItems$ = this.menuService.additionalMenuItems$;

  public readonly accountMenuInMainMenu$ = this.menuService.accountMenuInMain$;

  private readonly resizeEventListener = debounce(() => this.closeMenu(), 150);

  constructor(
    private authService: AuthService,
    private router: Router,
    private menuService: MenusService
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (event) { this.curentUrl = event.urlAfterRedirects; }
    });
  }

  ngOnInit(): void {
    window.addEventListener(RESIZE_EVENT, this.resizeEventListener);
  }

  ngOnDestroy(): void {
    this.menuSub.unsubscribe();
    window.removeEventListener(RESIZE_EVENT, this.resizeEventListener);
  }

  logout(event: MouseEvent): void {
    event.preventDefault();
    this.authService.signOut().subscribe();
    this.closeMenu();
  }

  closeMenu(): void {
    this.showMenu$.next(false);
  }

  showMenu(): void {
    this.showMenu$.next(true);
  }

  handleCustomMenuClick(event: MouseEvent, url: string): void {
    event.preventDefault();
    if (url.startsWith('http') || url.startsWith('https')) {
      window.open(url, '_blank').focus();
    } else {
      this.router.navigateByUrl(url);
    }
    this.closeMenu();
  }

  onDropMenuClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.drop-menu-content')) {
      this.closeMenu();
    }
  }
}
