import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import {JwtService} from './jwt.service';

@Injectable()
export class InitService {

  constructor(private authService: AuthService,
              private jwtService: JwtService) {}

  load() {
    return new Promise<void>((resolve, reject) => {
      const token = this.jwtService.getToken();
      if (token && token.uid && token.uid !== 'null') {
        this.authService.validateToken(token).pipe(
          map(user => {
            this.authService.setUser(user);
          }),
          catchError(error => {
            this.authService.userSetToLogin();
            return of(error);
          }),
          tap(r => resolve())
        ).subscribe();
      } else {
        this.authService.userSetToLogin();
        resolve();
      }
    });
  }
}
