<div *ngIf="!submitted">
  <div class="tenant-boarding__header tenant-boarding__header_small">Your Documents Are Being Reviewed</div>
  <div class="tenant-boarding__insurables tenant-boarding__header_small" *ngIf="false">
    <span class="tenant-boarding__highlight">{{ communityName }}</span> |
    <span *ngIf="buildingName"><span class="tenant-boarding__highlight">{{ buildingName }}</span> | </span>
    <span class="tenant-boarding__highlight">{{ unitName }}</span>
  </div>
  <div class="tenant-boarding__wrap">
    <div class="tenant-boarding__cloud-container">
      <p class="tenant-boarding__master_policy_text">
        <b>Thank you, <span class="tenant-boarding__highlight">{{ firstName }}</span>!</b>
      </p>

      <p class="tenant-boarding__master_policy_text">
        You will be notified of your submission status via email.
      </p>

      <div class="tenant-boarding__passwords">
        <div class="tenant-boarding__passwords__label">Optional</div>
        <p>To finish setting up your account, please enter a password.</p>
        <div class="tenant-boarding_mt-15">
          <mat-form-field fxFlex="100%" appearance="outline">
            <mat-label>{{ 'form.password' | translate }}</mat-label>
            <input matInput type="password" [formControl]="passwordControl" required>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field fxFlex="100%" appearance="outline">
            <mat-label>Verify Password</mat-label>
            <input matInput type="password" [formControl]="verifyPasswordControl" required>
          </mat-form-field>
        </div>

        <div *ngIf="passwordControl.touched && verifyPasswordControl.touched">
          <mat-error *ngIf="passwordControl.invalid || verifyPasswordControl.invalid"
                     fxLayout="row"
                     fxLayoutGap="2px"
                     fxLayoutAlign="start center">
            <mat-icon class="error-icon">info</mat-icon>
            <span>Please complete the password fields</span>
          </mat-error>
          <mat-error *ngIf="!isPasswordsMatch"
                     fxLayout="row"
                     fxLayoutGap="2px"
                     fxLayoutAlign="start center">
            <mat-icon class="error-icon">info</mat-icon>
            <span>Passwords do not match</span>
          </mat-error>
        </div>

        <div style="margin-top: 15px;">
          <button mat-button
                  type="submit"
                  (click)="setPassword()"
                  class="mat-btn warning full-width"
                  [disabled]="loader || !isPasswordsMatch"
                  [class.spinner]="loader">
            {{ 'form.submit' | translate }}**
          </button>
        </div>
      </div>
    </div>

    <div>
      **By clicking 'Submit' you agree to our <a routerLink="/Terms-of-Use" style="text-decoration: underline">{{ 'menu.terms_of_use' | translate }}</a>.
    </div>
  </div>
</div>

<pma-account-setup
  *ngIf="submitted"
  [communityName]="communityName"
  [buildingName]="buildingName"
  [unitName]="unitName"
>
</pma-account-setup>
