import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeunderscore' })
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (!value)  {
      return null;
    }

    return value.replace(/_/g, ' ');
  }
}
