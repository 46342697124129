import { Component, Input } from '@angular/core';
import { BrandingService } from '../../core/services/branding.service';

@Component({
  selector: 'app-form-descriptor',
  templateUrl: './form-descriptor.component.html',
  styleUrls: ['./form-descriptor.component.css']
})
export class FormDescriptorComponent {
  @Input() message;
  customRgPage$;

  constructor(private brandingService: BrandingService) {
    this.customRgPage$ = this.brandingService.customRgPage$;
  }

  prevent(event, value) {
    event.stopPropagation();
    value.toggle();
  }
}
