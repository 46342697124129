import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { map } from 'rxjs/operators';


@Injectable()
export class PoliciesResolver implements Resolve<Observable<any>> {

  constructor(private userService: UserService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.userService.getPolicies('updated_at', 'desc', 0, 50)
      .pipe(
        map(result => result)
      );
  }
}
