import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResidentialService } from '../services/residential.service';

@Injectable()
export class CommercialResolver implements Resolve<Observable<any>> {

  constructor(private resService: ResidentialService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.resService.getFormData('commercial')
      .pipe(map(result => result));
  }
}
