import { Injectable } from '@angular/core';
import { TokenInterface } from '../models/token.interface';

@Injectable()
export class JwtService {

  getToken(): TokenInterface | null {
    if (localStorage.getItem('accessToken')) {
      return {
        access_token: localStorage.getItem('accessToken'),
        expiry: localStorage.getItem('expiry'),
        uid: localStorage.getItem('uid'),
        token_type: localStorage.getItem('tokenType'),
        client: localStorage.getItem('client'),
      };
    } else {
      return null;
    }
  }

  saveToken(token: TokenInterface) {
    localStorage.setItem('accessToken', token.access_token);
    localStorage.setItem('expiry', token.expiry);
    localStorage.setItem('uid', token.uid);
    localStorage.setItem('tokenType', token.token_type);
    localStorage.setItem('client', token.client);
  }

  destroyToken() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('expiry');
    localStorage.removeItem('uid');
    localStorage.removeItem('tokenType');
    localStorage.removeItem('client');
  }
}
