import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserService } from '../../core/services';
import { ToastrService } from 'ngx-toastr';
import { customEmailValidator } from '../../core/utils/custom-email-validator.utils';
import { BrandingService } from '../../core/services/branding.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  forgetForm: FormGroup;
  submitted = false;
  subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    private brandingService: BrandingService,
    private translate: TranslateService
  ) {
    this.forgetForm = this.fb.group({
      email: ['', [Validators.required, customEmailValidator()]],
    });
  }

  ngOnInit() {
    this.brandingService.setBrandingTitle('Forgot Password');
    this.subscription = this.forgetForm.valueChanges.subscribe(() => this.submitted = false);
  }

  submitForm(formDirective?: FormGroupDirective) {
    this.submitted = true;
    if (this.forgetForm.invalid) {
      if (this.f.email.errors.email) {
        this.toastr.error(this.translate.instant('form.please_enter_a_valid_email'));
      }
      return;
    }

    this.userService.resetPassword(this.forgetForm.value.email, '/login')
      .subscribe(result => {
        if (result) {
          formDirective.resetForm();
          this.forgetForm.reset();
          this.toastr.success(this.translate.instant('form.we_have_sent_you_an_email'));
        }
      }, error => { const message = error.errors.pop(); this.toastr.error(message); });
  }

  get f() { return this.forgetForm.controls; }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
