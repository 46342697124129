import { API } from './httpService';
import { environment } from '@environment';

export const fetchBrandingProfile = () => {
  return API.get(`${environment.apiEndpoint}/branding-profile`);
};

class BrandingProfileSingleton {

  private static instance: BrandingProfileSingleton = new BrandingProfileSingleton();
  private profile: any = null;

  constructor() {
    if (BrandingProfileSingleton.instance){
      throw new Error('Error: Instantiation failed: Use SingletonClass.getInstance() instead of new.');
    }
    BrandingProfileSingleton.instance = this;
  }

  public static getInstance(): BrandingProfileSingleton {
    return BrandingProfileSingleton.instance;
  }

  public setProfile(payload: any): void {
    this.profile = payload;
  }

  public getProfile(): any {
    return this.profile;
  }
}

export const brandingProfileStore = BrandingProfileSingleton.getInstance();
