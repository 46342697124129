import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'intCurrency' })
export class IntCurrency implements PipeTransform {
  transform(value: string): string {
    // remove numbers after dot for INT CURRENCY PIPE
    const tmpVal = ((+value) / 100.0).toFixed(0);
    let tmpStringVal = tmpVal.toString();
    const regexPattern = /(-?\d+)(\d{3})/;

    while (regexPattern.test(tmpStringVal)) {
      tmpStringVal = tmpStringVal.replace(regexPattern, '$1,$2');
    }

    return('$' + tmpStringVal);
  }
}
