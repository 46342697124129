import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-success-policy',
  templateUrl: './success-policy.component.html',
  styleUrls: ['./success-policy.component.scss']
})
export class SuccessPolicyComponent {

  @Input() policyDetails;

}
