import { Component, OnInit } from '@angular/core';
import { BrandingService } from '../../core/services/branding.service';

@Component({
  selector: 'app-giveback-page',
  templateUrl: './giveback-page.component.html',
  styleUrls: ['./giveback-page.component.css']
})
export class GivebackPageComponent implements OnInit {
  faq$ = this.brandingService.faq$;
  giveBackPartner$ = this.brandingService.giveBackPartner$;
  constructor(private brandingService: BrandingService) { }

  ngOnInit(): void {
    this.brandingService.setBrandingTitle('Giveback Partner');
  }
}
