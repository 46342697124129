import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../core/services';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from '../_helpers/must-match.validator';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
export  enum ChangePasswordFormType {
  ChangePassword = 'change_password',
  AcceptInvitation = 'accept_invitation'
}
@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.css']
})
export class ChangePasswordFormComponent implements OnInit, OnDestroy {
  @Input() type: ChangePasswordFormType;
  @Input() inputToken = null;
  @Input() btnLabel = 'form.reset_password';
  @Input() withLogin = true;
  submitted = false;
  message: any;
  formStep = 1;
  tokenChecking = true;
  tokenValid  = false;
  token: string;
  changePasswordForm: FormGroup;
  valueChangeSubscription: Subscription;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private translate: TranslateService,
              private route: ActivatedRoute,
              private toastr: ToastrService) {
    this.changePasswordForm = this.fb.group({
        password: ['', [Validators.required, Validators.minLength(6)]],
        password_confirmation: ['', Validators.required],
      },
      {
        validator: MustMatch('password', 'password_confirmation')
      }
    );
  }

  ngOnInit(): void {
    this.token = this.getToken;
    this.checkTokenValidity();
    this.valueChangeSubscription = this.changePasswordForm.valueChanges.subscribe(
      () => this.submitted = false
    );
  }

  submitForm(form) {
    this.submitted = true;
    if (form.valid) {
      switch (this.type) {
        case ChangePasswordFormType.ChangePassword: this.changePassword(form); break;
        case ChangePasswordFormType.AcceptInvitation: this.acceptInvitation(form); break;
      }
    }
  }

  private checkTokenValidity() {
    if (this.type === ChangePasswordFormType.ChangePassword) {
      this.authService.resetPassword({ reset_password_token: this.token } as any)
        .subscribe(
          (res) => {
            this.tokenValid = true;
            this.tokenChecking = false;
          },
          (err) =>  {
            this.tokenValid = !err.errors || !err.errors.includes('Unauthorized');
            this.tokenChecking = false;
          }
        );
    } else if (this.type === ChangePasswordFormType.AcceptInvitation) {
      this.authService.invitationResetPassword({ invitation_token: this.token } as any)
        .subscribe(
          (res) => {
            this.tokenValid = true;
            this.tokenChecking = false;
          },
          (err) =>  {
            this.tokenValid = !err.errors || !err.errors.includes('Invalid token.');
            this.tokenChecking = false;
          }
        );
    }

  }

  private acceptInvitation(form) {
    this.authService.invitationResetPassword({
      password: form.value.password,
      password_confirmation: form.value.password,
      invitation_token: this.token
    }).subscribe(
      (result: any) => {
        this.toastr.success(result.success);
        this.formStep = 2;
        this.submitted = false;
      },
      error => {
        this.message = error;
        this.toastr.error(error.errors);
        this.submitted = false;
      }
    );
  }

  private changePassword(form) {
    this.authService.resetPassword({
      password: form.value.password,
      password_confirmation: form.value.password,
      reset_password_token: this.token,
    }).subscribe(
      result => {
        this.toastr.success('Successfully changed password');
        this.formStep = 2;
        this.submitted = false;
      },
      error => {
        this.toastr.error(error.errors);
        this.submitted = false;
      }
    );
  }

  get f() { return this.changePasswordForm.controls; }

  get getToken() {
    return this.inputToken || this.route.snapshot.paramMap.get('token');
  }

  ngOnDestroy(): void {
    if (this.valueChangeSubscription) { this.valueChangeSubscription.unsubscribe() }
  }
}
