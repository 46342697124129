import { isApiResponse } from '../typeguards';
import { isArray } from 'lodash';

/**
 * Parses response data
 * @param response
 */
export const parseResponseData = <T>(response: any): T => {
  return isApiResponse(response) && response.data ? response.data : undefined;
};

/**
 * Parses array in response data
 * @param response
 */
export const parseResponseDataArray = <T>(response: any): Array<T> => {
  return isApiResponse(response) && isArray(response.data) ? response.data : [];
};

/**
 * Parses array in response data and filters out object not matching a type
 * @param response
 * @param guard
 */
export const parseResponseDataArrayOfType = <T>(response: any, guard: (v: any) => v is T): Array<T> => {
  return isApiResponse(response) && isArray(response.data) ? response.data.filter(guard) : [];
};
