import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MustMatch } from '../_helpers/must-match.validator';
import { UserService } from '../../core/services/user.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FormsService } from '../../shared/forms.service';
import { BrandingService } from '../../core/services/branding.service';
import * as moment from 'moment';
import { customEmailValidator } from '../../core/utils/custom-email-validator.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm: FormGroup;
  submitted = false;
  subscription: Subscription;
  maxBirthdayDate: any;
  isLoginDisabled$;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    private formsService: FormsService,
    private brandingService: BrandingService,
    private translate: TranslateService
  ) {
      this.isLoginDisabled$ = brandingService.isLoginDisabled$;
      this.registerForm = this.fb.group({
        invitation_token: [null, []],
        confirm_success_url: ['confirmed', []],
        email: ['', [Validators.required, customEmailValidator()]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        password_confirmation: ['', Validators.required],
        profile_attributes: this.fb.group({
          first_name: ['', Validators.required],
          last_name: ['', Validators.required],
          middle_name: [''],
          birth_date: ['', Validators.required],
          contact_phone: ['', Validators.required],
        }),
        terms_conditions: [false, Validators.requiredTrue]
      }, { validator: MustMatch('password', 'password_confirmation') });
  }

  ngOnInit() {
    this.brandingService.setBrandingTitle('Register');
    this.maxBirthdayDate = moment(moment.now()).subtract(18, 'year').toDate();
  }

  submitForm() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      this.toastr.error(this.translate.instant('form.please_fill_the_form'));
      return;
    }
    this.subscription = this.userService.register(this.registerForm.value)
      .subscribe(success => {
        if (success) {
          this.toastr.success('Use email and password to login.', 'Registration Success!');
          this.router.navigate(['/login']);
        }
      },
      error => this.formsService.handleFormErrors(error)
    );
  }

  get f() { return this.registerForm.controls; }

  get profileAttributes() { return this.registerForm.get('profile_attributes')['controls']; }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
