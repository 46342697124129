import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'pma-dialog-additional-interest',
  templateUrl: './pma-dialog-additional-interest.component.html',
  styleUrls: ['./pma-dialog-additional-interest.component.css']
})
export class PmaDialogAdditionalInterestComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PmaDialogAdditionalInterestComponent>) { }

  ngOnInit(): void {
  }

}
