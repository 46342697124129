import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { BrandingService } from '../services/branding.service';
import { PagesService } from '../services/pages.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class PageResolver implements Resolve<Observable<any>> {

  constructor(public brandingService: BrandingService,
              private pagesService: PagesService,
              private translate: TranslateService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const pageTitle = route.data['pageTitle'];
    return this.brandingService.getPageByTitle(pageTitle).pipe(
      filter(v => !!v),
      map(v => v.id),
      switchMap((id) => this.pagesService.getPage(id)),
      map((v: any) => v.content),
      take(1)
    );
  }
}
