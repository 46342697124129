import { Component, OnInit } from '@angular/core';
import { ChangePasswordFormType } from '../change-password-form/change-password-form.component';
import { BrandingService } from '../../core/services/branding.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
  type = ChangePasswordFormType.ChangePassword;
  constructor(private brandingService: BrandingService) { }

  ngOnInit(): void {
    this.brandingService.setBrandingTitle('Change Password');
  }
}
