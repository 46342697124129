import {AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime, delay, distinctUntilChanged, map} from 'rxjs/operators';
import {FaqQuestionComponent} from './faq-question/faq-question.component';
import {FaqInterface, FaqService} from '../../core/services/faq.service';
import {BrandingService} from '../../core/services/branding.service';
import {MatAccordion} from '@angular/material/expansion';

@Component({
  selector: 'app-new-faq-page',
  templateUrl: './new-faq-page.component.html',
  styleUrls: ['./new-faq-page.component.css'],
})
export class NewFaqPageComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('accordion', {static: true}) accordion: MatAccordion;
  @ViewChildren(FaqQuestionComponent) faqQuestions: QueryList<FaqQuestionComponent>;
  faqData = [];
  searchedFaqData;
  search = new FormControl();
  subscription: Subscription = new Subscription();
  brandingId;

  constructor(private faqService: FaqService,
              private brandingService: BrandingService) {
  }

  ngOnInit() {
    this.subscription.add(this.brandingService.brandingInfo$.subscribe((val) => {
      if (val && val.id) {
        this.brandingId = val.id;
        this.fetchFaqs();
      }
    }));
  }

  fetchFaqs() {
    this.faqService.getFaqs(this.brandingId).subscribe((res: FaqInterface[]) => {
      if (res && res.length > 0) {
        this.faqData = res;
        this.filterData(``);
      }
    });
  }

  ngAfterViewInit() {
    this.subscription.add(this.search.valueChanges.pipe(
      debounceTime(600),
      distinctUntilChanged(),
      map(search => {
        this.filterData(search);
        return search;
      }),
      delay(100),
      map(search => {
        if (search && search.length > 0) {
          this.accordion.openAll();
        }
        return search;
      }),
      delay(300),
    ).subscribe(search => {
      if (search && search.length > 0) {
        this.faqQuestions.forEach(res => {
          res.accordion.openAll();
        });
      }
    }));
  }

  filterData(search) {
    this.searchedFaqData = [];
    this.faqData.forEach(item => {
      const questions = item.questions.filter(question => {
        return question.question.toLowerCase().includes(
          search.toLowerCase()) || question.answer.toLowerCase().includes(search.toLowerCase()
        );
      });
      if (questions && questions.length > 0) {
        const markedQuestions = search && search.length > 0 && !search.includes('?') && !search.includes('.') ?
          this.highlight(questions, search) : questions;
        this.searchedFaqData.push({
          title: item.title,
          questions: markedQuestions
        });
      }
    });
  }

  highlight(data, search) {
    const highlightedData = [];
    data.forEach(item => {
      // const reg = new RegExp(search + '(?!([^<]+)?<)', 'gi');
      const reg = new RegExp(search + '(?![^<>]*>)', 'gi');
      const question = item.question.replace(reg, (str) => `<mark>${str}</mark>`);
      const answer = item.answer.replace(reg, (str) => `<mark>${str}</mark>`);
      highlightedData.push({
        question,
        answer
      });
    });
    return highlightedData;
  }

  resetSearch() {
    this.search.setValue('');
  }

  closeAll() {
    this.accordion.closeAll();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
