import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss']
})
export class MaintenancePageComponent implements OnInit, OnDestroy {
  readonly pageClass = 'maintenance-page';

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.addClass(document.body, this.pageClass);
    this.renderer.addClass(document.querySelector('app-root'), this.pageClass);
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, this.pageClass);
    this.renderer.removeClass(document.querySelector('app-root'), this.pageClass);
  }

}
