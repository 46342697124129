import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface PolicyAssignCheckResult {
  belongs_to_provided_user: boolean;
  exists: boolean
}

export interface PolicyAssignCheckPayload {
  user_id: number;
  email: string;
  policy_number: string;
}

@Injectable({
  providedIn: 'root'
})
export class PolicyAssignService {
  constructor(private httpClient: HttpClient) {}

  assignCheck(body: PolicyAssignCheckPayload): Observable<PolicyAssignCheckResult> {
    return this.httpClient.post(`/policies/belongs`, body) as Observable<PolicyAssignCheckResult>;
  }
}
