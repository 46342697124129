<div class="questions-container">
  <!--<div class="questions-container__close"-->
       <!--(click)="dialogRef.close(false)">-->
    <!--<mat-icon>close</mat-icon>-->
  <!--</div>-->
  <h2 mat-dialog-title>{{ 'form.increased_liability_coverage_qualification_questions' | translate }}</h2>
  <mat-dialog-content>
    <div *ngFor="let question of questions; let i=index;" class="option-question">
      <div class="option-question__title">{{ question.title }}</div>
      <mat-radio-group fxLayout="row" fxLayoutGap="20px">
        <mat-radio-button
          *ngFor="let option of question.options"
          [value]="option"
          [checked]="answers[question.questionId] === option"
          (change)="changeAnswer($event, question.value, question.questionId)"
          color="primary">
          {{ getLabelOption(option) }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-error *ngIf="qbeWrongKnockout" style="margin: 5px 0 15px;">Sorry, you only qualify for up to $100,000 in Liability Coverage</mat-error>
  </mat-dialog-content>
  <mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="end start"
                      fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign.xs="center center">
    <button mat-button
            class="mat-btn outline"
            style="font-size: 14px;"
            (click)="dialogRef.close(false)">
      {{ 'form.cancel' | translate }}
    </button>
    <button mat-button
            class="mat-btn warning"
            style="font-size: 14px;"
            (click)="dialogRef.close(!qbeWrongKnockout)">
      {{ 'form.continue' | translate }}
    </button>
  </mat-dialog-actions>
</div>
