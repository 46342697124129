import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GetOrCreateResponse, ResidentialService } from '../../core/services/residential.service';
import { PmaService } from '../../pma/pma.service';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { BrandingService } from '../../core/services/branding.service';

@Component({
  selector: 'ui-single-house-address',
  templateUrl: './ui-single-house-address.component.html',
  styleUrls: ['./ui-single-house-address.component.scss']
})
export class UiSingleHouseAddressComponent implements OnInit {
  @Input() form: FormGroup;
  @Output() setAccountId = new EventEmitter();
  @Output() emitAddressAttributes = new EventEmitter();
  loader = false;
  addresses = [];
  notFound = false;

  constructor(private pmaService: PmaService,
              private residentialService: ResidentialService,
              private brandingService: BrandingService) { }

  ngOnInit() {
    this.addressControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(400),
    ).subscribe(() => {
      this.onAddressChange();
    })
  }

  onAddressChange() {
    if (this.addressControl && this.addressControl.value && this.addressControl.value.id) {
      this.getOrCreate();
    } else {
      this.searchAddresses();
    }
  }

  searchAddresses() {
    this.addresses = [];
    const search = this.addressControl && this.addressControl.value && typeof this.addressControl.value === 'string' ? this.addressControl.value : null;
    if (search && search.length > 4) {
      this.fetchAddresses(search);
    }
  }

  fetchAddresses(search = '') {
    this.loader = true;
    this.notFound = false;
    this.pmaService.searchAddresses(search, this.brandingService.getBrandingInfoSnapshot().id).pipe(
      finalize(() => this.loader = false)
    ).subscribe((res: any) => {
      if (res && res.length > 0) {
        this.addresses = res;
      } else {
        this.notFound = true
      }
    });
  }

  getOrCreate(): void {
    this.form.get('insurable_id').setValue(null);
    const address = this.getFullAddress();

    const formData = this.form.value;
    formData.address = this.getFullAddress();

    this.residentialService.getOrCreateUnit(formData)
      .subscribe((result: GetOrCreateResponse) =>  {
        const community = result && result.results && result.results[0] ? result.results[0] : null;
        if (community && community.id) {
          this.form.get('insurable_id').setValue(community.id);
        }
        const accountId = community && community.account_id ? community.account_id : null;
        if (accountId) {
          this.setAccountId.emit(accountId);
        }
        this.emitAddressAttributes.emit(result);
      });
  }

  getFullAddress() {
    if (this.addressControl && this.addressControl.value && this.addressControl.value.id) {
      return this.getAddress(this.addressControl.value);
    }
  }

  get addressControl() {
    return this.form.get('address');
  }

  displayFnAddress(address?: any): string {
    const fullAddress = address &&
                        address.addresses_attributes &&
                        address.addresses_attributes[0] &&
                        address.addresses_attributes[0].full ? address.addresses_attributes[0].full : '';
    return fullAddress ? fullAddress : address && address.title ? address.title : address ? address : '';
  }

  getAddress(item?: any) {
    return item &&
           item.addresses_attributes &&
           item.addresses_attributes[0] &&
           item.addresses_attributes[0].full ? item.addresses_attributes[0].full : '';
  }

  reset() {
    this.addressControl.reset(null);
  }
}
