<svg  class="freeway-footer-layout-social" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53">
  <title>twitter</title>
  <g>
    <title>background</title>
    <rect x="-1" y="-1" width="10.68852" height="10.68852" id="canvas_background" fill="none"/>
  </g>
  <g>
    <title>Layer 1</title>
    <path class="freeway-footer-layout-social_fill" d="m51.64485,11.60458a20.11,20.11 0 0 1 -5.84,1.6a10.12,10.12 0 0 0 4.44,-5.59a20.23,20.23 0 0 1 -6.42,2.45a10.12,10.12 0 0 0 -17.22,9.22a28.7,28.7 0 0 1 -20.8,-10.56a10.12,10.12 0 0 0 3.13,13.49a10.08,10.08 0 0 1 -4.58,-1.27s0,0.08 0,0.13a10.11,10.11 0 0 0 8.11,9.91a10.16,10.16 0 0 1 -2.66,0.36a10,10 0 0 1 -1.9,-0.18a10.12,10.12 0 0 0 9.44,7a20.39,20.39 0 0 1 -15,4.19a28.6,28.6 0 0 0 15.49,4.54c18.59,0 28.76,-15.4 28.76,-28.76q0,-0.66 0,-1.31a20.47,20.47 0 0 0 5.05,-5.22z"/>
  </g>
</svg>
