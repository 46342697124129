import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator, Validators
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap, take,
  takeUntil, tap,
} from 'rxjs/operators';
import { PmaService } from '../pma.service';
import { Carrier } from '../../core/models';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { isEqual, isString } from 'lodash';
import { CarrierMatch, carrierSearch } from '../helpers';
import { isCarrier } from '../../core/typeguards';
import { haveSameContent } from '../../core/utils';

export type CarrierInputValue = Carrier | string;

export interface PmaCarrierComponentFormValue {
  carrierSearch: CarrierInputValue;
  otherCarrierName: string;
}

export type FinalValue = {
  carrier: Carrier | null;
  otherCarrierName: string
} | null;

@Component({
  selector: 'pma-carrier',
  templateUrl: './pma-carrier.component.html',
  styleUrls: ['./pma-carrier.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PmaCarrierComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PmaCarrierComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PmaCarrierComponent implements AfterViewInit, OnDestroy, OnInit, ControlValueAccessor, Validator {

  readonly form: FormGroup = new FormGroup({
    // For autocomplete search
    carrierSearch: new FormControl<CarrierInputValue>(''),
    // User entered carrier name
    otherCarrierName: new FormControl<string>(''),
  });

  readonly carriers$: Observable<Array<Carrier>> = this.pmaService.getCarriers().pipe(
    shareReplay(1),
  );

  private readonly keywordInput$ =  this.form.controls.carrierSearch.valueChanges.pipe(
    startWith(''),
    filter(value => isString(value)),
    distinctUntilChanged(),
    debounceTime(150),
  );

  readonly carrierNameMatches$: Observable<Array<CarrierMatch>> = this.keywordInput$.pipe(
    switchMap(keyword => this.carriers$.pipe(map(carriers => carrierSearch(carriers, keyword)))),
  );

  readonly confirmedCarrierMatches$ = this.carrierNameMatches$.pipe(
    map(matches => matches.filter(match =>  match.carrier.confirmed)),
  );

  /**
   * Observable for "Did you mean"...
   */
  readonly userEntrySimilarMatches$ = this.form.controls.otherCarrierName.valueChanges.pipe(
    debounceTime(150),
    switchMap(keyword =>
      this.carriers$.pipe(map(carriers => carrierSearch(carriers, keyword)))
    ),
    map(matches => matches.filter(match => match.distance >= 0.75)),
  );

  /**
   * To hold a carrier value we want to show in the drop down that may not be confirmed but that the user picked via
   * the similar carriers
   */
  private readonly forcedCarrier$ = new BehaviorSubject<Carrier | null>(null);

  /**
   * Observable to add the forced carrier to the mat-options, even if it's not confirmed
   */
  readonly displayedForcedCarrier$ = combineLatest([
    this.confirmedCarrierMatches$,
    this.forcedCarrier$,
  ]).pipe(map(([matches, carrier]) => {
    return carrier && !matches.some(match => match.carrier.id === carrier.id) ? carrier : null;
  }));

  readonly other = this.translateService.instant('tenant_boarding.pma_carrier_cannot_find_carrier');

  private readonly destroyed$ = new Subject<boolean>();

  @ViewChildren('otherInput') readonly otherInputElementRef: QueryList<ElementRef>;

  constructor(private pmaService: PmaService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(
      distinctUntilChanged((v1, v2) => isEqual(v1, v2)),
      takeUntil(this.destroyed$),
    ).subscribe(value => {
      // Control-Value-Accessor
      if (this.onTouched) {
        this.onTouched();
      }
      if (this.onChange) {
        this.onChange({
          carrier: value.carrierSearch,
          otherCarrierName: value.otherCarrierName,
        });
      }
      // Add "Other" text input validator if user selects other in the autocomplete
      value.carrierSearch === this.other
        ? this.form.controls.otherCarrierName.addValidators([Validators.required])
        : this.form.controls.otherCarrierName.removeValidators([Validators.required]);
    });
  }

  ngAfterViewInit(): void {
    // Autofocus on other input when it becomes available
    this.otherInputElementRef.changes.pipe(
      filter(list => list.length === 1),
      takeUntil(this.destroyed$),
      map(list => list.first.nativeElement),
    ).subscribe(el => el.focus());
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  writeValue(value: Partial<PmaCarrierComponentFormValue>): void {
    this.form.patchValue({ ...value }, { emitEvent: false });
  }

  private onChange: (value: FinalValue) => void;
  registerOnChange(fn: (value: FinalValue) => void): void {
    this.onChange = fn;
  }

  private onTouched: () => void;
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(_: AbstractControl = null): { invalidOtherCarrierName: true } | { invalidCarrier : true } | null {
    const isOther = this.form.controls.carrierSearch.value === this.other;
    if (isOther && !this.otherCarrierName) {
      return { invalidOtherCarrierName: true };
    }
    if (!isOther && !this.carrier) {
      return { invalidCarrier: true };
    }
    return null;
  }

  private get otherCarrierName(): string {
    return this.form.controls.otherCarrierName.value.trim();
  }

  private get carrier(): Carrier | null {
    return isCarrier(this.form.controls.carrierSearch.value)
      ? this.form.controls.carrierSearch.value
      : this.forcedCarrier$.getValue() ?? null;
  }

  reset() {
    this.form.reset({
      carrier: null,
      otherCarrierName: '',
    });
  }

  verifyCarrierName(): void {
    // if we don't have a carrier or other selected, empty the value
    const value = this.form.controls.carrierSearch.value;
    if (value !== this.other && !isCarrier(value)) {
      this.form.patchValue({ carrierSearch: '' });
    }
  }

  displayFnCarrier(carrier: Carrier | string) {
    return isCarrier(carrier) ? carrier.title : carrier === this.other ? this.other : undefined;
  }

  setForcedCarrier(carrier: Carrier): void {
    this.forcedCarrier$.next(carrier);
    this.form.patchValue({ carrierSearch: carrier, otherCarrierName: '' });
  }

  onOtherInputBlur(event: MouseEvent): void {
    // if user is done typing and we can find one equal match, then map it to our DB entries like when a similar entry is clicked
    const value = this.form.controls.otherCarrierName.value;
    if (value) {
      this.carriers$.pipe(
        take(1),
      ).subscribe(carriers => {
        const found = carriers.find(carrier => haveSameContent(carrier.title, value));
        if (found) {
          this.setForcedCarrier(found);
        }
      });
    }
  }

  sameAs(match: CarrierMatch): string {
    const value = isString(this.form.controls.carrierSearch.value) ? this.form.controls.carrierSearch.value : null;
    return value && match.synonymMatches.some(match => haveSameContent(value, match.synonym)) ? value : '';
  }
}

