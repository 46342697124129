<header mat-dialog-title>
  <nav>
    <button (click)="showTerms = true" [class.active]="showTerms === true">Terms of Service</button>
    <button (click)="showTerms = false" [class.active]="showTerms === false">Privacy Policy</button>
  </nav>
  <div>
    <button mat-button [mat-dialog-close]="true">Close</button>
  </div>
</header>
<mat-dialog-content>
  <app-terms-of-use-page *ngIf="showTerms; else privacy"></app-terms-of-use-page>
  <ng-template #privacy>
    <app-privacy-policy-page></app-privacy-policy-page>
  </ng-template>
</mat-dialog-content>
