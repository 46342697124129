<mat-dialog-content>
  <p style="margin-bottom: 15px;">
    A policy with this number has already been uploaded. Would you like to update it?
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="end start"
                    fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign.xs="center center">
  <button mat-button
          class="mat-btn"
          (click)="dialogRef.close(true)">
    Yes
  </button>
  <button mat-button
    class="mat-btn outline"
    (click)="dialogRef.close(false)">
    No
  </button>
</mat-dialog-actions>
