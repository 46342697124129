import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentMsiService {

  constructor(private translate: TranslateService) { }

  getConfig() {
      return {
          fields: {
              card: {
                  selector: '[data-cc-card]' // confirm this points to the corresponding field!
              },
              cvv: {
                  selector: '[data-cc-cvv]', // confirm this points to the corresponding field!
                  placeholder: 'CVV'
              },
              exp: {
                  selector: '[data-cc-exp]' // confirm this points to the corresponding field!
              },
              name: {
                  selector: '[data-cc-name]', // confirm this points to the corresponding field!
                  placeholder: this.translate.instant('form.card_holder_name')
              },
          },
          styles: {
            input: {
              'font-size': '16px',
              color: '#00a9e0',
              'font-family': 'Arial',
            },
            '.card': {
              'font-family': 'monospace'
            },
            ':focus': {
              color: '#00a9e0'
            },
            '.valid': {
              color: '#43B02A'
            },
            '.invalid': {
              color: '#C01324'
            },
            'input:focus:-webkit-autofill': {
              '-webkit-text-fill-color': '#00a9e0',
            },
            'input.valid:-webkit-autofill': {
              '-webkit-text-fill-color': '#43B02A',
            },
            'input.invalid:-webkit-autofill': {
              '-webkit-text-fill-color': '#C01324',
            },
            'input::placeholder': {
              color: '#aaa',
            },
          },
          classes: {
            empty: 'empty',
            focus: 'focus',
            invalid: 'invalid',
            valid: 'valid',
          },
        };
  }
}
