import { Injectable } from '@angular/core';
import { IframeMessage } from '../interfaces/Iframe-message';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

interface IframeState {
  iframeMode: boolean;
}

const INITIAL_STATE: IframeState = {
  iframeMode: false
};

@Injectable({
  providedIn: 'root'
})
export class IframeModeService {
  private store = new BehaviorSubject<IframeState>(INITIAL_STATE);
  public iframeMode$ = this.store.asObservable().pipe(
    map(v => v.iframeMode)
  );
  /**
   * Specify list of white-listed domains
   * that will be allowed and used to send
   * window.postMessage event on all the purchases happens on the site
   * THIS ARRAY SUPPORTS WILDCARD INPUT
   */
  referrerWhiteList = [
    'essex.funnelleasing.com', // Funnel's production URL
    '*.nestiostaging.com' // Funnel's dev/staging domains
  ];

  constructor() { }

  setIframeMode(iframeMode: boolean) {
    this.store.next({
      ...this.store.getValue(),
      iframeMode
    });
  }

  // Return referrer removing http/s prefix and trailing slash
  getReferrer(): string {
    return document.referrer.toLowerCase()
                            .replace(/^https?:\/\//, '')
                            .replace(/\/$/, '');
  }

  getMessageDomain() {
    const domain = this.getReferrer();

    if (!domain) {
      return null;
    }

    // console.log('REFERRER DOMAIN', domain);

    const whitelist = this.referrerWhiteList;

    for (const item of whitelist) {
      // wildcard domain
      if (item.startsWith('*.')) {
        let url = item.replace('*.', '')
          .replace(/^[^.]+\./g, '');

      }
    }
    // *.nestiostaging.com
  }

  sendMessage(message: IframeMessage) {
    window.parent.postMessage({
      ...message,
      event: 'gc_iframe_event',
      payload: 'success'
    }, '*');
  }

}
