<h2 mat-dialog-title>{{ 'disclosure.final_bind_policy_disclosures' | translate }}</h2>
<mat-dialog-content>
  <p>
    {{ 'disclosure.qbe_i_hereby_acknowledge_that_i_authorize_the_insurance_company' | translate }}
    {{data.initialCharge | stripeCurrency}}
    {{'disclosure.with_recurring_monthly_charges_of' | translate}}
    {{data.monthlyCharge | stripeCurrency}}
    {{ 'disclosure.from_the_same_credit_card_for_the_premium_cost' | translate }}
  </p>

  <h3>{{ 'disclosure.nsf_reinstatement_fees' | translate }}</h3>
  <p>{{ 'disclosure.qbe_the_following_additional_fees' | translate }}</p>

  <h3>{{ 'disclosure.options_endorsements' | translate }}</h3>
  <p>
    {{ 'disclosure.your_insurance_policy_may_include_optional_endorsements' | translate }}
    {{(brandingInfo$ |async)?.title}}
    {{ 'disclosure.to_request_changes_to_policy' | translate }}
  </p>
  <h3>{{ 'disclosure.verify_your_email' | translate }}</h3>
  <p>{{ 'disclosure.i_agree_to_receive_all_mailings_and_communications' | translate }} {{data.email}}
    {{ 'disclosure.provided_in_this_on_line_application' | translate }}
  </p>
  <h3>{{ 'disclosure.cancellation_policy' | translate }}</h3>
  <p>
    {{ 'disclosure.i_hereby_acknowledge_that_by_clicking' | translate }} {{nameOfButton | translate}} {{ 'disclosure.button_below_i_agree_to_the_cancelation' | translate }}
  </p>
  <h3>{{ 'disclosure.qbe_fraud_warning' | translate | uppercase }}</h3>
  <p>{{ 'disclosure.qbe_it_is_a_crime' | translate }}</p>
  <br>
  <p>{{ 'disclosure.qbe_fraud_warning_content' | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="end start"
                    fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign.xs="center center">
  <button mat-button
          class="mat-btn warning"
          (click)="dialogRef.close(true)">
    {{ 'total_premium.purchase' | translate }}
  </button>
  <button mat-button
          class="mat-btn outline"
          (click)="dialogRef.close(false)">
    {{ 'form.go_back' | translate }}
  </button>
</mat-dialog-actions>
