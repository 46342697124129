 <div class="pma-insured" [formGroup]="formGroup">
  <header>
    <div class="user-entity-form__title">{{ 'residential.insured_detail' | translate }} ({{ index + 1 }})</div>
    <button *ngIf="removable" mat-button mat-stroked-button type="button" (click)="remove.emit(index)">Remove</button>
  </header>

  <div style="margin-bottom: 12px;">
    <mat-checkbox [formControl]="formGroup.controls.spouse" color="primary">Spouse</mat-checkbox>
  </div>

  <div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
      <mat-form-field fxFlex="" appearance="outline">
        <mat-label>{{ 'form.first_name' | translate }}</mat-label>
        <input matInput [formControl]="formGroup.controls.first_name" />
        <mat-error *ngIf="formGroup.controls.first_name.invalid" class="tight">
          {{ formGroup.controls.first_name | errorMsg:'first_name' }}
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="" appearance="outline">
        <mat-label>{{ 'user.middle_name' | translate }}</mat-label>
        <input matInput [formControl]="formGroup.controls.middle_name" />
        <mat-error *ngIf="formGroup.controls.middle_name.invalid" class="tight">
          {{ formGroup.controls.middle_name | errorMsg:'middle_name' }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">

      <mat-form-field fxFlex="" appearance="outline">
        <mat-label>{{ 'form.last_name' | translate }}</mat-label>
        <input matInput [formControl]="formGroup.controls.last_name" />
        <mat-error *ngIf="formGroup.controls.last_name.invalid" class="tight">
          {{ formGroup.controls.last_name | errorMsg:'last_name' }}
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="" appearance="outline">
        <mat-label>{{ 'form.email' | translate }}</mat-label>
        <input type="email" matInput [formControl]="formGroup.controls.email" />
        <mat-error *ngIf="formGroup.controls.email.errors?.forbiddenEmail; else otherError" class="tight">
          The email entered for this individual cannot be yours.
        </mat-error>
        <ng-template #otherError>
          <mat-error *ngIf="formGroup.controls.email.invalid" class="tight">
            {{ formGroup.controls.email | errorMsg:'email' }}
          </mat-error>
        </ng-template>
        <mat-hint>The email must be unique to each individual and different from yours.</mat-hint>
    </mat-form-field>
  </div>
</div>
</div>
