<form (submit)="handleForm($event)">
  <div class="form-row">

    <div id="card-element" #cardElement>
      <!-- A Stripe Element will be inserted here. -->
    </div>

    <!-- Used to display Element errors. -->
    <p class="has-text-danger">{{ cardErrors }}</p>
  </div>
  <hr>
  <div class="guarantee__btns-container" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="stretch">
    <button mat-button class="mat-btn" [disabled]="loading" [class.spinner]="loading">{{ 'total_premium.purchase' | translate }}</button>
    <button mat-button type="reset" class="mat-btn outline" [disabled]="loading" *ngIf="hasBackButton" (click)="decline()">{{ 'form.go_back' | translate }}</button>
  </div>

</form>



<pre *ngIf="confirmation" style="max-width: 500px;">
  {{ confirmation | json }}
</pre>
