import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GaEcommerceService {
  products = [
      {
          item_id: 1,
          item_name: 'Rent Guarantee',
          policy_type_id: 5,
          quantity: 1
      },
      {

          item_id: 2,
          item_name: 'Renters Insurance',
          policy_type_id: 1,
          quantity: 1
      }
  ];

  constructor() { }

  getProduct(policyTypeId, additionalInfo = {}) {
      const prod = this.products.find(product => product.policy_type_id === policyTypeId);
      const item = Object.assign(additionalInfo, prod);

      return item;
  }

  formatPrice(price) {
      return price / 100;
  }
}
