import { Component, OnInit } from '@angular/core';
import { BrandingService } from '../../core/services/branding.service';

@Component({
  selector: 'app-terms-of-use-page',
  templateUrl: './terms-of-use-page.component.html',
  styleUrls: ['./terms-of-use-page.component.css']
})
export class TermsOfUsePageComponent implements OnInit {
  constructor(public brandingService: BrandingService) { }

  ngOnInit(): void {
    this.brandingService.setBrandingTitle('Terms of Use');
  }
}
