<div class="terms-block">
  <div class="terms">
    <h2 [innerHTML]="'terms_of_use.get_covered_br_terms_of_use' | translate"></h2>
    <p>{{ 'terms_of_use.last_modified' | translate }}: [<mark>{{ 'terms_of_use.main_date' | translate }}</mark>]</p>
  </div>

  <div class="terms-content">
    <p>
      <span [innerHTML]="'terms_of_use.thanks_for_using_get_covered' | translate"></span>
      (<span class="upper-text yellow-marker"> {{brandingService.termOfUse$ | async}} </span>)
      <span [innerHTML]="'terms_of_use.the_site_mobile_application' | translate"></span>
    </p>

    <p class="upper-text">{{ 'terms_of_use.please_read_this_agreement' | translate }}</p>

    <ol class="title-of-terms">
      <li>
        <p class="underline">
          {{ 'terms_of_use.use_of_services' | translate }}
        </p>
        <ol class="description-of-terms">
          <li>
            <p>
              {{ 'terms_of_use.eligibility' | translate }}
            </p>
            <p>{{ 'terms_of_use.this_is_a_contract_between_you_get_covered' | translate }}
            </p>
          </li>
          <li>
            <p>
              {{ 'terms_of_use.access_to_the_services' | translate }}
            </p>
            <p>
              {{ 'terms_of_use.subject_to_the_terms_and_conditions' | translate }}
            </p>
            <p>
              {{ 'terms_of_use.to_use_the_app_you_must_have_a_mobile_device' | translate }}
            </p>
          </li>
          <li>
            <p>
              {{ 'terms_of_use.get_covered_account' | translate }}
            </p>
            <p>
              {{ 'terms_of_use.in_order_to_process_your_online_transactions' | translate }}
            </p>
            <p>
              {{ 'terms_of_use.your_get_covered_account_gives_you_access' | translate }}
            </p>
            <p>
              {{ 'terms_of_use.you_may_never_use_another_users_account' | translate }}
            </p>

            <p [innerHTML]="'terms_of_use.you_may_control_your_user_profile' | translate"></p>

          </li>
          <li>
            <p>
              {{ 'terms_of_use.services_rules' | translate }}
            </p>
            <p>
              {{ 'terms_of_use.you_agree_not_to_engage' | translate }}
            </p>
            <p>
              {{ 'terms_of_use.we_may_without_prior_notice' | translate }}
            </p>
            <p>
              {{ 'terms_of_use.you_are_solely_responsible' | translate }}
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p class="underline">
          {{ 'terms_of_use.user_data_license_grant' | translate }}
        </p>

        <p [innerHTML]="'terms_of_use.by_disclosing_or_otherwise' | translate"></p>

      </li>
      <li>
        <p class="underline">
          {{ 'terms_of_use.out_proprietary_rights' | translate }}
        </p>

        <p [innerHTML]="'terms_of_use.except_for_your_user_data' | translate"></p>

        <p [innerHTML]="'terms_of_use.you_may_choose_to_or_we_may' | translate"></p>
      </li>
      <li>
        <p class="underline">
          {{ 'terms_of_use.privacy' | translate }}
        </p>
        <p>
          {{ 'terms_of_use.we_care_about_the_privacy' | translate }}
        </p>
      </li>
      <li>
        <p class="underline">
          {{ 'terms_of_use.security' | translate }}
        </p>
        <p>
          {{ 'terms_of_use.get_covered_uses_commercially' | translate }}
        </p>
      </li>
      <li>
        <p class="underline">
          {{ 'terms_of_use.third_party_links_and_information' | translate }}
        </p>
        <p>
          {{ 'terms_of_use.the_services_may_contain_links' | translate }}
        </p>
        <p>
          {{ 'terms_of_use.we_use_stripe_to_process' | translate }}

          <a href="#">{{ 'terms_of_use.stripes_terms_of_use' | translate }}</a>.

        </p>
        <p>
          {{ 'terms_of_use.this_product_uses_the_fcc' | translate }}
        </p>
      </li>
      <li>
        <p class="underline">
          {{ 'terms_of_use.indemnity' | translate }}
        </p>
        <p>
          {{ 'terms_of_use.you_agree_to_defend' | translate }}
        </p>
      </li>
      <li>
        <p class="underline">
          {{ 'terms_of_use.no_warranty' | translate }}
        </p>
        <p class="upper-text">
          {{ 'terms_of_use.the_services_are_provided' | translate }}
        </p>
        <p class="upper-text">
          {{ 'terms_of_use.get_covered_does_not_warrant' | translate }}
        </p>
        <p class="upper-text">
          {{ 'terms_of_use.federal_law_some_states' | translate }}
        </p>
      </li>
      <li>
        <p class="underline">
          {{ 'terms_of_use.limitation_of_liability' | translate }}
        </p>
        <p class="upper-text">
          {{ 'terms_of_use.to_the_maximum_extent' | translate }}
        </p>
        <p class="upper-text">
          {{ 'terms_of_use.to_the_maximum_extent_permitted' | translate }}
        </p>
        <p class="upper-text">
          {{ 'terms_of_use.this_limitation_of_liability_section' | translate }}
        </p>
        <p class="upper-text">
          {{ 'terms_of_use.some_states_do_not_allow' | translate }}
        </p>
        <p>
          {{ 'terms_of_use.the_services_are_controlled' | translate }}
        </p>
      </li>
      <li>
        <p class="underline">
          {{ 'terms_of_use.governing_law_arbitration' | translate }}
        </p>
        <ol class="description-of-terms">
          <li>
            <p>
              {{ 'terms_of_use.governing_law' | translate }}
            </p>
            <p>
              {{ 'terms_of_use.you_agree_that_i' | translate }}
            </p>
          </li>
          <li>
            <p>
              {{ 'terms_of_use.arbitration' | translate }}
            </p>

            <p [innerHTML]="'terms_of_use.read_this_section_carefully' | translate"></p>

          </li>
          <li>
            <p>
              {{ 'terms_of_use.class_action_jury_trial' | translate }}
            </p>
            <p class="upper-text">
              {{ 'terms_of_use.with_respects_to_all_persons' | translate }}
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p class="underline">
          {{ 'terms_of_use.general' | translate }}
        </p>
        <ol class="description-of-terms">
          <li>
            <p>
              {{ 'terms_of_use.communications' | translate }}
            </p>

            <p [innerHTML]="'terms_of_use.you_consent_to_receive' | translate"></p>

          </li>
          <li>
            <p>
              {{ 'terms_of_use.assignment' | translate }}
            </p>
            <p>
              {{ 'terms_of_use.this_agreement_and_any_rights' | translate }}
            </p>
          </li>
          <li>
            <p>
              {{ 'terms_of_use.notification_procedures' | translate }}
            </p>
            <p>
              {{ 'terms_of_use.get_covered_may_provide_notifications' | translate }}
            </p>
          </li>
          <li>
            <p>
              {{ 'terms_of_use.entire_agreement_severability' | translate }}
            </p>
            <p>
              {{ 'terms_of_use.this_agreement_together_with_any_amendments' | translate }}
            </p>
          </li>
          <li>
            <p>
              {{ 'terms_of_use.no_waiver' | translate }}
            </p>
            <p>
              {{ 'terms_of_use.no_waiver_of_any_term' | translate }}
            </p>
          </li>
          <li>
            <p>
              {{ 'terms_of_use.california_residents' | translate }}
            </p>
            <p>
              {{ 'terms_of_use.the_provider_of_the_services' | translate }}
              <a href="tel:9173008200">917-300-8200</a>,
              {{ 'terms_of_use.email' | translate }}
              <a href="mailto:info@getcovered.io">info@getcovered.io</a>.
              {{ 'terms_of_use.if_you_are_a_california_resident' | translate }}
            </p>
          </li>
        </ol>
      </li>
    </ol>
  </div>
</div>
