import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';
import { AuthRoutingModule } from './auth-routing.module';
import { TranslateModule } from '@ngx-translate/core';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';


@NgModule({
    declarations: [
        LoginComponent,
        RegisterComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent,
        ChangePasswordFormComponent,
        AcceptInvitationComponent,
    ],
    exports: [
        ChangePasswordFormComponent
    ],
    imports: [
        SharedModule,
        AuthRoutingModule,
        TranslateModule
    ]
})
export class AuthModule { }
