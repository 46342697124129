import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { JwtService } from './jwt.service';
import { UserModel } from '../models/user.model';
import { TokenInterface } from '../models/token.interface';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';


interface AuthState {
  user: UserModel;
  isAuthenticated: boolean | null;
}

const AUTH_INITIAL_STATE = {
  user: null,
  isAuthenticated: null
};

@Injectable()
export class AuthService {

  private store = new BehaviorSubject<AuthState>(AUTH_INITIAL_STATE);
  public isAuthenticated$: Observable<boolean> = this.store.asObservable().pipe(
    map(v => v.isAuthenticated)
  );
  public get isAuthenticatedSnapshot() {
    return this.store.getValue().isAuthenticated;
  }
  public user = this.store.asObservable().pipe(
    map(v => v.user)
  );

  constructor(
    private httpService: HttpClient,
    private jwtService: JwtService,
    private router: Router
  ) {}

  login(payload): Observable<any> {
    return this.httpService.post('/user/auth/sign_in', payload, {observe: 'response'})
      .pipe(
        tap(res => {
          const header: TokenInterface = {
            access_token: res.headers.get('access-token'),
            token_type  : res.headers.get('token-type'),
            client      : res.headers.get('client'),
            expiry      : res.headers.get('expiry'),
            uid         : res.headers.get('uid'),
          };
          this.setAuth(res.body, header);
          return res;
        })
      );
  }

  setAuth(user: UserModel, headers: TokenInterface) {
    this.trackAuth(user);
    this.jwtService.saveToken(headers);
    this.store.next({
      user,
      isAuthenticated: true
    });
  }

  signOut() {
    return this.httpService.delete('/user/auth/sign_out').pipe(
      tap(() => {
        this.logOut();
      })
    );
  }

  logOut() {
    this.jwtService.destroyToken();
    this.store.next({
      user: null,
      isAuthenticated: false
    });
    this.router.navigate(['/']);
  }

  userSetToLogin() {
    this.store.next({
      user: null,
      isAuthenticated: false
    });
  }

  getCurrentUser(): UserModel | any {
    return this.store.value ? this.store.value.user : null;
  }

  // :uid, :client, :access-token
  validateToken(token: TokenInterface) {
    const headers = new HttpHeaders()
      .set('access-token', token.access_token)
      .set('uid', token.uid)
      .set('client', token.client)
      .set('token-type', token['token_type'])
      .set('expiry', token.expiry);

    return this.httpService.get('/user/auth/validate_token', { headers , observe: 'response' })
      .pipe( map(res => res.body) );
  }

  updateCurrentUser() {
    const token = this.jwtService.getToken();
    if (token && token.uid && token.uid !== 'null') {
      this.validateToken(token).pipe(
        map(user => {
          this.setUser(user);
        })
      ).subscribe();
    }
  }

  // https://github.com/getcoveredllc/GetCovered-V2/wiki/User-Authentication-Flow
  resetPassword(data: {
    password: string,
    password_confirmation: string,
    reset_password_token: string
  }): Observable<any> {
    return this.httpService.put(`/user/auth/password`, data);
  }

  invitationResetPassword(data: {
    password: string,
    password_confirmation: string,
    invitation_token: string
  }): Observable<any>  {
    return this.httpService.put(`/user/auth/invitation`, data);
  }

  secretAuthenticate(authToken) {
    const encodedAuthToken = encodeURIComponent(authToken);
    return this.httpService.post(`/secret_authentication/${encodedAuthToken}/authenticate`, null, { observe: 'response' })
      .pipe(
        switchMap(res => {
          const token: TokenInterface = {
            access_token: res.headers.get('access-token'),
            token_type  : res.headers.get('token-type'),
            client      : res.headers.get('client'),
            expiry      : res.headers.get('expiry'),
            uid         : res.headers.get('uid'),
          };
          return this.validateToken(token);
        })
      );
  }

  authenticateUser() {
    const token = this.jwtService.getToken();
    this.validateToken(token).subscribe(user => {
      this.setUser(user);
    });
  }

  setUser(user) {
    this.trackAuth(user);
    this.store.next({
      user,
      isAuthenticated: true
    });
  }

  trackAuth(user) {
    // @ts-ignore
    if (typeof window.FS !== 'undefined') {
      // @ts-ignore
        window.FS.identify(user.id, {
          displayName: user.profile_attributes.full_name,
          email: user.email
        });
    }

    // @ts-ignore
    if (typeof window.Intercom !== 'undefined') {
        // @ts-ignore
        window.Intercom('boot', {
          app_id: 'zkctq5ql',
          email: user.email,
          user_id: user.id,
        });
    }
  }
}
