import { ApiResponse } from '../interfaces';
import { isObject } from 'lodash';

/**
 * Type guard for API response
 * @param v
 */
export const isApiResponse = (v: any): v is ApiResponse<any> => {
  return isObject(v) && v !== null && 'data' in v;
};
