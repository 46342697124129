import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BrandingService } from '../../core/services/branding.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { PagesService } from '../../core/services/pages.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactUsPageComponent implements OnInit, OnDestroy {
  pageContent;
  loading = true;
  subscription: Subscription = new Subscription();
  constructor(
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private brandingService: BrandingService,
    private pagesService: PagesService
  ) { }

  ngOnInit(): void {
    this.brandingService.setBrandingTitle('Contact Us');
    this.getPageContent();
  }

  getPageContent() {
    const pageTitle = this.activatedRoute.snapshot.data['pageTitle'];
    this.subscription.add(this.brandingService.getPageByTitle(pageTitle).pipe(
      tap(() => this.loading = true),
      filter(v => !!v),
      map(v => v.id),
      switchMap((id) => this.pagesService.getPage(id)),
      map((v: any) => v.content)
    ).subscribe(pageContent => {
      this.loading = false;
      this.pageContent = this.sanitizer.bypassSecurityTrustHtml(pageContent);
    }));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
