import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-freeway-layout-footer-social',
  templateUrl: './freeway-layout-footer-social.component.html',
  styleUrls: ['./freeway-layout-footer-social.component.css']
})
export class FreewayLayoutFooterSocialComponent implements OnInit {

  @Input() type;

  constructor() { }

  ngOnInit(): void {
  }

}
