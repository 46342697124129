<div class="total-premium-states">
  <p *ngIf="state === 'AL'">
    {{ 'state_notice.al' | translate }}
  </p>

  <p *ngIf="state === 'AR'">
    {{ 'state_notice.ar' | translate }}
  </p>

  <p *ngIf="state === 'AZ'">
    {{ 'state_notice.az' | translate }}
  </p>

  <p *ngIf="state === 'CA'">
    {{ 'state_notice.ca' | translate }}
  </p>

  <p *ngIf="state === 'CO'">
    {{ 'state_notice.co' | translate }}
  </p>

  <p *ngIf="state === 'CT'">
    {{ 'state_notice.ct' | translate }}
  </p>


  <p *ngIf="state === 'DC'">
    {{ 'state_notice.dc' | translate }}
  </p>

  <p *ngIf="state === 'DE'">
    {{ 'state_notice.de' | translate }}
  </p>

  <p *ngIf="state === 'FL'">
    {{ 'state_notice.fl' | translate }}
  </p>

  <p *ngIf="state === 'GA'">
    {{ 'state_notice.ga' | translate }}
  </p>

  <p *ngIf="state === 'HI'">
    {{ 'state_notice.hi' | translate }}
  </p>

  <p *ngIf="state === 'IA'">
    {{ 'state_notice.ia' | translate }}
  </p>

  <p *ngIf="state === 'ID'">
    {{ 'state_notice.id' | translate }}
  </p>

  <p *ngIf="state === 'IL'">
    {{ 'state_notice.il' | translate }}
  </p>

  <p *ngIf="state === 'IN'">
    {{ 'state_notice.in' | translate }}
  </p>

  <p *ngIf="state === 'KS'">
    {{ 'state_notice.ks' | translate }}
  </p>

  <p *ngIf="state === 'KY'">
    {{ 'state_notice.ky' | translate }}
  </p>

  <p *ngIf="state === 'LA'">
    {{ 'state_notice.la' | translate }}
  </p>

  <p *ngIf="state === 'MA'">
    {{ 'state_notice.ma' | translate }}
  </p>

  <p *ngIf="state === 'MD'">
    {{ 'state_notice.md' | translate }}
  </p>

  <p *ngIf="state === 'ME'">
    {{ 'state_notice.me' | translate }}
  </p>

  <p *ngIf="state === 'MI'">
    {{ 'state_notice.mi' | translate }}
  </p>

  <p *ngIf="state === 'MN'">
    {{ 'state_notice.mn' | translate }}
  </p>

  <p *ngIf="state === 'MO'">
    {{ 'state_notice.mo' | translate }}
  </p>

  <p *ngIf="state === 'MS'">
    {{ 'state_notice.ms' | translate }}
  </p>

  <p *ngIf="state === 'MT'">
    {{ 'state_notice.mt' | translate }}
  </p>

  <p *ngIf="state === 'NC'">
    {{ 'state_notice.nc' | translate }}
  </p>

  <p *ngIf="state === 'ND'">
    {{ 'state_notice.nd' | translate }}
  </p>

  <p *ngIf="state === 'NE'">
    {{ 'state_notice.ne' | translate }}
  </p>


  <p *ngIf="state === 'NH'">
    {{ 'state_notice.nh' | translate }}
  </p>

  <p *ngIf="state === 'NJ'">
    {{ 'state_notice.nj' | translate }}
  </p>

  <p *ngIf="state === 'NM'">
    {{ 'state_notice.nm' | translate }}
  </p>

  <p *ngIf="state === 'NV'">
    {{ 'state_notice.nv' | translate }}
  </p>

  <p *ngIf="state === 'NY'">
    {{ 'state_notice.ny' | translate }}
  </p>


  <p *ngIf="state === 'OH'">
    {{ 'state_notice.oh' | translate }}
  </p>

  <p *ngIf="state === 'OK'">
    {{ 'state_notice.ok' | translate }}
  </p>

  <p *ngIf="state === 'OR'">
    {{ 'state_notice.or' | translate }}
  </p>

  <p *ngIf="state === 'PA'">
    {{ 'state_notice.pa' | translate }}
  </p>

  <p *ngIf="state === 'RI'">
    {{ 'state_notice.ri' | translate }}
  </p>

  <p *ngIf="state === 'SC'">
    {{ 'state_notice.sc' | translate }}
  </p>

  <p *ngIf="state === 'SD'">
    {{ 'state_notice.sd' | translate }}
  </p>

  <p *ngIf="state === 'TN'">
    {{ 'state_notice.tn' | translate }}
  </p>

  <p *ngIf="state === 'TX'">
    {{ 'state_notice.tx' | translate }}
  </p>

  <p *ngIf="state === 'UT'">
    {{ 'state_notice.ut' | translate }}
  </p>

  <p *ngIf="state === 'VA'">
    {{ 'state_notice.va' | translate }}
  </p>

  <p *ngIf="state === 'VT'">
    {{ 'state_notice.vt' | translate }}
  </p>

  <p *ngIf="state === 'WA'">
    {{ 'state_notice.wa' | translate }}
  </p>

  <p *ngIf="state === 'WI'">
    {{ 'state_notice.wi' | translate }}
  </p>

  <p *ngIf="state === 'WV'">
    {{ 'state_notice.wv' | translate }}
  </p>

  <p *ngIf="state === 'WY'">
    {{ 'state_notice.wy' | translate }}
  </p>
</div>
