import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-facebook',
  templateUrl: './social-facebook.component.html',
  styleUrls: ['./social-facebook.component.css']
})
export class SocialFacebookComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
