import { Component } from '@angular/core';
import { IframeModeService } from '../../../core/services/iframe-mode.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  IframeMode = false;

  constructor(private IframeModeService: IframeModeService) {
    IframeModeService.iframeMode$.subscribe(IframeMode => this.IframeMode = IframeMode);
  }

}
