import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';

import {
  AuthService,
  JwtService
} from './services';
import { ProfileResolver } from './resolvers/profile.resolver';
import { PoliciesResolver } from './resolvers/policies.resolver';
import { AuthGuard } from './services/auth-guard.service';
import { LeasesResolver } from './resolvers/leases.resolver';
import { ResidentialResolver } from './resolvers/residential.resolver';
import { CommercialResolver } from './resolvers/commercial.resolver';
import { GuaranteeResolver } from './resolvers/guarantee.resolver';
import { PageResolver } from './resolvers/page.resolver';


@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    AuthService,
    JwtService,
    AuthGuard,
    ProfileResolver,
    PoliciesResolver,
    LeasesResolver,
    ResidentialResolver,
    CommercialResolver,
    GuaranteeResolver,
    PageResolver
  ],
  declarations: []
})
export class CoreModule { }
