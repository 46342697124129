import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormControlStatus,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { first, flatMap, intersection, isArray, isEmpty, isEqual, isObject, isString, pick, uniq } from 'lodash';
import * as _moment from 'moment';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, EMPTY, merge, Observable, of, Subscription } from 'rxjs';
import {
  BrandingAdditionalInterest,
  GCPolicyUploadDefaultAddress,
  GCPolicyUploadNewAddress,
  PmaService
} from '../../pma.service';
import { AuthService } from '../../../core/services';
import { BrandingService } from '../../../core/services/branding.service';
import { BrandingInfo } from '../../../core/models/branding.model';
import {
  PmaDialogAdditionalInterestComponent
} from './pma-dialog-additional-interest/pma-dialog-additional-interest.component';
import { PmAccount } from '../../../core/dto/pm-account';
import { ResidentialService } from '../../../core/services/residential.service';
import { IframeModeService } from '../../../core/services/iframe-mode.service';
import { DialogPolicyNumberComponent } from './dialog-policy-number/dialog-policy-number.component';
import { ExternalPolicyFillService } from './external-policy-fill.service';
import { PolicyAssignCheckPayload, PolicyAssignService } from './policy-assign.service';
import { UserMatchService } from '../../../auth/user-match.service';
import { customEmailValidator } from '../../../core/utils/custom-email-validator.utils';
import { isCarrier } from '../../../core/typeguards';
import { MatSelectChange } from '@angular/material/select';
import { dateToYMDDate, ViewPortVisibilityObserver } from '../../../core/utils';
import { MatFormField } from '@angular/material/form-field';
import { YMDDate } from '../../../core/models';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MasterPolicy } from '../../../core/interfaces/master-policy.interface';

const moment = _moment;

type PolicyCheckResult = Partial<{
  canUpload: boolean,
  number: string;
  belongs_to_provided_user: boolean;
  exists: boolean;
}>


interface UserControlErrors {
  invalid: boolean;
  countMismatch: true;
  spouseCount: true;
  mainEmailInUserEmails: true;
  duplicateEmails: Array<string>;
}

const fieldNames = {
  email: 'email',
  firstName: 'first_name',
  insurableId: 'insurable_id',
  lastName: 'last_name',
  number: 'number',
  unit: 'unit',
};

enum PolicyVerificationStatus {
  pendingVerification = 'pendingVerification',
  verification = 'verification',
  verified = 'verified',
  uploading = 'uploading',
  uploaded = 'uploaded',
  uploadError = 'uploadError',
}


@Component({
  selector: 'upload-coverage',
  templateUrl: './upload-coverage.component.html',
  styleUrls: ['./upload-coverage.component.scss'],
})
export class UploadCoverageComponent implements AfterViewInit, OnInit, OnDestroy {
  @Output() changeStep = new EventEmitter();
  @Input() firstForm;
  form: FormGroup;
  @Input() communityName = 'Community Name';
  @Input() buildingName;
  @Input() unitName = 'Unit Number';
  @Input() fullAddress;
  @Input() state: string = '';
  @Input() isMasterPolicy;
  @Input() communityAdditionalInterestName;
  @Input() additionalInterestAddress;
  @Input() communityControl;
  @Input() county;
  @Input() buildingControl;
  @Input() unitControl;
  @Input() inTenantBoarding = true;
  @Input() isBrandingSecondNature;
  @Output() setAddress = new EventEmitter();
  @Input() pmAccountName;
  @Input() accountId;
  @Input() agencyId;
  @Input() addressAttributes = null;
  @Input() nonApplicableControl: FormControl;
  @Input() nonpreferredAccountSelection;
  @Input() nonpreferredAddressSelection;
  @Input() pmaSingleHouses;

  @Input() fromToken: boolean = false;
  @Input() masterPolicies: MasterPolicy[] = [];

  public readonly quoteButtonColor$ = this.brandingService.quoteButtonColor$

  readonly policyUploadStatus$ = new BehaviorSubject<
    PolicyVerificationStatus
  >(PolicyVerificationStatus.pendingVerification);

  readonly verifyingLeaseOrPolicy$ = this.policyUploadStatus$.pipe(
    map(status => status === PolicyVerificationStatus.verification),
  );

  readonly policyVerified$ = this.policyUploadStatus$.pipe(
    map(status => status === PolicyVerificationStatus.verified),
  );

  userId;
  carrierControl = new FormControl();
  dogCoverageControl = new FormControl();
  // liabilityCoverageControl = new FormControl();
  //contentsCoverageControl = new FormControl();
  communityContentsCoverageMin;
  communityLiabilityCoverageMin;

  minDate = moment(new Date).subtract(2, 'year').toDate();
  maxDate;
  branding: BrandingInfo;
  brandingSlug: string;
  brandingAdditionalInterest: BrandingAdditionalInterest = null;
  contactEmail: string;

  subscription = new Subscription();

  GCPolicyUploadDefaultAddress = GCPolicyUploadDefaultAddress;
  GCPolicyUploadNewAddress = GCPolicyUploadNewAddress;

  fetchedCommunityAdditionalInterestName;
  fetchedAdditionalInterestAddress;

  additionalInterest: BrandingAdditionalInterest = null;

  slug;
  userAddressAttributes;
  liabilityCoverages = PmaService.getLiabilityCoverages();
  buildings;
  units;
  synonyms;
  policyPayloadCheck;
  isPolicyNumberValid = true;
  brandingId;
  pmaUploadH1$: Observable<string>;
  pmaUploadDescription$: Observable<string>;

  promptForInsuranceDetails$: Observable<boolean>;

  private uploadCheckResult$: Observable<PolicyCheckResult>;
  canUpload$: Observable<boolean>;
  policyUploadLocked$: Observable<boolean>;
  policyBelongsToOtherUser$: Observable<boolean>;

  /**
   * Displayed error links on top of continue button
   * [ text, anchor ]
   */
  displayedErrorLinks$: Observable<Array<[ string, string ]>>;

  readonly editFirstFormValues$ = new BehaviorSubject<boolean>(false);

  processing$: Observable<boolean>;

  continueDisabled$: Observable<boolean>;

  identityFormComplete$: Observable<boolean>;

  insurableId$: Observable<number>;

  firstFormComplete$: Observable<boolean>;

  displayFirstFormFields$: Observable<boolean>;

  showInsurableForm$: Observable<boolean>;

  showLeaseForm$: Observable<boolean>;

  @ViewChild('submitButton', { read: ElementRef }) private submitButton: ElementRef;

  @ViewChildren(MatFormField, { read: ElementRef }) private fieldElements: QueryList<ElementRef>;

  private isButtonVisible$: Observable<boolean>;
  private alreadyExistsModalDisplayed = false;

  constructor(private fb: FormBuilder,
              private router: Router,
              private dialog: MatDialog,
              private translate: TranslateService,
              private cd: ChangeDetectorRef,
              private toastr: ToastrService,
              private pmaService: PmaService,
              private authService: AuthService,
              private brandingService: BrandingService,
              private residentialService: ResidentialService,
              private IframeModeService: IframeModeService,
              private externalPolicyFillService: ExternalPolicyFillService,
              private policyAssignService: PolicyAssignService,
              private userMatchService: UserMatchService) {
  }

  get liabilityCoverageControl(): AbstractControl {
    return this.form.controls.liability_coverage;
  }

  get contentsCoverageControl(): AbstractControl {
    return this.form.controls.contents_coverage;
  }

  ngOnInit(): void {
    const initialValues = (() => {
      const values = {
        first_name: this.firstForm?.value?.first_name ?? '',
        last_name: this.firstForm?.value?.last_name ?? '',
        email: this.firstForm?.value?.email ?? '',
      };
      if (!this.inTenantBoarding) {
        const user = this.authService.getCurrentUser();
        if (user) {
          if (user.profile_attributes.first_name?.trim()) {
            values.first_name = user.profile_attributes.first_name;
          }
          if (user.profile_attributes.last_name?.trim()) {
            values.last_name = user.profile_attributes.last_name;
          }
          if (user.email) {
            values.email = user.email;
          }
        }
      }
      return values;
    })();

    this.form = this.fb.group({
      first_name: [
        initialValues.first_name,
        [Validators.required, Validators.maxLength(30)],
      ],
      last_name: [
        initialValues.last_name,
        [Validators.required, Validators.maxLength(30)],
      ],
      middle_name: ['', [Validators.maxLength(25)]],
      email: [
        initialValues.email ?? '',
        [customEmailValidator()]
      ],
      number: ['', [Validators.required]],
      policy_type_id: [1, []],
      effective_date: ['', {
        updateOn: 'blur',
        validators: [Validators.required]
      }], // required if not GC policy
      expiration_date: [{ value: '' }, [Validators.required]],
      documents: [[], [ Validators.required ]],
      out_of_system_carrier_title: [{ carrier: null, otherCarrierName: '' }, [Validators.required]],
      additional_interest: [false, []], // required if not GC policy
      system_purchased: [false, []],
      userCount: [0, []],
      users: this.fb.array([]),
      liability_coverage: [{ value: '', disabled: true }, [Validators.required]],
      contents_coverage: [{ value: 0, disabled: true }, []],
    });

    const sub = this.form.controls.effective_date.valueChanges.subscribe(
      () => this.setCoverageValidators()
    );
    this.subscription.add(sub);

    this.identityFormComplete$ = this.form.valueChanges.pipe(
      startWith(this.form.value ?? {}),
      map(values => {
        return !!values.first_name?.trim() && !!values.last_name?.trim() && !!values.email?.trim();
      }),
    );

    // we only enable the Your information box if the user is not landing from the token URL
    this.firstFormComplete$ = this.fromToken ? of(false) : this.firstForm.valueChanges.pipe(
      startWith(this.firstForm.value),
      map(values => {
        const fields = [
          fieldNames.email,
          fieldNames.firstName,
          fieldNames.lastName,
          fieldNames.unit,
        ];
        const valid = fields.filter(field => {
          return isString(values[field]) ? !!values[field]?.trim() : !!values[field];
        });
        return valid.length === fields.length;
      }),
      startWith(false),
    );

    this.displayFirstFormFields$ = combineLatest(
      this.firstFormComplete$,
      this.editFirstFormValues$,
    ).pipe(map(([complete, edit]) => !complete || edit));

    this.insurableId$ =  this.firstForm ? this.firstForm.valueChanges.pipe(
      startWith(this.firstForm.value),
      map(
      value => {
        return isObject(value) && Number(value[fieldNames.insurableId]);
      }),
    ) : of(0);

    // Custom validator for insured users
    const insuredUsersValidator: ValidatorFn = (control: FormArray): null | Partial<UserControlErrors> => {
      const count = this.form.controls.userCount.value;
      const errors: Partial<UserControlErrors> = {};

      const invalidControls = control.controls.some(ctrl => ctrl.invalid);

      if (invalidControls) {
        errors.invalid = true;
      }

      if (count !== control.controls.length) {
        errors.countMismatch = true;
      }

      // check spouse isn't checked multiple times
      const spouseCount = control.controls.filter(ctrl => !!ctrl.value.spouse).length;
      if (spouseCount > 1) {
        errors.spouseCount = true;
      }
      const emails = control.controls
        .map(ctrl => ctrl.value.email?.trim().toLowerCase())
        .filter(email => !!email);

      // check main email is not entered in the emails for users
      const mainEmail = this.form.controls.email.value?.trim().toLowerCase();
      if (emails.includes(mainEmail)) {
        errors.mainEmailInUserEmails = true;
      }
      // check emails are not the same in the list of users
      const uniqEmails =  uniq(emails);
      const duplicates = emails.length > uniqEmails.length;
      if (duplicates) {
        errors.duplicateEmails = intersection(emails, uniqEmails);
      }
      return isEmpty(errors) ? null : errors;
    };
    this.form.controls.users.addValidators([insuredUsersValidator]);

    const debouncedFormChanges$ = combineLatest([
      this.form.valueChanges.pipe(
        startWith(this.form.value),
        debounceTime(300),
        tap(() => this.form.updateValueAndValidity({ emitEvent: false })),
        filter(() => {
          return isEmpty(this.form.controls.email.errors);
        }),
      ),
      this.firstForm.controls.insurable_id.valueChanges.pipe(
        startWith(this.firstForm.value.insurable_id),
      ),
    ]).pipe(
      map(([values, insurable_id]) => ({ ...values, insurable_id })),
    );

    const verifyValues$ = debouncedFormChanges$.pipe(
      map(value => ({
        email: value?.email?.trim().toLowerCase(),
        first_name: value?.first_name?.trim().toLowerCase(),
        insurable_id: value?.insurable_id ?? null,
        last_name: value?.last_name?.trim().toLowerCase(),
        number: value?.number?.trim(),
      })),
      distinctUntilChanged(isEqual),
    );

    this.uploadCheckResult$ = verifyValues$.pipe(
      distinctUntilChanged(isEqual),
      map((values) =>
        pick(values, [fieldNames.email, fieldNames.number]) as unknown as  { email: string, number: string }),
      filter(({ email, number }) => !!email && !!number),
      tap(_ => {
        this.policyUploadStatus$.next(PolicyVerificationStatus.verification);
      }),
      switchMap(({ number, email }) => {
        return this.externalPolicyFillService.policyCanBeUploaded(number).pipe(
          map(canUpload => ({ canUpload, number })),
          switchMap(result => !!email ? this.policyAssignCheck().pipe(
            map(res => ({ ...result, ...res })),
          ) : of(result)),
          tap((res) => {
            this.policyUploadStatus$.next(PolicyVerificationStatus.verified);
          }),
        );
      }),
      shareReplay(1),
    );

    this.canUpload$ = this.uploadCheckResult$.pipe(
      map(result => result?.canUpload === true),
    );

    this.policyUploadLocked$ = this.uploadCheckResult$.pipe(
      map(result => result && !result.canUpload),
    );

    const policyBelongsToUser$ = this.uploadCheckResult$.pipe(
      map(result => {
        return result ? !result.exists || result.belongs_to_provided_user : null; // null = we don't know
      }),
    );

    this.policyBelongsToOtherUser$ = policyBelongsToUser$.pipe(
      map(result => result === false),
    );

    this.showInsurableForm$ = combineLatest([
      this.identityFormComplete$,
      this.displayFirstFormFields$,
      policyBelongsToUser$,
    ]).pipe(
      map(flags => flags.every(f => f)),
    );

    this.showLeaseForm$ = combineLatest([
      this.insurableId$.pipe(startWith(0)),
      policyBelongsToUser$.pipe(startWith(false)),
    ]).pipe(
      map(([id, belongs]) => !!id && belongs),
    );

    this.displayedErrorLinks$ = debouncedFormChanges$.pipe(
      tap(() => this.form.updateValueAndValidity({ emitEvent: false})),
      map(() => {
        const invalidCtrlNames = Object.entries(this.form.controls)
          .filter(([_, ctrl]) => {
            return !ctrl.valid
          }).map(([name]) => name);
        /**
         * To display errors to the user next to continue button. In the order we want to display them
         * with a flag that indicates if the error should display at the moment of calling the method
         */
        const hasCarrier = !!this.form.controls.out_of_system_carrier_title.value;
        const fieldI18nKeys: Array<[string, string, boolean]>  = [
          ['lease_start', 'tenant_boarding.lease_start', true],
          //['out_of_system_carrier_title',  'tenant_boarding.insurance_company', true],
          ['effective_date',  'tenant_boarding.policy_start', hasCarrier],
          ['expiration_date',  'tenant_boarding.expiration', hasCarrier],
          ['liability_coverage',  'tenant_boarding.liability_coverage', hasCarrier],
          ['contents_coverage',  'tenant_boarding.contents_coverage', hasCarrier],
          ['additional_interest',  'tenant_boarding.additional_interest_checkbox', hasCarrier],
          ['documents',  'tenant_boarding.documents', hasCarrier],
          ['users',  'tenant_boarding.users', hasCarrier],
        ];

        return fieldI18nKeys.reduce((acc,[name, i18nKey, display]) => {
          if (!display || !invalidCtrlNames.includes(name)) {
            return acc;
          }
          acc.push([ this.translate.instant(i18nKey), name ]);
          return acc;
        }, [] as Array<[string, string ]>);
      }),
    );

    this.setBrandingAndAdditionalInterest();

    this.onCommunityChanges();

    // this.onInsurableIdChanges();

    this.setAdditionalInterest();

    this.userMatchService.onChangesUserInfo(this.form);

    this.onPolicyNumberChange();

    // if @Input() isBrandingSecondNature wasn't passed (happens on the standalone upload coverage proof form)
    // in this case we need to correctly define this property
    if (this.isBrandingSecondNature === undefined) {
      const slug = this.brandingService.getBrandingInfoSnapshot()?.profile_attributes.find(item => item.name === 'branding_profile_slug')?.value;
      this.isBrandingSecondNature = slug === 'second_nature' || slug === 'pinata';
    }

    this.promptForInsuranceDetails$ = this.form.controls.out_of_system_carrier_title.valueChanges.pipe(
      map(() => this.promptForInsuranceDetails()),
      tap(prompt => {
        const controls = [
          this.form.controls.effective_date,
          this.form.controls.expiration_date,
          this.form.controls.additional_interest,
          this.liabilityCoverageControl,
        ];
        controls.forEach(control => {
          control.setValue('');
          control.clearValidators();
          if (prompt) {
            control.addValidators([Validators.required]);
          }
          control.updateValueAndValidity({ onlySelf: false, emitEvent: false });
        });
      }),
    );
    this.promptForInsuranceDetails$.subscribe(); // TODO check if template needs to hide insurance fields

    const invalidFormControls$ = this.form.statusChanges.pipe(
      map(status => {
        return Object.keys(this.form.controls).filter(name => {
          return this.form.controls[name].invalid;
        }).map(name => name);
      }),
      startWith(Object.keys(this.form.controls)),
    );

    const isInvalid = (status: FormControlStatus) => status === 'INVALID';

    this.processing$ = merge(
      this.verifyingLeaseOrPolicy$,
      this.policyUploadStatus$.pipe(map(status => status === PolicyVerificationStatus.uploading)),
    );

    this.continueDisabled$ = combineLatest([
      this.processing$,
      invalidFormControls$.pipe(
        map(controls => !isEmpty(controls))),
      this.verifyingLeaseOrPolicy$,
      this.form.controls.users.statusChanges.pipe(map(isInvalid), startWith(false)),
      this.unitControl.valueChanges.pipe(map(isInvalid), startWith(false)),
      this.liabilityCoverageControl.statusChanges.pipe(map(isInvalid), startWith(false)),
      this.contentsCoverageControl.statusChanges.pipe(map(isInvalid), startWith(false)),
    ]).pipe(
      map(flags => flags.some(f => !!f)),
      startWith(true),
    );

    this.pmaUploadH1$ = this.brandingService.pmaUploadH1$;
    this.pmaUploadDescription$ = this.brandingService.pmaUploadDescription$;
  }

  setBrandingAndAdditionalInterest() {
    const sub = this.brandingService.brandingInfo$.subscribe((b: BrandingInfo) => {
      this.branding = b;
      this.brandingSlug = this.brandingService.getBrandingInfoSnapshot()?.profile_attributes.find(item => item.name === 'branding_profile_slug')?.value;
      this.contactEmail = this.brandingService.getBrandingInfoSnapshot()?.support_email;
      this.brandingId = b.id;
      this.pmaService.getAdditionalInterest(b.profile_attributes);
    });
    this.subscription.add(sub);
  }

  setAdditionalInterest() {
    const branding = this.brandingService.getBrandingInfoSnapshot();
    this.slug = branding?.profile_attributes.find(item => item.name === 'branding_profile_slug')?.value;

    this.additionalInterest = this.pmaService.getAdditionalInterest(branding.profile_attributes);

    if (branding.profileable_type === 'Account' && ['garden_communities', 'essex', 'second_nature'].includes(this.slug)) {
      this.pmAccountName = this.slug === 'second_nature' ? 'Sweyer Property Management' : branding.profileable_title;
    } else if (!this.pmAccountName) {
      this.pmAccountName = branding.profileable_title;
    }
  }

  onCommunityChanges() {
     this.communityControl.valueChanges.subscribe(community => {
      if (community && community.id) {
        this.pmaService.getAdditionalInterestByInsurableId(community.id).subscribe((res: any) => {
          this.fetchedCommunityAdditionalInterestName = this.pmaService.setAdditionalInterestName(res, community.title);
          this.fetchedAdditionalInterestAddress = this.pmaService.setAdditionalInterestAddress(res, community.title);
        });
        // this.setCoverageValidators();
      }
    });
  }

  onInsurableIdChanges() {
    if (this.firstForm?.controls[fieldNames.insurableId]) {
      this.subscription.add(
        this.firstForm.controls[fieldNames.insurableId].valueChanges.subscribe(id => {
          if (id) {
            this.setCoverageValidators();
          }
        })
      );
    }
  }

  setCoverageValidators() {
    const communityId = this.getCommunityControlId() ? this.getCommunityControlId() : this.getInsurableId();
    if (communityId) {
      this.fetchCoverageValidations(communityId);
    }
  }

  getInsurableId() {
    return this.firstForm?.controls[fieldNames.insurableId]?.value ?? null;
  }

  getCommunityControlId() {
    return this.communityControl && this.communityControl.value && this.communityControl.value.id ? this.communityControl.value.id : null;
  }

  getPolicyStartDate(): YMDDate | null {
    const date = this.form.controls?.effective_date?.value;
    return date ? dateToYMDDate(date) : null;
  }

  fetchCoverageValidations(insurableId: number): void {
    const policyStartDate: YMDDate | null = this.getPolicyStartDate();

    this.residentialService.getCoverageRequirements(insurableId, policyStartDate).subscribe((res: any) => {
      const data = res && res.data ? res.data : [];

      const liability = data.find(item => item.designation === 'liability');
      const contents = data.find(item => item.designation === 'contents');

      this.communityLiabilityCoverageMin = liability && liability.amount ? liability.amount : null;
      this.communityContentsCoverageMin = contents && contents.amount ? contents.amount : null;

      this.setLimitValidation(this.liabilityCoverageControl, this.communityLiabilityCoverageMin); // we display actual not divided by 100 value for the liability drop-down
      this.setLimitValidation(this.contentsCoverageControl, this.communityContentsCoverageMin / 100);

      if (this.communityContentsCoverageMin) {
        const communityContentsCoverageMin = this.communityContentsCoverageMin / 100;
        this.contentsCoverageControl.setValidators([Validators.required, Validators.min(communityContentsCoverageMin)]);
      } else {
        this.contentsCoverageControl.clearValidators();
      }
      this.contentsCoverageControl.enable();
      this.liabilityCoverageControl.enable();
      this.contentsCoverageControl.updateValueAndValidity();
    });
  }

  setLimitValidation(control: AbstractControl, limit: number): void {
    if (!limit) {
      control.clearValidators();
      return;
    }

    control.setValidators([Validators.required, Validators.min(limit)])
  }

  setUserAddressAttributes(address) {
    const full = address && address.full ? address.full : null;
    if (full) {
      this.setFullAddress(full);
    }
    this.userAddressAttributes = {
      street_name: address && address.street_name ? address.street_name : '',
      street_number: address && address.street_number ? address.street_number : '',
      street_two: address && address.street_two ? address.street_two : '',
      city: address && address.city ? address.city : '',
      state: address && address.state ? address.state : '',
      zip_code: address && address.zip_code ? address.zip_code : ''
    }
  }

  openAdditonalInterestDialog() {
    this.dialog.open(PmaDialogAdditionalInterestComponent, { autoFocus: false, maxWidth: 450 });
  }

  updateDate(event: MatDatepickerInputEvent<Date>) {
    if (!event.value) {
      return;
    }

    const date = new Date(event.value);
    date.setFullYear(event.value.getFullYear() + 1);
    this.form.controls.expiration_date.setValue(date);
    this.maxDate = moment(date).add(1, 'year').toDate();
    this.form.controls.expiration_date.updateValueAndValidity();
  }

  requireDocuments() {
    if (this.form.get('documents').value?.length > 0 || !this.promptForInsuranceDetails()) {
      return false;
    }
    this.toastr.error('Declaration Document is required');
    return true;
  }

  submitForm() {
    if (this.requireDocuments()) {
      return;
    }
    this.uploadExternalPolicy();
  }

  openDialogPolicyNumber(withExternalPolicy = false) {
    const dialog = this.dialog.open(DialogPolicyNumberComponent, { autoFocus: false, maxWidth: 450 });
    this.alreadyExistsModalDisplayed = true;

    dialog.afterClosed().subscribe((res) => {
      this.isPolicyNumberValid = !!res;
      if (this.isPolicyNumberValid) {
        this.externalPolicyFillService.prefillForm(
          this.form,
          this.liabilityCoverageControl, this.contentsCoverageControl,
          this.policyPayloadCheck
        );
      }
      if (this.isPolicyNumberValid && withExternalPolicy) {
        this.uploadExternalPolicy();
      }

      this.cd.detectChanges();
      this.form.updateValueAndValidity();
    });
  }

  uploadExternalPolicy() {
    this.policyUploadStatus$.next(PolicyVerificationStatus.uploading);
    this.cd.markForCheck();
    if (this.form.valid) {
      const formData = this.getFormData();
      this.pmaService.addCoverageProof(formData).subscribe((res: any) => {
        this.policyUploadStatus$.next(PolicyVerificationStatus.uploaded);
        this.pmaService.storeInsurableByAuthToken(null);
        const msg = res && res.message ? res.message : this.translate.instant('user.success');
        this.toastr.success(msg);
        this.changeStep.emit('documents-reviewed');
        this.IframeModeService.sendMessage({
          event_type: 'upload'
        });
      }, res => {
        this.policyUploadStatus$.next(PolicyVerificationStatus.uploadError);
        this.handleError(res);
      });
    } else {
      this.policyUploadStatus$.next(PolicyVerificationStatus.uploaded);
    }
  }

  handleError(res) {
    const errorMessages = {
      carrier_invalid: this.translate.instant('tenant_boarding.select_carrier'),
      policy_in_system_violation: this.translate.instant('tenant_boarding.policy_in_system_violation'),
      insurable_must_exist: this.translate.instant('user.insurable_must_exist'),
      something_went_wrong: this.translate.instant('user.something_went_wrong')
    };

    const msg = (() => {
      switch (true) {
        case isArray(res?.documents) && !isEmpty(res.documents):
          return first(res.documents);
        case res?.originError?.error?.['policy_insurables.insurable']:
          return errorMessages['insurable_must_exist'];
        case res?.error && errorMessages[res.error]:
          return errorMessages[res.error];
        case res?.error && res?.message:
          return res.message;
        default:
          return this.contactEmail ?
            `${errorMessages['something_went_wrong']} ${this.translate.instant('user.email_policy_to', { email: this.contactEmail })}` :
            errorMessages['something_went_wrong'];
      }
    })();

    this.toastr.error(msg);
  }

  getFormData() {
    const formData = new FormData();
    formData.append(`policy[account_id]`, this.getFormDataAccountId());
    const values = this.form.value;

    formData.append(`policy[policy_insurables_attributes][0][${fieldNames.insurableId}]`, this.getFormDataInsurableId());
    formData.append(`policy[agency_id]`, this.branding?.agency_id?.toString() || '');
    formData.append(`policy[address]`, this.fullAddress);
    formData.append(`policy[number]`, values.number);
    formData.append(`policy[policy_type_id]`, values.policy_type_id);
    formData.append(`policy[status]`, 'EXTERNAL_UNVERIFIED');
    formData.append(`policy[lease_start]`, formatDateValue(values.lease_start));
    formData.append(`policy[effective_date]`, formatDateValue(values.effective_date));
    formData.append(`policy[expiration_date]`, formatDateValue(values.expiration_date));
    formData.append(`policy[additional_interest]`, values.additional_interest);
    formData.append(`policy[system_purchased]`, values.system_purchased);
    formData.append(`policy[policy_users_attributes][0][spouse]`, 'false');
    formData.append(`policy[policy_users_attributes][0][primary]`, 'true');
    formData.append(`policy[policy_users_attributes][0][user_attributes][email]`, values.email);
    formData.append('policy[policy_users_attributes][0][user_attributes][profile_attributes][first_name]', values.first_name);
    formData.append('policy[policy_users_attributes][0][user_attributes][profile_attributes][last_name]', values.last_name);
    formData.append('policy[policy_users_attributes][0][user_attributes][profile_attributes][middle_name]', values.middle_name);

    const authTokenInfo = this.pmaService.insurableByAuthTokenStore.getValue();

    if (authTokenInfo && authTokenInfo?.policy_user?.id) {
      formData.append(`policy[policy_users_attributes][0][id]`, authTokenInfo?.policy_user?.id);
    }

    if (authTokenInfo && authTokenInfo?.user?.id) {
      formData.append(`policy[policy_users_attributes][0][user_attributes][id]`, authTokenInfo?.user?.id);
    }

    // Carrier
    const value = this.form.controls.out_of_system_carrier_title.value;
    if (isCarrier(value.carrier)) {
      formData.append(`policy[carrier_id]`, String(value.carrier.id));
    } else if (value.otherCarrierName) {
      formData.append(`policy[out_of_system_carrier_title]`, value.otherCarrierName);
    }

    [
      ...this.getUserAddressFormData(0),
      ...this.getUsersFormData(),
      ...this.getLiabilityCoverageFormData(),
      ...this.getPetDamageFormData(),
    ].forEach(([name, value]) => formData.append(name, value));

    (values.documents ?? []).forEach(doc => {
      formData.append('policy[documents][]', doc);
    });

    return formData;
  }

  getCarrierField(): [ 'carrier_id' | 'out_of_system_carrier_title', string ] {
    const control = this.form.controls.out_of_system_carrier_title.value;
    const isCarrierId = control.value?.id;
    const firstSynonymId = this.synonyms && this.synonyms[0] && this.synonyms[0].id ? this.synonyms[0].id : null;
    const isOneOption = !!control.value && firstSynonymId && this.synonyms.length === 1;
    if (isCarrierId) {
      return [ 'carrier_id', isCarrierId ];
    } else if (isOneOption) {
      return [ 'carrier_id', firstSynonymId ];
    }
    return [ 'out_of_system_carrier_title', control.value ];
  }

  private getFormDataInsurableId(): string {
    if (this.firstForm && this.firstForm.get('address') && this.firstForm.get(fieldNames.insurableId)) {
      return this.firstForm.get(fieldNames.insurableId).value;
    }
    return this.unitControl?.value?.id
      ?? this.buildingControl?.value?.id
      ?? this.buildingControl?.value?.id
      ?? this.communityControl?.value?.id;
  }

  private getFormDataAccountId(): string {
    const accountId = this.branding?.account_id?.toString() || '';
    if (this.firstForm.get('pm_account')) {
      return (this.firstForm.get('pm_account').value as PmAccount).id;
    }
    return accountId;
  }

  getLiabilityCoverageFormData(): Array<[string, string]> {
    const k = 'policy[policy_coverages_attributes]';
    return [
      [`${k}[0][deductible]`, '0'],
      [`${k}[0][designation]`, 'liability'],
      [`${k}[0][limit]`, this.liabilityCoverageControl.value],
      [`${k}[0][title]`, 'Liability Coverage'],
      [`${k}[0][enabled]`, 'true'],
      [`${k}[1][deductible]`, '0'],
      [`${k}[1][designation]`, 'contents'],
      [`${k}[1][limit]`, this.contentsCoverageControl.value * 100],
      [`${k}[1][title]`, 'Contents Coverage'],
      [`${k}[1][enabled]`, 'true'],
    ];
  }

  getPetDamageFormData(): Array<[string, string]> {
    if (this.isBrandingSecondNature && this.dogCoverageControl.value) {
      const k = 'policy[policy_coverages_attributes][2]';
      return [
        [`${k}[deductible]`, '0'],
        [`${k}[designation]`, 'pet_damage'],
        [`${k}[limit]`, '0'],
        [`${k}[title]`, 'Pet Damage'],
        [`${k}[enabled]`, 'true'],
      ];
    }
    return [];
  }

  getUsersFormData(): Array<[string, string]> {
    const users = this.form.value.users;
    return flatMap(users.map((user, i) => {
      return [
        [`policy[policy_users_attributes][${i + 1}][user_attributes][email]`, user.email],
        [`policy[policy_users_attributes][${i + 1}][spouse]`, user.spouse],
        [`policy[policy_users_attributes][${i + 1}][primary]`, 'false'],
        ...Object.keys(user).map(key => {
          return [
            `policy[policy_users_attributes][${i + 1}][user_attributes][profile_attributes][${key}]`,
            key === 'birth_date' ?  moment(user[key]).format('YYYY-MM-DD') : user[key]
          ];
        }),
      ];
    }));
  }

  isNotEmptyUser(user) {
    if (user && user.email) {
      return true;
    }
    const profileAttributes = user.profile_attributes;
    const found = Object.keys(profileAttributes).find(key => profileAttributes[key]);
    return !!found;
  }

  getUserAddressFormData(index: number): Array<[string, string]> {
    const addressAttributes = this.addressAttributes ?? this.userAddressAttributes ?? null;
    if (addressAttributes) {
      return [
        [`policy[policy_users_attributes][${index}][user_attributes][address_attributes][street_number]`, addressAttributes.street_number],
        [`policy[policy_users_attributes][${index}][user_attributes][address_attributes][street_name]`, addressAttributes.street_name],
        [`policy[policy_users_attributes][${index}][user_attributes][address_attributes][street_two]`, addressAttributes.street_two],
        [`policy[policy_users_attributes][${index}][user_attributes][address_attributes][city]`, addressAttributes.city],
        [`policy[policy_users_attributes][${index}][user_attributes][address_attributes][state]`, addressAttributes.state],
        [`policy[policy_users_attributes][${index}][user_attributes][address_attributes][zip_code]`, addressAttributes.zip_code],
      ];
    }
    return [];
  }

  externalPolicyCheck() {
    if (this.alreadyExistsModalDisplayed) {
      return;
    }

    this.form.controls[fieldNames.number].setErrors(null);
    const number = this.form.controls[fieldNames.number].value;
    const email = this.form.controls[fieldNames.email].value;
    if (number) {
      this.externalPolicyFillService.externalPolicyCheck(number, email).subscribe((res: any) => {
        if (res && res.policy) {
          const isPolicyInSystemError = res.policy && res.policy.policy_in_system ? res.policy.policy_in_system : false;
          if (isPolicyInSystemError) {
            this.form.controls[fieldNames.number].setErrors({'in_system': true});
          } else {
            this.openDialogPolicyNumber();
          }
          this.policyPayloadCheck = res;
        }
      })
    }
  }

  private policyAssignCheck(): Observable<{ belongs_to_provided_user: boolean; exists: boolean } | null> {
    const body = this.policyAssignGetBody();

    this.externalPolicyCheck();
    return of(null);


    // The following code works correctly, but was commented out due to the fact business team requested
    // To ignore assignCheck request result, and always let user upload

    // return body
    //   ? of(true).pipe(
    //       switchMap(() => this.policyAssignService.assignCheck(body)),
    //       catchError(() => {
    //         this.externalPolicyCheck();
    //         return of(EMPTY);
    //       }),
    //     )
    //   : of(null);
  }

  navigateToUserProfile() {
    this.router.navigateByUrl('user/policies');
  }

  getQuoteNow() {
    const attributes = this.brandingService.getBrandingInfoSnapshot()?.profile_attributes;

    const isGetQuoteDefaultPma = attributes.find(item => item.name === 'is_get_quote_default_to_pma_step')?.value === 'true';


    // 2 or more master policies
    if (this.masterPolicies && this.masterPolicies.length > 1) {
      this.changeStep.emit('opt-in-select-coverage');
      return;
    } else if (isGetQuoteDefaultPma) {
      this.router.navigateByUrl('/pma-tenant-onboarding');
      return;
    }

    this.pmaService.navigateToResidential(this.form, this.fullAddress, this.county, this.currentAddressAttributes, this.unitControl);
  }

  setFullAddress(fullAddress) {
    this.setAddress.emit(fullAddress);
  }

  setSynonyms(synonyms) {
    this.synonyms = synonyms;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    ViewPortVisibilityObserver.stopObserving(this.submitButton.nativeElement);
  }

  public ngAfterViewInit(): void {
    //
  }

  get currentAddressAttributes() {
    return this.addressAttributes ? this.addressAttributes : this.userAddressAttributes ? this.userAddressAttributes : null;
  }


  get controls() {
    return this.form.controls;
  }

  get selectedPmAccount() {
    return this.firstForm && this.firstForm.get('pm_account') ? this.firstForm.get('pm_account').value : null;
  }

  get getCommunityAdditionalInterestName() {
    return this.inTenantBoarding ? this.communityAdditionalInterestName : this.fetchedCommunityAdditionalInterestName;
  }

  get getAdditionalInterestAddress() {
    return this.inTenantBoarding ? this.additionalInterestAddress : this.fetchedAdditionalInterestAddress;
  }

  get isValidInitForm(): boolean {
    const email = this.form.controls[fieldNames.email];
    const unit = this.firstForm.get('unit');
    return !!this.form.controls[fieldNames.firstName]?.value?.trim() &&
      email?.value?.trim() && email.valid &&
      (unit.valid || unit.disabled) &&
      this.userAddressAttributes?.street_name?.trim() && this.isPMAccount;
  }

  get isPMAccount() {
    return !this.nonpreferredAccountSelection ||
      (this.nonpreferredAccountSelection && this.firstForm.get('pm_account') && this.firstForm.get('pm_account').value);
  }

  get isGetQuote() {
    const attributes = this.brandingService.getBrandingInfoSnapshot()?.profile_attributes;

    const isGetQuote = attributes.find(item => item.name === 'is_get_quote')?.value;
    return isGetQuote;
  }

  get showAdditionalEmailNotice(): boolean { // TODO this is not ideal as the ID or title can change across environments
    return this.branding?.agency_id === 416 || this.branding?.profileable_title.toLowerCase() === 'second nature';
  }

  promptForInsuranceDetails(): boolean {
    if (!this.form?.controls) {
      return false;
    }
    const value = this.form.controls.out_of_system_carrier_title.value;
    return !!value && (!isCarrier(value) || value.is_system !== true);
  }


  pushInsured(): void {
    this.usersControl.push(
      new FormControl({
        email: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        spouse: false,
      }),
    );
    this.form.controls.userCount.patchValue(this.usersControl.value.length);
  }

  updateInsuredCount(event: MatSelectChange): void {
    const users = this.form.controls.users as FormArray;
    const currentLength = users.length;
    const diff = Number(event.value) - currentLength;
    if (diff !== 0) {
      for (let i = 0; i < Math.abs(diff); i++) {
        if (diff > 0) {
          this.pushInsured();
        } else {
          users.removeAt(users.length - 1);
        }
      }
    }
  }

  get userFormsValid(): boolean {
    // User forms are valid if they are complete and mach the number of individuals the user selected
    const valid = (() => {
      for (let i = 0; i < this.usersControl.length; i++) {
        this.usersControl.at(i).updateValueAndValidity();
        if (this.usersControl.at(i).invalid) {
          return false;
        }
      }
      return true;
    })();

    return this.form.controls.users.value.length === this.form.controls.userCount.value; // TODO
  }

  trackUserControl(index: number): number {
    return index;
  }

  get usersControl(): FormArray {
    return this.form.controls.users as FormArray;
  }

  onRemoveInsured(index: number): void {
    this.usersControl.removeAt(index);
    this.form.controls.userCount.setValue(this.usersControl.value.length);
    this.form.controls.users.updateValueAndValidity();
  }

  private policyAssignGetBody = (): PolicyAssignCheckPayload => {
    const number = this.form.controls.number;
    const email = this.form.controls.email;
    if (number.valid && email.valid) {
      return {
        user_id: this.userId,
        email: email.value,
        policy_number: number.value
      };
    }
    return null;
  }

  protected readonly length = length;

  private onWindowScroll(): void {
    // when button becomes visible trigger errors so user knows
    const button = this.submitButton?.nativeElement;

  }

  private onPolicyNumberChange(): void {
    // clear out alreadyExistsModalDisplayed
    // so in case policy number changed - the already exists modal would be displayed again
    const sub = this.form.controls[fieldNames.number].valueChanges.subscribe(() => {
      this.alreadyExistsModalDisplayed = false;
    });
    this.subscription.add(sub);
  }
}


const formatDateValue = (value: Date): string => {
  return value ? moment(value).format('YYYY-MM-DD') : '';
}


