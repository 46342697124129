import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { BrandingService } from '../../core/services/branding.service';
import { distinctUntilChanged, finalize, map, startWith, switchMap, takeWhile } from 'rxjs/operators';
import { ResidentialService } from '../../core/services/residential.service';
import { PmAccount } from '../../core/dto/pm-account';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { Platform } from '@angular/cdk/platform';
import { AddressAttributes } from '../../core/models/address.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'pm-account',
  templateUrl: './pm-account.component.html',
  styleUrls: ['./pm-account.component.scss']
})
export class PmAccountComponent implements OnInit, OnDestroy {
  isActive = true;

  @Input() accountControl: AbstractControl = new FormControl('');
  accountControlStringValue$: Observable<string>;
  filteredAccounts$: Observable<PmAccount[]>;
  accounts: PmAccount[] = [];
  isLoading = true;

  // Minimum number of characters required for autocomplete to show results
  minCharacters = 2;

  constructor(
    public platform: Platform,
    private brandingService: BrandingService,
    private residentialService: ResidentialService
  ) { }

  ngOnInit(): void {
    this.brandingService.brandingInfo$.pipe(
      takeWhile(() => this.isActive),
      switchMap((branding) => this.residentialService.getAgencyAccounts(branding.agency_id).pipe(
        finalize(() => this.isLoading = false)
      )),
    ).subscribe((accounts: PmAccount[]) => {
      this.accounts = accounts;
      this.setFilteredAccounts();
    });
    this.accountControl.addValidators([Validators.required]);
  }

  ngOnDestroy(): void {
    this.isActive = false;
  }

  onBlur(autoComplete: MatAutocomplete) {
    if (!autoComplete.isOpen && this.accountControl.value && !this.accountControl.value.id) {
      this.accountControl.reset();
    }
  }

  private setFilteredAccounts() {
    this.accountControlStringValue$ = this.accountControl.valueChanges
      .pipe(
        startWith(''),
        map(value => value || ''),
        map(value => typeof value === 'string' ? value : value && value.title ? value.title : ''),
        map(value => value.trim()),
        distinctUntilChanged(),
      );

    this.filteredAccounts$ = this.accountControlStringValue$.pipe(
      map(value => value?.length >= this.minCharacters ? this.filterAccounts(this.accounts, value) : [])
    );
  }

  displayFnAccount(account?: PmAccount): string | undefined {
    return account ? account.title : undefined;
  }

  private filterAccounts(accounts, searchTerm: string): PmAccount[] {
    const filterValue = searchTerm.toLowerCase();
    return accounts.filter(account => account.title.toLowerCase().includes(filterValue));
  }

  reset() {
    this.accountControl.reset();
  }
}

/**
 * Check if all the address fields aren't empty
 */
export function isAddressFilled(address: AddressAttributes): boolean {
  return !!(address && address.street_name && address.street_name && address.city && address.county && address.state && address.zip_code);
}
