<ng-container *ngIf="!isLogoLoading">
  <div *ngIf="!isSecondLogo || IframeMode" class="header-logo-wrap__container"
    [ngClass]="{'header-logo-wrap__container-wide': isWideLogo }">
    <a *unlessBrandingAttr="'logo_custom_url'"
      [routerLink]="['/']">
      <div class="header-logo-wrap" [style.background-image]="'url(' + getLogoUrl + ')'"></div>
    </a>

    <a *brandingAttr="'logo_custom_url'"
       [href]="'logo_custom_url' | displayBrandingAttr:'profile_attributes'"
       target="_blank">
      <div class="header-logo-wrap" [style.background-image]="'url(' + getLogoUrl + ')'"></div>
    </a>
  </div>

  <div *ngIf="isSecondLogo && !IframeMode" class="header-dual-container">
    <a [routerLink]="['/']" fxLayoutAlign="row center">
      <div class="header-dual">
        <img class="header-dual-plus" src="./assets/logos/header_logo_plus.png" alt="plus">
        <div class="header-dual-first">
          <img [src]="getLogoUrl" alt="plus" [ngClass]="{ 'header-dual-first-square': isSquareLogo }">
        </div>
        <div class="header-dual-second">
          <img [src]="headerSecondLogoUrl" alt="plus" [ngClass]="{ 'header-dual-second-square': isSquareSecondLogo }">
        </div>
      </div>
    </a>
  </div>
</ng-container>
