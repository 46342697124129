<ng-container *ngIf="loader">
  <div fxLayout="row" fxLayoutAlign="center" style="margin-top: 150px;">
    <mat-spinner></mat-spinner>
  </div>
</ng-container>

<div *ngIf="!loader" class="tenant-boarding">
  <upload-coverage
    *ngIf="step === 'proof-coverage'"
    [firstForm]="firstForm"
    (changeStep)="changeStep($event)"
    [communityName]="communityName"
    [unitName]="unitName"
    [fullAddress]="fullAddress"
    [state]="state"
    [isMasterPolicy]="isMasterPolicy"
    [communityAdditionalInterestName]="communityAdditionalInterestName"
    [communityControl]="communityControl"
    [buildingControl]="buildingControl"
    [unitControl]="firstForm.get('unit')"
    [inTenantBoarding]="false"
    (setAddress)="setAddress($event)"
    [nonApplicableControl]="nonApplicableControl"
    [nonpreferredAccountSelection]="nonpreferredAccountSelection"
    [nonpreferredAddressSelection]="nonpreferredAddressSelection"
    [pmaSingleHouses]="pmaSingleHouses"
    [fromToken]="!!token"
  >
  </upload-coverage>
  <pma-documents-reviewed
    *ngIf="step === 'documents-reviewed'"
    [communityName]="communityName"
    [unitName]="unitName"
  >
  </pma-documents-reviewed>
</div>
