<div style="width: 100%;">
  <h2 style="text-align: center; font-size: 20px;">Get Covered, LLC Entity P-C Insurance Agency License Numbers</h2>
  <table>
    <thead>
    <tr>
      <th>{{ 'form.state' | translate }}</th>
      <th>License Number</th>
    </tr>
    </thead>
    <tbody>

    <tr *ngFor="let item of states">
      <td>{{ item.state }}</td>
      <td>{{ item.licenseNumber }}</td>
    </tr>

    </tbody>
  </table>
</div>
