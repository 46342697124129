import {Component, ViewEncapsulation} from '@angular/core';
import { BrandingService } from '../../core/services/branding.service';

@Component({
  selector: 'app-header-banner',
  templateUrl: './header-banner.component.html',
  styleUrls: ['./header-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderBannerComponent {
  headerHtml$;
  constructor(private brandingService: BrandingService) {
    this.headerHtml$ = brandingService.headerHtml$;
  }
}
