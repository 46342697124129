<footer class="footer">
  <div class="footer-container">
    <div class="footer-container__row"
      [class.footer-container__row-narrow]="isNarrowFooter | async"
      >
      <ng-container *ngTemplateOutlet="logo"></ng-container>

      <ng-container *ngIf="footerCopyrightHtml$ | async">
        <div class="footer-container__copyright"
             [class.footer-container__copyright-narrow]="isNarrowFooter | async"
             [innerHTML]="footerCopyrightHtml$ | async"></div>
      </ng-container>
    </div>

    <div class="footer-container__row footer-container__row-navigation" style="flex-direction: column; align-self: flex-end">
      <div class="footer-navigation">
        <div class="footer-navigation__link">
          <a *ngIf="!(isUserLogin$ | async)" routerLink="/auth/login">{{ 'form.login' | translate }}</a>
          <a *ngIf="isUserLogin$ | async" routerLink="user/profile">{{ 'form.profile' | translate }}</a>
        </div>
        <div *ngIf="isContactUsPage$ | async" class="footer-navigation__link"><a routerLink="/Contact-Us">{{ 'menu.contact_us' | translate }}</a></div>
      </div>
      <div class="footer-container__content">
        <div class="footer-container__content__terms">
          <a routerLink="privacy-policy">{{ 'menu.privacy_policy' | translate }}</a>
          <a routerLink="Terms-of-Use">{{ 'menu.terms_of_use' | translate }}</a>
          <!--<a routerLink="PCI-Insurance-Fraud-Provisions">{{ 'menu.pci_insurance_fraud_provisions' | translate }}</a>-->
          <a routerLink="state-fraud-warnings">{{ 'menu.state_fraud_warnings' | translate }}</a>
          <a routerLink="licenses">Licenses</a>
        </div>
        <div class="footer-container__content__social-links">
          <ng-container *ngIf="(socialLinks$ | async) && (socialLinks$ | async).length" >
            <div *ngFor="let link of (socialLinks$ | async)" [ngClass]="link.type">
              <a [href]="link.url">
                <app-social [type]="link.type"></app-social>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="footer-container-mobile">
      <div *ngIf="isFooterLogo" class="footer-container-mobile__logo">
        <img class="footer-logo-img" [src]=footerLogoUrl alt="LOGO">
        <img *ngIf="isSecondFooterLogo" class="footer-logo-plus" src="./assets/logos/header_logo_plus.png" alt="plus">
        <img *ngIf="isSecondFooterLogo" class="footer-logo-img" [src]="footerSecondLogoUrl" alt="LOGO-2">
      </div>
      <div *ngIf="!isFooterLogo" class="footer-container-mobile__logo">
        <img class="footer-logo-img" [src]=headerLogoUrl alt="LOGO">
        <img *ngIf="isSecondHeaderLogo" class="footer-logo-plus" src="./assets/logos/header_logo_plus.png" alt="plus">
        <img *ngIf="isSecondHeaderLogo" class="footer-logo-img" [src]="headerSecondLogoUrl" alt="LOGO-2">
      </div>
      <ng-container *ngIf="footerCopyrightHtml$ | async">
        <div class="footer-container-mobile__copyright" [innerHTML]="footerCopyrightHtml$ | async"></div>
      </ng-container>
    </div>
  </div>
</footer>


<ng-template #logo>
  <div *ngIf="isFooterLogo" class="footer-logo">
    <img class="footer-logo-img" [src]=footerLogoUrl alt="LOGO" *unlessBrandingAttr="'logo_custom_url'">

    <a *brandingAttr="'logo_custom_url'"
       [href]="'logo_custom_url' | displayBrandingAttr:'profile_attributes'"
       style="
    display: block;
    max-height: 120px;
    width: 225px;
"
       target="_blank">
      <img class="footer-logo-img" [src]=footerLogoUrl alt="LOGO">
    </a>

    <img *ngIf="isSecondFooterLogo" class="footer-logo-plus" src="./assets/logos/header_logo_plus.png" alt="plus">
    <img *ngIf="isSecondFooterLogo" class="footer-logo-img" [src]="footerSecondLogoUrl" alt="LOGO-2">
  </div>
  <div *ngIf="!isFooterLogo" class="footer-logo">
    <img class="footer-logo-img" [src]=headerLogoUrl alt="LOGO">
    <img *ngIf="isSecondHeaderLogo" class="footer-logo-plus" src="./assets/logos/header_logo_plus.png" alt="plus">
    <img *ngIf="isSecondHeaderLogo" class="footer-logo-img" [src]="headerSecondLogoUrl" alt="LOGO-2">
  </div>


</ng-template>
