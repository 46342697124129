import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { PmaService } from '../../../pma/pma.service';
import { Insurable } from '../../../core/models/insurable.model';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';

@Component({
  selector: 'ui-community',
  templateUrl: './ui-community.component.html',
  styleUrls: ['./ui-community.component.scss']
})
export class UiCommunityComponent implements OnInit {
  @Input() stateControl: FormControl
  @Input() communityControl: FormControl;
  @Input() unitControl: FormControl;
  @Input() brandingId;
  filteredInsurables$: Observable<Insurable[]>;
  insurables;
  communities = [];
  loader;

  constructor(private pmaService: PmaService) {
  }

  ngOnInit() {
    this.stateControl.valueChanges.subscribe((state) => {
      this.communities = [];
      this.fetchCommunities(state);
    })
    this.communityControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(450),
    ).subscribe(() => {
      this.searchCommunities();
    })
  }

  searchCommunities() {
    const state = this.stateControl && this.stateControl.value ? this.stateControl.value : null;
    const search = this.communityControl && this.communityControl.value && typeof this.communityControl.value === 'string' ? this.communityControl.value : null;
    if (state && search && search.length > 4) {
      this.fetchCommunities(state, search);
    }
  }

  fetchCommunities(state, search = '') {
    this.loader = true;
    this.pmaService.searchCommunities(state, search, this.brandingId).pipe(
      finalize(() => this.loader = false)
    ).subscribe((res: any) => {
      const sortAlphaNumeric = (a, b) => a.title.localeCompare(b.title, 'en', { numeric: true })
      this.communities = res.sort(sortAlphaNumeric);
    });
  }

  get insurableField() {
    return this.communityControl;
  }

  displayFnInsurable(insurable?: Insurable): string | undefined {
    return insurable ? insurable.title : undefined;
  }

  reset() {
    this.insurableField.reset(null);
    this.unitControl.reset(null);
  }
}
