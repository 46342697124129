import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-pinterest',
  templateUrl: './social-pinterest.component.html',
  styleUrls: ['./social-pinterest.component.css']
})
export class SocialPinterestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
