import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileMb'
})
export class FileMbPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!value)  {
      return null;
    }

    return (value / (1024 * 1024)).toFixed(2);
  }

}
