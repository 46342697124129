import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Injectable({
  providedIn: 'root'
})
export class SmartyService {
  constructor(private httpClient: HttpClient) {}

  searchByS(str: string) {
    let headers = new HttpHeaders();
    headers.append('Host', 'us-autocomplete-pro.api.smartystreets.com');
    headers.append('Referer', 'getcoveredinsurance.com');

    let params: HttpParams = new HttpParams();
    params = params.append('key', '169561092556086003');
    params = params.append('search', str);
    // params = params.append('auth-id', 'e46e0935-c795-8b3b-5147-bca18f1ddd13');
    // params = params.append('auth-token', 'w1KbzwH9vaafTP5nLJQy');

    return this.httpClient.get(`https://us-autocomplete-pro.api.smartystreets.com/lookup`, { headers, params});
  }

  searchBy(str: string) {
    const payload = {
      search: str,
      max_results: 10
    };
    return this.httpClient.post(`/smarty/search`, payload);
  }
}
