<div class="auth-form">
  <form [formGroup]="registerForm" class="auth-form" (ngSubmit)="submitForm()">
    <p class="description">
       {{ 'signup.description' | translate }}
    </p>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'form.email' | translate }}</mat-label>
      <input matInput type="email" formControlName="email" required>
      <mat-error *ngIf="f['email'].invalid && submitted">
        {{ f['email'] | errorMsg: ('form.email' | translate) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'form.password' | translate }}</mat-label>
      <input matInput type="password" formControlName="password" required>
      <mat-error *ngIf="f['password'].invalid && submitted">
        {{ f['password'] | errorMsg: ('form.password' | translate) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'user.confirm_password' | translate }}</mat-label>
      <input matInput type="password" formControlName="password_confirmation" required>
      <mat-error *ngIf="f['password_confirmation'].invalid && submitted">
        {{ f['password_confirmation'] | errorMsg: ('user.confirm_password' | translate) }}
      </mat-error>
    </mat-form-field>

    <ng-container formGroupName="profile_attributes">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'user.first_name' | translate }}</mat-label>
        <input matInput type="text" formControlName="first_name" required>
        <mat-error *ngIf="profileAttributes['first_name'].invalid && submitted">
          {{ profileAttributes['first_name'] | errorMsg: ('user.first_name' | translate) }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'user.last_name' | translate }}</mat-label>
        <input matInput type="text" formControlName="last_name" required>
        <mat-error *ngIf="profileAttributes['last_name'].invalid && submitted">
          {{ profileAttributes['last_name'] | errorMsg: ('user.last_name' | translate) }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'user.middle_name' | translate }}</mat-label>
        <input matInput type="text" formControlName="middle_name">
        <mat-error *ngIf="profileAttributes['middle_name'].invalid && submitted">
          {{ profileAttributes['middle_name'] | errorMsg: ('user.middle_name' | translate) }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="date-with-suffix">
        <mat-label>{{ 'user.birth_date' | translate }}</mat-label>
        <input [max]="maxBirthdayDate"
               matInput
               [matDatepicker]="picker_birth_date" formControlName="birth_date" required>
        <div matSuffix class="suffix-container">
          <mat-datepicker-toggle matSuffix [for]="picker_birth_date"></mat-datepicker-toggle>
        </div>
        <mat-datepicker #picker_birth_date startView="multi-year"></mat-datepicker>
        <mat-error *ngIf="profileAttributes['birth_date'].invalid && submitted">
          {{ profileAttributes['birth_date'] | errorMsg : ('user.birth_date' | translate) }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'user.contact_phone' | translate }}</mat-label>
        <input matInput mask="(000) 000-0000" minlength="10" type="text" formControlName="contact_phone" required>
        <mat-error *ngIf="profileAttributes['contact_phone'].invalid && submitted">
          {{ profileAttributes['contact_phone'] | errorMsg: ('user.contact_phone' | translate): 'phone' }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <hr>

    <button mat-button
            type="submit"
            class="mat-btn warning test-register"
            [disabled]="f.terms_conditions.errors && submitted"
    >
      {{ 'user.save_login' | translate }}
    </button>

    <hr>

    <div class="terms">
      <input type="checkbox" id="terms_conditions" formControlName="terms_conditions"
             [ngClass]="{ 'is-invalid-i': submitted && f.terms_conditions.errors }">
      <label for="terms_conditions" class="terms-label">{{ 'user.terms_conditions' | translate }}</label>
      <div *ngIf="submitted && f.terms_conditions.errors" class="invalid-input">{{ 'form.terms_conditions_required' | translate }}</div>
    </div>

  </form>

  <p *ngIf="!(isLoginDisabled$ | async)">{{ 'form.already_have_an_account' | translate }}? <a [routerLink]="['/auth/login']">{{ 'form.login' | translate }}</a>.</p>
</div>
