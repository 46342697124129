import {Injectable, Optional} from '@angular/core';
import { BrandingInfo } from '../models/branding.model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import {DomSanitizer, Title} from '@angular/platform-browser';
import { StripeCurrencyPipe } from '../pipes/stripeCurrency.pipe';
import { jsonToCss } from '../utils/json.utils';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import {MetadataService} from '../../metadata.service';

const getAttrValueByFieldName = (fieldName: string) => map<any[], any>(x => {
  let found;
  const language = localStorage.getItem('Accept-Language');
  if (language !== 'en') {
    found = x.find(value => value.name === `${fieldName}_${language}`);
  }
  found = found ? found : x.find(value => value.name === fieldName) || {};
  return found.value;
});

@Injectable({
  providedIn: 'root'
})
export class BrandingService {
  private brandingStore = new BehaviorSubject<BrandingInfo>({} as BrandingInfo);
  public brandingInfo$ = this.brandingStore.asObservable();
  public clientStyleItem$ = this.brandingInfo$.pipe(map(info=> info.styles.client.content));
  public headerLogo$ = this.brandingInfo$.pipe(map(info => info.logo_url));
  public headerSecondLogo$ = this.brandingInfo$.pipe(map(info => info.second_logo_url));
  public footerLogo$ = this.brandingInfo$.pipe(map(info => info.footer_logo_url));
  public footerSecondLogo$ = this.brandingInfo$.pipe(map(info => info.second_footer_logo_url));
  public profileAttributes$ = this.brandingInfo$.pipe(map(info => {
    return info.profile_attributes || []
  }));

  public faqColor$ = this.getClientStyleItem('.faq-page a');
  public chatWidgetColor$ = this.getClientStyleItem('chat_widget_custom_color');
  public registerButtonColor$ = this.getClientStyleItem('register_button_custom_color');
  public quoteButtonColor$ = this.getClientStyleItem('quote_button_custom_color');

  public headerHtmlText$ =  this.profileAttributes$.pipe(getAttrValueByFieldName('r_header1'));
  public subheaderHtmlText$ =  this.profileAttributes$.pipe(getAttrValueByFieldName('r_header2'));
  public isFooterAffiliate$ = this.profileAttributes$.pipe(getAttrValueByFieldName('is_footer_affiliate'));
  public id$ = this.brandingInfo$.pipe(map(info => info.id));
  public headerHtml$ =  combineLatest([
    this.headerHtmlText$,
    this.subheaderHtmlText$
  ]).pipe(
    map(([header, subheader]) => header + subheader),
    map(val => this.sanitizer.bypassSecurityTrustHtml(val)),
  );
  public faq$ =  this.profileAttributes$.pipe(getAttrValueByFieldName('faq'));
  public giveBackPartner$ = this.profileAttributes$.pipe(getAttrValueByFieldName('give_back_partner'));
  public availableForms$ = this.profileAttributes$.pipe(
    getAttrValueByFieldName('available_forms'),
    map(v => JSON.parse(v || '[]'))
  );
  public commercialFormAvailable$ = this.availableForms$.pipe(
    map(v => v.includes('commercial'))
  );
  public residentialFormAvailable$ = this.availableForms$.pipe(
    map(v => v.includes('residential'))
  );
  public rentGuaranteeFormAvailable$ = this.availableForms$.pipe(
    map(v => v.includes('rent_guarantee'))
  );
  public registerAccountNotice$ = this.getProfileAttributeValue('register_account_notice');

  public feeNotice$ = this.getProfileAttributeValue('fee_notice');

  public footerCopyrightHtml$ = this.getProfileAttributeValue('footerCopyrightText').pipe(
    map(val => val ? this.sanitizer.bypassSecurityTrustHtml(val) : null),
  );
  public termOfUse$ = this.getProfileAttributeValue('term_of_use');

  public defaultStartingPage$ = this.getProfileAttributeValue('default_starting_page');

  public rightBackgroundLogo$ = this.getProfileAttributeValue('background_right_logo');

  public showBackButton$ = this.getProfileAttributeValue('show_back_button').pipe(
    map(v => v === 'true'),
  );
  public estimatePremiumBlock$ = this.getProfileAttributeValue('estimate_premium_block');

  public optionalAddons$ = this.getProfileAttributeValue('optional_addons');

  public socialLinks$ = this.getProfileAttributeValue('social_links').pipe(
    map(v =>  JSON.parse(v || '[]'))
  );

  public menuHeader$ = this.getProfileAttributeValue('menu_header').pipe(
    map(v =>  JSON.parse(v || '{"enabled":false,"values":[]}'))
  );

  public dropdownMenuHeader$ = this.getProfileAttributeValue('dropdown_menu_header').pipe(
    map(v =>  JSON.parse(v || '{"enabled":false,"values":[]}'))
  );
  public brandingPages$ = this.brandingInfo$.pipe(
    map(v => v.pages || [])
  );
  public profilableId$ = this.brandingInfo$.pipe(
    map(v => v.profileable_id || 1)
  );
  public currentAgencyTitleName: string;
  public gaTrackingId$ = this.getProfileAttributeValue('ga_tracking_id', '');

  public customRgPage$ = this.getProfileAttributeValue('custom_rg_page');

  public hideGuaranteeHeader$ = this.getProfileAttributeValue('rg_hide_header1').pipe(
    map(v => v === 'true')
  );

  public rootFontFamily$ = this.getProfileAttributeValue('root_font_family');

  public favicon$ = this.getProfileAttributeValue('favicon');

  public dataLayerResidential$ = this.getProfileAttributeValue('data_layer_residential');

  public isLoginDisabled$ = this.getProfileAttributeValue('is_login_disabled');

  public isRegisterDisabled$ = this.getProfileAttributeValue('is_register_disabled');

  public isWideLogo$ = this.getProfileAttributeValue('is_wide_logo');

  public slug$ = this.getProfileAttributeValue('branding_profile_slug');

  public pmaProgramDescription$ = this.getProfileAttributeValue('pma_program_description');

  public uploadExternalPolicy$ = this.profileAttributes$.pipe(
    filter(v => v && v.length > 0),
    getAttrValueByFieldName('upload_external_policy'),
    map(v => v === 'true')
  );
  public pmaRentersOption= this.getProfileAttributeValue('pma_renters_option');

  public masterPolicyOptIn = this.getProfileAttributeValue('master_policy_opt_in');

  public pmaH1$ = this.getProfileAttributeValue('pmaH1');

  public pmaDescription$ = this.getProfileAttributeValue('pmaDescription');

  public pmaUploadH1$ = this.getProfileAttributeValue('pmaUploadH1');

  public pmaUploadDescription$ = this.getProfileAttributeValue('pmaUploadDescription');

  public readonly onboardingSelectOptionDescription$ = this.getProfileAttributeValue(
    'onboarding_select_option_description',
  ).pipe(
    map(value => value || ''),
  );

  public isWideFooter$ = this.getProfileAttributeValue('is_wide_footer').pipe(
    map(v => v === 'true')
  );

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  private stripeCurrency: StripeCurrencyPipe = new StripeCurrencyPipe();

  constructor(private httpService: HttpClient,
              private sanitizer: DomSanitizer,
              private titleService: Title,
              private translateService: TranslateService,
              @Optional() private metadataService: MetadataService) { }

  public fetchBrandingInfo() {
    /**
     * For debug purpose you can create variable BRANDING_ID inside index.html to force loading by certain ID
     */
    let requestUrl = '/branding-profile';

    if (window['BRANDING_ID']) {
      requestUrl = `/branding-profiles/${window['BRANDING_ID']}`;
    }

    this.httpService.get(requestUrl)
      .pipe(
        tap(res => this.addStyles(res)),
        tap(res => this.setVariables(res)),
        tap(res => this.setFontFamily(res)),
        tap(res => this.setFavicon(res)),
        tap((info: any) => this.brandingStore.next(info)),
        tap((res: any) => {
          if (res.profileable_title) {
            this.currentAgencyTitleName = res.profileable_title;
            this.titleService.setTitle(res.profileable_title);
          }
          this.trackBranding(res);
        })
      ).subscribe();
  }

  public setBrandingInfo(res) {
    this.addStyles(res);
    this.setVariables(res);
    this.setFontFamily(res);
    this.setFavicon(res);
    this.brandingStore.next(res);
    if (res.profileable_title) {
      this.currentAgencyTitleName = res.profileable_title;
      this.titleService.setTitle(res.profileable_title);
    }
  }

  public getBrandingInfoSnapshot() {
    return this.brandingStore.getValue();
  }

  public feeNoticeHtml(fee) {
    return this.feeNotice$.pipe(
      filter(v => !!v),
      map(v => v.replace('${fee}', this.stripeCurrency.transform(fee))),
      map(v => this.sanitizer.bypassSecurityTrustHtml(v))
    );
  }

  public getPageByTitle(title: string) {
    return this.brandingPages$.pipe(
      map(v => {
        return v.find(page => page.title === title);
      })
    );
  }

  public setBrandingTitle(title: string) {
    this.titleService.setTitle(`${title} - ${this.currentAgencyTitleName}`);
  }

  public estimatePremiumBlockHtml(total) {
    return this.estimatePremiumBlock$.pipe(
      filter(v => !!v),
      map(v  => v.replace('${total}', `${this.stripeCurrency.transform(total)} / ${this.translateService.instant('residential.per_month_label')}`)),
      map(v => this.sanitizer.bypassSecurityTrustHtml(v))
    );
  }


  private addStyles(res) {
    const style = document.createElement('style');
    style.type = 'text/css';
    const clientStyles = res && res.styles ? res.styles.client : {};
    const colors =  clientStyles ? clientStyles.colors : {};
    const content = clientStyles ? clientStyles.content : {};
    const colorStyle = Object.entries(colors)
      .map(([key, value]) => `.color_${key} { color: ${value} } .background_${key} { background-color: ${value} }`).join(' ');
    const contentStyle = jsonToCss(content);
    style.innerHTML = colorStyle +  ' ' + contentStyle ;
    document.getElementsByTagName('head')[0].appendChild(style);
  }

  private setVariables(res) {
    if (res.styles && res.styles.client && res.styles.client.colors) {
      const colors = res.styles.client.colors;
      if (colors.primary) {
        document.documentElement.style.setProperty('--primary-color', colors.primary);
      }
      if (colors.secondary) {
        document.documentElement.style.setProperty('--secondary-color', colors.secondary);
      }
      if (colors.quaternary) {
        document.documentElement.style.setProperty('--quaternary-color', colors.quaternary);
      }
      if (colors.tertiary) {
        document.documentElement.style.setProperty('--tertiary-color', colors.tertiary);
      }
      if (colors.warning) {
        document.documentElement.style.setProperty('--warning-color', colors.warning);
      }
      if (colors.descriptor) {
        document.documentElement.style.setProperty('--descriptor-color', colors.descriptor);
      }
      if (colors.border) {
        document.documentElement.style.setProperty('--border-color', colors.border);
      }
    }
  }

  private setFontFamily(res) {
    if (res.profile_attributes) {
      const foundRootFontFamily = res.profile_attributes.find(attr => attr.name === 'root_font_family');
      if (foundRootFontFamily && foundRootFontFamily.value) {
        document.documentElement.style.setProperty('--root-font-family', foundRootFontFamily.value);
      }
    }
  }

  private setFavicon(res) {
    const getCoveredIco = 'https://gc-public-prod.s3-us-west-2.amazonaws.com/assets/getcovered.ico';
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.type = 'image/x-icon';
    if (res.profile_attributes) {
      const found = res.profile_attributes.find(attr => attr.name === 'favicon');
      favicon.href = found && found.value ? found.value : getCoveredIco;
    } else {
      favicon.href = getCoveredIco;
    }
    document.getElementsByTagName('head')[0].appendChild(favicon);
  }

  public refreshStore = () => {
    const value = this.brandingStore.getValue();
    this.brandingStore.next(value);
  }

  getProfileAttributeValue<T = any>(name: string | ProfileAttributes, defaultValue?: T): Observable<T> {
    return this.profileAttributes$.pipe(
      getAttrValueByFieldName(name),
      map(value => defaultValue && !value ? defaultValue : value),
    );
  }

  getClientStyleItem<T = any>(name: string): Observable<T> {
    return this.clientStyleItem$.pipe(
      map(item => {
        return  item?.[name] ? item[name]['color'] : '';
      })
    );
  }

  private trackBranding(branding) {
    if (typeof window['Intercom'] === 'undefined') {
      return;
    }

    (window as any).Intercom('boot', {
      app_id: (window as any).intercom_app_id,
      branding: branding.url
    });
  }

}

export enum ProfileAttributes {
  pmaTenantOnboardingFooter = 'pma_tenant_onboarding_footer',
}
